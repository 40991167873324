import axios from "axios";

export const getAllAdmin = (token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_URL_BE}/admin`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
        };
        axios(config)
        .then((data) => {
            dispatch({type:'LIST_ADMIN', payload: data})
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'getAllAdmin'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const CreateAdmin = (user, token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const data = JSON.stringify({
            'username': user.username,
            'email': user.email,
            'password': user.password,
            'role': 'super_admin'
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_URL_BE}/admin/register`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
            data: data
        };
        axios(config)
        .then((data) => {
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'CreateAdmin'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const UpdateAdmin = (user, id, token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        let data = {}
        if (user.password === "") {
            data = JSON.stringify({
                'username': user.username,
                'email': user.email,
            });
        } else {
            data = JSON.stringify({
                'username': user.username,
                'email': user.email,
                'password': user.password,
            });
        }

        const config = {
            method: 'put',
            url: `${process.env.REACT_APP_URL_BE}/admin/${id}`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
            data: data
        };
        axios(config)
        .then((data) => {
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'UpdateAdmin'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const DeleteAdmin = (id, token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const config = {
            method: 'delete',
            url: `${process.env.REACT_APP_URL_BE}/admin/${id}`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
        };
        axios(config)
        .then((data) => {
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'DeleteAdmin'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const getAllUsers = (limit, page, token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_URL_BE}/user?limit=${limit}&page=${page}`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
        };
        axios(config)
        .then((data) => {
            dispatch({type:'LIST_USER', payload: data})
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'getAllUsers'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const CreateUser = (user, token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const data = JSON.stringify({
            'firstName': user.firstname,
            'lastName': user.lastname,
            'email': user.email,
            'password': user.password,
            'phoneNumber': user.phoneNumber,
            'address': user.address,    
            'planCode': user.planCode
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_URL_BE}/user`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
            data: data
        };
        axios(config)
        .then((data) => {
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'CreateUser'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const UpdateUser = (user, id, token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        let data = {}
        if (user.password === "") {
            if (user.planCode) {
                data = JSON.stringify({
                    'firstName': user.firstname,
                    'lastName': user.lastname,
                    'email': user.email,
                    'phoneNumber': user.phoneNumber,
                    'address': user.address, 
                    'planCode': user.planCode
                });
            } else {
                data = JSON.stringify({
                    'firstName': user.firstname,
                    'lastName': user.lastname,
                    'email': user.email,
                    'phoneNumber': user.phoneNumber,
                    'address': user.address, 
                });
            }
        } else {
            if (user.planCode) {
                data = JSON.stringify({
                    'firstName': user.firstname,
                    'lastName': user.lastname,
                    'email': user.email,
                    'password': user.password,
                    'phoneNumber': user.phoneNumber,
                    'address': user.address, 
                    'planCode': user.planCode
                });
            } else {
                data = JSON.stringify({
                    'firstName': user.firstname,
                    'lastName': user.lastname,
                    'email': user.email,
                    'password': user.password,
                    'phoneNumber': user.phoneNumber,
                    'address': user.address, 
                });
            }
        }

        const config = {
            method: 'put',
            url: `${process.env.REACT_APP_URL_BE}/user/admin/${id}`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
            data: data
        };
        axios(config)
        .then((data) => {
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'UpdateUser'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const BlockUser = (id, token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const config = {
            method: 'put',
            url: `${process.env.REACT_APP_URL_BE}/user/block/${id}`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
        };
        axios(config)
        .then((data) => {
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'BlockUser'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}