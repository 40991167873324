import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import closeIcon from "../assets/close-icon.svg"
import InfoCircle from "../assets/info-circle.svg"
function PopupWindow (props) {
    const dispatch = useDispatch()
    const errorLog = useSelector((state) => state.authReducer.errorLog);
    const errorMessage = useSelector((state) => state.authReducer.errorMessage);
    const loading = useSelector((state) => state.globalReducer.loading);
    const handleCloseInfo = () => {
        dispatch({type:'ERROR_MESSAGE', payload: ''})
        dispatch({type:'ERROR_LOG', payload: ''})
    }

    return (
        <>
            {loading? 
                <div className="fixed top-0 flex left-0 w-full h-full justify-center items-center bg-[#00000047] z-50">
                    <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status"></div>
                </div>:''}
            {
                errorLog === 'success' ? 
                <div className='w-full flex justify-center absolute'>
                    <div className="bg-green-1 rounded-lg p-[10px_15px_10px_10px] gap-5 h-11 text-base text-lightgrey-50 mb-3 fixed z-50 top-5 mx-auto block" role="alert">
                        <p className='text-sm flex gap-4'>{errorMessage} <img onClick={()=>handleCloseInfo()} className='cursor-pointer' src={closeIcon} alt='closeIcon'/></p>
                    </div>
                </div>
                :
                errorLog !== '' ? 
                <div className='w-full flex justify-center absolute'>
                    <div className="bg-red rounded-lg p-[10px_15px_10px_10px] gap-5 h-11 text-base text-lightgrey-50 mb-3 fixed z-50 top-5 mx-auto block" role="alert">
                        <p className='text-sm flex gap-4'><img src={InfoCircle} alt='InfoCircle'/> {errorMessage} <img onClick={()=>handleCloseInfo()} className='cursor-pointer' src={closeIcon} alt='closeIcon'/></p>
                    </div>
                </div> : ''
            }
        </>
    ) 
}

export default PopupWindow