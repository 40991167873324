import React, { useState } from "react";
import Ink from "../../../assets/hover/Ink.svg";
import Draw from "../../../assets/hover/Draw.svg";
import Erase from "../../../assets/hover/Erase.svg";
import Clear from "../../../assets/hover/Clear.svg";
import ClearAll from "../../../assets/hover/ClearAll.svg";
import Snap from "../../../assets/hover/Snap.svg";
import TouchHover from "../../../assets/hover/Touch.svg";
import MouseHover from "../../../assets/hover/Mouse.png";
import TouchpadHover from "../../../assets/hover/Trackpad.svg";
import RedoHover from "../../../assets/hover/redo.svg";
import UndoHover from "../../../assets/hover/undo.svg";


import { FaCheck } from "react-icons/fa";

// ACTIVE BTN
import SnapIconActive from "../../../assets/icon-footer/snap-active.svg";
import PenIconActive from "../../../assets/icon-footer/pen-active.svg";
import EraseIconActive from "../../../assets/icon-footer/erase-active.svg";
import UndoActive from "../../../assets/icon-footer/undo-active.svg";
import RedoActive from "../../../assets/icon-footer/redo-active.svg";

import penIcon from "../../../assets/icon-footer/pen.svg";
import eraseIcon from "../../../assets/icon-footer/erase.svg";
import resetIcon from "../../../assets/icon-footer/reset.svg";
import snapIcon from "../../../assets/icon-footer/snap.svg";
import trashIcon from "../../../assets/icon-footer/trash.svg";
import UndoNonActive from "../../../assets/icon-footer/undo-nonactive.svg";
import RedoNonActive from "../../../assets/icon-footer/redo-nonactive.svg";

import Touch from "../../../assets/Touch.svg";
import Mouse from "../../../assets/Mouse.svg";
import Trackpad from "../../../assets/Trackpad.svg";
import TouchGrey from "../../../assets/Touch_grey.svg";
import MouseGrey from "../../../assets/Mouse_grey.svg";
import TrackpadGrey from "../../../assets/Trackpad_grey.svg";
import { useSelector, useDispatch } from "react-redux";

export default function GameFooter(props) {
  const dispatch = useDispatch();
  const [redo, setRedo] = useState([])
  const [footerButtons] = useState([
    {
      name: "Pen",
      icon: penIcon,
      iconActive: PenIconActive,
      image: Draw,
    },
    {
      name: "Erase",
      icon: eraseIcon,
      iconActive: EraseIconActive,
      image: Erase,
    },
    {
      name: "Trash",
      icon: trashIcon,
      image: Clear,
    },
    {
      name: "Reset",
      icon: resetIcon,
      image: ClearAll,
    },
    {
      name: "Snap",
      icon: snapIcon,
      iconActive: SnapIconActive,
      image: Snap,
    },
    {
      name: "Undo",
      icon: UndoNonActive,
      iconActive: UndoActive,
      image: UndoHover,
    },
    {
      name: "Redo",
      icon: RedoNonActive,
      iconActive: RedoActive,
      image: RedoHover,
    },
  ]);

  const [footerInput] = useState([
    {
      name: "touch",
      icon: Touch,
      iconGrey: TouchGrey,
      image: TouchHover,
    },
    {
      name: "mouse",
      icon: Mouse,
      iconGrey: MouseGrey,
      image: MouseHover,
    },
    {
      name: "trackpad",
      icon: Trackpad,
      iconGrey: TrackpadGrey,
      image: TouchpadHover,
    },
  ]);

  const inputButton = useSelector((state) => state.globalReducer.inputButton);
  const featureDragable = useSelector((state) => state.globalReducer.featureDragable);
  const gameEvent = useSelector((state) => state.globalReducer.gameEvent);
  const redoEvent = useSelector((state) => state.globalReducer.redoEvent);
  const undoEvent = useSelector((state) => state.globalReducer.undoEvent);
  const typeRedo = useSelector((state) => state.globalReducer.typeRedo);
  const typeUndo = useSelector((state) => state.globalReducer.typeUndo);
  const activeTile = useSelector((state) => state.globalReducer.activeTile);

  const handleTouchInput = (input) => {
    dispatch({ type: "INPUT_BUTTON", payload: input });
    localStorage.removeItem("input_button");
    localStorage.setItem("input_button", input);
  };

  const changeColorDraw = (event) => {
    props.setStatusColorDraw(event)
  };

  const handleClickFooter = (nameButton) => {
    if (nameButton === "Reset") {
      if (gameEvent.length !== 0) {
        dispatch({type: "UNDO_EVENT", payload: [...gameEvent]});
        dispatch({type: "TYPE_UNDO", payload: 'undo all'});
        dispatch({type: "TYPE_REDO", payload: 'redo one'});
        dispatch({ type: "DRAG_ITEMS", payload: [] });
        dispatch({ type: "GAME_EVENT", payload: [] });
        dispatch({type: "REDO_EVENT", payload: []});
      }
    } else if (nameButton === "Snap") {
      if (featureDragable.snap) {
        dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
      } else {
        dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: true }});
        const eventGame = [...gameEvent]
        const countX = []
        const countY = []
        const countGrid = window.screen.width <= 1335 ? 10.4 : 15.6
        console.log(countGrid)
        for (let i = -8; i < 99999; i+=countGrid) {
          countX.push(i)
        }
        for (let i = -308; i < 99999; i+=countGrid) {
          countY.push(i)
        }
        eventGame.forEach(el => {
          countX.forEach((count, i) => {
            if (el.x >= count && el.x < countX[i+1]) {
              el.x = count
            } else if (el.x < -8) {
              el.x = -8
            }
          });
          countY.forEach((count, j) => {
            if (el.y >= count && el.y < countY[j+1]) {
              el.y = count
            } else if (el.y < -308) {
              el.y = -308
            }
          });
        });
        dispatch({ type: "GAME_EVENT", payload: [...eventGame] });
      }
    } else if (nameButton === "Trash") {
      let tempObj = activeTile;
      const tempGameEvent = gameEvent;
      const newGameEvent = []
      const dataForUndo = []
      
      tempGameEvent.forEach((el) => {
        if (tempObj[`${el.id}`]) {
          dataForUndo.push(el)
          delete tempObj[`${el.id}`];
        } else {
          newGameEvent.push(el);
        }
      });
      
      const newArrLine = []
      newGameEvent.forEach(el => {
        if (!el.shadowColor) newArrLine.push(el)
        else dataForUndo.push(el)
      });
      
      dispatch({type: "UNDO_EVENT", payload: [...dataForUndo]});
      dispatch({type: "TYPE_UNDO", payload: 'undo delete'});
      dispatch({ type: "ACTIVE_TILE", payload: { ...tempObj } });
      dispatch({ type: "GAME_EVENT", payload: [...newArrLine] });
    } else if (nameButton === 'Pen') {
      if (featureDragable.pen) {
        dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, pen: false }});
      } else {
        dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, pen: true, erase: false }});
      }
    } else if (nameButton === 'Erase') {
      if (featureDragable.erase) {
        dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, erase: false }});
      } else {
        dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, erase: true, pen: false }});
      }
    } else if (nameButton === "Undo") {
      if (undoEvent.length !== 0) {
        if (typeUndo === 'undo all') {
          dispatch({type: "GAME_EVENT", payload: [...undoEvent]});
          dispatch({type: "TYPE_REDO", payload: 'redo all'});
          dispatch({type: "TYPE_UNDO", payload: 'undo one'});
        } else if (typeUndo === 'undo delete') {
          const dataNow = gameEvent
          undoEvent.forEach((item)=>{
            dataNow.push(item)
          })
          dispatch({type: "GAME_EVENT", payload: [...dataNow]});
          dispatch({type: "TYPE_UNDO", payload: 'undo one'});
        } else {
          const newArr = gameEvent
          const redoData = [...redoEvent]
          redoData.push(newArr[newArr.length-1])
          dispatch({type: "REDO_EVENT", payload: [...redoData]});
          newArr.pop()
          dispatch({type: "GAME_EVENT", payload: [...newArr]});
          dispatch({type: "TYPE_REDO", payload: 'redo one'});
          dispatch({type: "TYPE_UNDO", payload: 'undo one'});
          dispatch({type: "UNDO_EVENT", payload: []});  
        }
      } else if (gameEvent.length !== 0) {
        const newArr = gameEvent
        const redoData = [...redoEvent]
        redoData.push(newArr[newArr.length-1])
        dispatch({type: "REDO_EVENT", payload: [...redoData]});
        newArr.pop()
        dispatch({type: "GAME_EVENT", payload: [...newArr]});
        dispatch({type: "TYPE_REDO", payload: 'redo one'});
        dispatch({type: "TYPE_UNDO", payload: 'undo one'});
        dispatch({type: "UNDO_EVENT", payload: []});
      }
    } else if (nameButton === "Redo") {
      if (typeRedo === 'redo all') {
        dispatch({type: "GAME_EVENT", payload: []});
        dispatch({type: "TYPE_UNDO", payload: 'undo all'});
        dispatch({type: "TYPE_REDO", payload: 'redo one'});
      } else if (redoEvent.length !== 0) {
        const newArrEvent = gameEvent
        const arrRedo = redoEvent
        newArrEvent.push(arrRedo[arrRedo.length-1])
        arrRedo.pop()
        dispatch({type: "REDO_EVENT", payload: [...arrRedo]});
        dispatch({type: "GAME_EVENT", payload: [...newArrEvent]});
      }
    }
  };

  return (
    <div className="w-full fixed flex justify-beetwen rounded-t-lg px-8 py-6 maxXl:py-3 bg-white bottom-0 shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_6px_0px_#F4F4F4] z-20">
      <div className="w-full flex gap-12">
        <div className="tooltip flex gap-4 opacity-100">
        {
          props.statusBg === 'yellow' ?
          <button
            onClick={() => changeColorDraw("#333333")}
            className={`w-12 maxXl:w-10 h-12 maxXl:h-10 bg-black shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] rounded-lg grid items-center border-[3px] border-[#000] justify-items-center`}
          >
            {props.statusColorDraw === "#333333" ? <FaCheck color="#fff" className="text-2xl maxXl:text-sm" /> : ""}
          </button>
          :
          <button
            onClick={() => changeColorDraw("#fff")}
            className={`w-12 maxXl:w-10 h-12 maxXl:h-10 bg-white shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] rounded-lg grid items-center border-[3px] border-[#000] justify-items-center`}
          >
            {props.statusColorDraw === "#fff" ? <FaCheck color="#333333" className="text-2xl maxXl:text-sm" /> : ""}
          </button>
        }
          <button
            onClick={() => changeColorDraw("#B32F2A")}
            className={`w-12 maxXl:w-10 h-12 maxXl:h-10 bg-red shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] rounded-lg grid items-center border-[3px] border-[#000] justify-items-center`}
          >
            {props.statusColorDraw === "#B32F2A" ? <FaCheck color="#fff" className="text-2xl maxXl:text-sm" /> : ""}
          </button>
          {/* <div className={`cursor-pointer shadow-[inset_0px_-4px_0px_rgba(156,_156,_156,_0.14)] w-12 maxXl:w-10 h-12 maxXl:h-10 ${props.statusBg === 'yellow' ? 'bg-black' : 'bg-white'} border-[3px] border-grey-100 rounded-[10px]`}></div>
          <div className="cursor-pointer shadow-[inset_0px_-4px_0px_rgba(156,_156,_156,_0.14)] w-12 maxXl:w-10 h-12 maxXl:h-10 bg-red border-[3px] border-black rounded-[10px] "></div> */}
          <img
            alt="Icon Ink"
            src={Ink}
            className="tooltiptext opacity-100 scale-100 bottom-10 maxXl:bottom-6 -left-8 max-w-[200px]"
          />
        </div>

        <div className="flex gap-3">
          {footerButtons.map(({ name, icon, iconActive, image }) => (
            <button
              onClick={() => handleClickFooter(name)}
              className={`${name === 'Undo' ? 'ml-6':''} cursor-pointer flex justify-center justify-items-center items-center w-12 maxXl:w-10 h-12 maxXl:h-10 p-2.5 border-[3px] rounded-[10px] tooltip opacity-100 ${name === 'Snap' && featureDragable.snap || name === 'Erase' && featureDragable.erase || name === 'Pen' && featureDragable.pen ? 'bg-yellow-300 hover:bg-yellow-400 border-green-1 shadow-[0px_3px_0px_#2A5328,_inset_0px_2px_0px_rgba(114,_114,_114,_0.15)]' : name === 'Undo' && gameEvent.length === 0 && undoEvent.length === 0 ? 'bg-[#E0E0E0] border-grey-3 cursor-not-allowed shadow-[0px_3px_0px_#A3A3A3,_inset_0px_3px_0px_rgba(255,_255,_255,_0.15)]' : name === 'Redo' && typeRedo === 'redo all' ? 'bg-green-1 hover:bg-green-2 border-yellow-300 shadow-[0px_3px_0px_#E1BA45,_inset_0px_2px_0px_rgba(255,_255,_255,_0.15)]' : name === 'Redo' && redoEvent.length === 0 ? 'bg-[#E0E0E0] border-grey-3 cursor-not-allowed shadow-[0px_3px_0px_#A3A3A3,_inset_0px_3px_0px_rgba(255,_255,_255,_0.15)]':'bg-green-1 hover:bg-green-2 border-yellow-300 shadow-[0px_3px_0px_#E1BA45,_inset_0px_2px_0px_rgba(255,_255,_255,_0.15)]'}`}
              key={name}
            >
              {
                name === 'Vowel' || name === 'Snap'? 
                <img
                  alt="Icon Ink"
                  src={image}
                  className="tooltiptext scale-125 bottom-12 maxXl:bottom-8 -left-3 max-w-2xl"
                />
                :
                name === 'Trash' || name === 'Reset' ?
                <img
                  alt="Icon Ink"
                  src={image}
                  className="tooltiptext scale-125 bottom-12 maxXl:bottom-8 -left-1 max-w-2xl"
                />
                :
                name === 'Undo' || name === 'Redo' ?
                <img
                  alt="Icon Ink"
                  src={image}
                  className="tooltiptext scale-125 bottom-14 maxXl:bottom-8 -left-9 max-w-2xl"
                />
                :
                name === 'Erase' ?
                <img
                  alt="Icon Ink"
                  src={image}
                  className="tooltiptext scale-125 bottom-12 maxXl:bottom-8 -left-16 max-w-2xl"
                />
                :
                <img
                  alt="Icon Ink"
                  src={image}
                  className="tooltiptext scale-125 bottom-12 maxXl:bottom-8 -left-5 max-w-2xl"
                />
              }
              {
                name === 'Undo' ?
                <img alt="icon-input" src={gameEvent.length !== 0 || undoEvent.length !== 0 ? iconActive : icon} />
                :
                name === 'Redo' ?
                <img alt="icon-input" src={redoEvent.length !== 0 || typeRedo === 'redo all' ? iconActive : icon} />
                :
                <img alt="icon-input" src={name === 'Snap' && featureDragable.snap || name === 'Pen' && featureDragable.pen || name === 'Erase' && featureDragable.erase ? iconActive : icon} />
              }
            </button>
          ))}
        </div>
      </div>
      <div className="flex gap-4">
        {footerInput.map(({ name, icon, image, iconGrey }) => (
          <>
            {inputButton === name ? (
              <div
                className="tooltip opacity-100 cursor-pointer shadow-[0px_3px_0px_#E1BA45,_inset_0px_3px_0px_rgba(255,_255,_255,_0.15)] w-12 maxXl:w-10 h-12 maxXl:h-10 p-2.5 bg-green-1 border-[3px] border-yellow-300 rounded-[10px] "
                key={name}
                onClick={() => handleTouchInput(name)}
              >
                {name === "trackpad" ? (
                  <img
                    alt="Icon Ink"
                    src={image}
                    className="tooltiptext scale-100 bottom-12 maxXl:bottom-8 -right-7 max-w-[200px]"
                  />
                ) : name === "touch" ? (
                  <img
                    alt="Icon Ink"
                    src={image}
                    className="tooltiptext scale-100 bottom-12 maxXl:bottom-8 -left-8 max-w-[200px]"
                  />
                ) : (
                  <img
                    alt="Icon Ink"
                    src={image}
                    className="tooltiptext scale-100 bottom-12 maxXl:bottom-8 -left-16 max-w-[200px]"
                  />
                )}

                <img alt="icon-input" src={icon} />
              </div>
            ) : (
              <div
                className="tooltip opacity-100 cursor-pointer shadow-[0px_3px_0px_#A3A3A3,_inset_0px_3px_0px_rgba(255,_255,_255,_0.15)] w-12 maxXl:w-10 h-12 maxXl:h-10 p-2.5 bg-[#E0E0E0] border-[3px] border-grey-3 rounded-[10px] "
                key={name}
                onClick={() => handleTouchInput(name)}
              >
                {name === "trackpad" ? (
                  <img
                    alt="Icon Ink"
                    src={image}
                    className="tooltiptext scale-100 bottom-12 maxXl:bottom-8 -right-7 max-w-[200px]"
                  />
                ) : name === "touch" ? (
                  <img
                    alt="Icon Ink"
                    src={image}
                    className="tooltiptext scale-100 bottom-12 maxXl:bottom-8 -left-8 max-w-[200px]"
                  />
                ) : (
                  <img
                    alt="Icon Ink"
                    src={image}
                    className="tooltiptext scale-100 bottom-12 maxXl:bottom-8 -left-16 max-w-[200px]"
                  />
                )}

                <img alt="icon-input" src={iconGrey} />
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
