import React, {useEffect, useState} from 'react';
import {PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';
import StatusMessages, {useMessages} from './StatusMessages';
import './style.css'
import { updateApplePay } from '../../redux/actions/Plan';
import { useDispatch } from 'react-redux';

const ApplePay = (props) => {
  const dispatch = useDispatch()
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [messages, addMessage] = useMessages();
  const token = localStorage.getItem('id_token');

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    const pr = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: props.titlePlan,
        amount: props.totalPrice*100,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });
    // Check the availability of the Payment Request API.
    pr.canMakePayment().then(result => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    pr.on('paymentmethod', async (e) => {
      // const {error: backendError, clientSecret} = await fetch(
      //   '/create-payment-intent',
      //   {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({
      //       paymentMethodType: 'card',
      //       currency: 'usd',
      //     }),
      //   }
      // ).then((r) => r.json());

      // if (backendError) {
      //   addMessage(backendError.message);
      //   return;
      // }

      addMessage('Client secret returned');
      
      const {
        error: stripeError,
        paymentIntent,
      } = await stripe.confirmCardPayment(props.clientSecret, {
        payment_method: e.paymentMethod.id,
      }, { handleActions: false });

      if (stripeError) {
        // Show error to your customer (e.g., insufficient funds)
        addMessage(stripeError.message);
        return;
      }

      dispatch(updateApplePay(token, props.clientSecret, (result)=>{
        window.location.replace(result.url)
      }))
      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.

      addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    });
  }, [stripe, elements, addMessage]);

  return (
    <>
      {paymentRequest && <PaymentRequestButtonElement options={{paymentRequest}} />}
      <StatusMessages messages={messages} />
    </>
  );
};

export default ApplePay;