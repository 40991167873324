import React from "react";
import Logo from "../assets/logo.png";
import CheckBox from "../assets/CheckBox.svg";
import { Link } from "react-router-dom";

function SuccessResetPasswordPage() {
  return (
    <>
      <div className="grid justify-items-center items-center h-screen">
        <div className="bg-white rounded-[15px] shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14)] dark:bg-gray-800 dark:border-gray-700 py-[65px] px-[60px] max-w-[616px] min-w-[515px] min-h-[436px]">
          <div className="flex flex-col items-center">
            <img alt="logo" src={CheckBox} className="w-[104.8px]" />
            <h1 className="text-4xl text-green-1 font-gilroyBold tracking-wide mt-5">Successful Password Reset!</h1>
            <div className='w-[496px] text-center px-4'>
                <p className="font-normal text-base mt-[26.16px] mb-2.5 text-center text-grey-2 tracking-wide">You can now use your new password to login to your account</p>
                <Link to="/login">
                    <button
                    className="flex shadow-[0px_4px_0px_#e9bf45,inset_0px_3px_0px_rgb(255_255_255_/_15%)] justify-center justify-items-center items-center rounded-[10px] w-full h-[62px] mt-[17.32px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold border-[3px] border-yellow hover:border-yellow"
                    >
                        <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">Login</span>
                    </button>
                </Link>
                <img alt="logo" src={Logo} className="w-[173.25px] mx-auto mt-5" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SuccessResetPasswordPage;
