import axios from "axios";

export const registerTrialSendOtp = (user, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const data = JSON.stringify({
            'firstName': user.firstname,
            'lastName': user.lastname,
            'username': user.username,
            'email': user.email,
            'password': user.password,
            'phoneNumber': user.phoneNumber
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_URL_BE}/user/register`,
            headers: { 
                'Content-Type': 'application/json',
            },
            data: data
        };
        axios(config)
        .then(({data}) => {
            dispatch({type:'EMAIL_VERIFY', payload: data.email})
            dispatch({type:'LOADING', payload: false})
            callback(data.message)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'registerTrialSendOtp'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const userVerifyEmail = (user, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const data = JSON.stringify({
            'token': user.token,
            'email': user.email
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_URL_BE}/user/verify`,
            headers: { 
                'Content-Type': 'application/json',
            },
            data: data
        };
        axios(config)
        .then(({data}) => {
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'userVerifyEmail'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(false)
            }, 2000);
        });
    }
}

export const userResendOtp = (user, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
      const data = JSON.stringify({
        'email': user.email
      });

      const config = {
          method: 'post',
          url: `${process.env.REACT_APP_URL_BE}/user/resend`,
          headers: { 
              'Content-Type': 'application/json',
          },
          data: data
      };
      axios(config)
      .then(({data}) => {
          dispatch({type:'LOADING', payload: false})
          callback(data)
      }).catch(({response}) => {
          dispatch({type:'LOADING', payload: false})
          dispatch({type:'ERROR_LOG', payload: 'userResendOtp'})
          dispatch({type:'ERROR_MESSAGE', payload: response.data.errors})
          setTimeout(() => {
              dispatch({type:'ERROR_MESSAGE', payload: ''})
              dispatch({type:'ERROR_LOG', payload: ''})
              callback(false)
          }, 2000);
      });
    }
}

export const login = (user, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
      const data = JSON.stringify({
        'email': user.email,
        'password': user.password
      });

      const config = {
          method: 'post',
          url: `${process.env.REACT_APP_URL_BE}/user/login`,
          headers: { 
              'Content-Type': 'application/json',
          },
          data: data
      };
      axios(config)
      .then(({data}) => {
          dispatch({type:'LOADING', payload: false})
          callback(data)
      }).catch(({response}) => {
          dispatch({type:'LOADING', payload: false})
          dispatch({type:'ERROR_LOG', payload: 'loginUser'})
          dispatch({type:'ERROR_MESSAGE', payload:  response.data.errors.message ? response.data.errors.message : response.data.errors})
          setTimeout(() => {
              dispatch({type:'ERROR_MESSAGE', payload: ''})
              dispatch({type:'ERROR_LOG', payload: ''})
              callback(false)
          }, 2000);
      });
    }
}

export const resetPassword = (user, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
      const data = JSON.stringify({
        'email': user.email,
      });

      const config = {
          method: 'post',
          url: `${process.env.REACT_APP_URL_BE}/user/newpassword`,
          headers: { 
              'Content-Type': 'application/json',
          },
          data: data
      };
      axios(config)
      .then(({data}) => {
          dispatch({type:'LOADING', payload: false})
          callback(data)
      }).catch(({response}) => {
          dispatch({type:'LOADING', payload: false})
          dispatch({type:'ERROR_LOG', payload: 'resetPassword'})
          dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message})
          setTimeout(() => {
              dispatch({type:'ERROR_MESSAGE', payload: ''})
              dispatch({type:'ERROR_LOG', payload: ''})
              callback(false)
          }, 2000);
      });
    }
}

export const createNewPassword = (user, token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
      const data = JSON.stringify({
        'password': user.password
      });

      const config = {
          method: 'put',
          url: `${process.env.REACT_APP_URL_BE}/user/newpassword`,
          headers: { 
              'Content-Type': 'application/json',
              'token': token
          },
          data: data
      };
      axios(config)
      .then(({data}) => {
          dispatch({type:'LOADING', payload: false})
          callback(data)
      }).catch(({response}) => {
          dispatch({type:'LOADING', payload: false})
          dispatch({type:'ERROR_LOG', payload: 'createNewPassword'})
          dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message})
          setTimeout(() => {
              dispatch({type:'ERROR_MESSAGE', payload: ''})
              dispatch({type:'ERROR_LOG', payload: ''})
              callback(false)
          }, 2000);
      });
    }
}

export const getAllMember = (token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})

      const config = {
          method: 'get',
          url: `${process.env.REACT_APP_URL_BE}/member`,
          headers: { 
              'Content-Type': 'application/json',
              'token': token
          },
      };
      axios(config)
      .then(({data}) => {
          dispatch({type:'LOADING', payload: false})
          callback(data)
      }).catch(({response}) => {
          dispatch({type:'LOADING', payload: false})
          dispatch({type:'ERROR_LOG', payload: 'createNewPassword'})
        if (response.data.errors.message) {
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message})
        } else {
            dispatch({type:'ERROR_MESSAGE', payload: "Ypu Don't Have Access"})            
        }
          setTimeout(() => {
              dispatch({type:'ERROR_MESSAGE', payload: ''})
              dispatch({type:'ERROR_LOG', payload: ''})
              callback(false)
          }, 2000);
      });
    }
}

export const createNewMember = (user, token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        var data = JSON.stringify({
            "firstName": user.firstname,
            "lastName": user.lastname,
            "email": user.email,
            "password": user.password,
            "isActive": user.isActive,
          });
      const config = {
          method: 'post',
          url: `${process.env.REACT_APP_URL_BE}/member`,
          headers: { 
              'Content-Type': 'application/json',
              'token': token
          },
          data:data
      };
      axios(config)
      .then(({data}) => {
          dispatch({type:'LOADING', payload: false})
          callback(data)
      }).catch(({response}) => {
          dispatch({type:'LOADING', payload: false})
          dispatch({type:'ERROR_LOG', payload: 'createNewPassword'})
            if (response.data.errors.message) {
                dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message})
            } else {
                dispatch({type:'ERROR_MESSAGE', payload: response.data.errors})            
            }
          setTimeout(() => {
              dispatch({type:'ERROR_MESSAGE', payload: ''})
              dispatch({type:'ERROR_LOG', payload: ''})
              callback(false)
          }, 2000);
      });
    }
}

export const editNewMember = (user, token, idMember, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        var data = JSON.stringify({
            "firstName": user.firstname,
            "lastName": user.lastname,
            "email": user.email,
            "isActive": user.isActive,
          });
      const config = {
          method: 'put',
          url: `${process.env.REACT_APP_URL_BE}/member/${idMember}`,
          headers: { 
              'Content-Type': 'application/json',
              'token': token
          },
          data:data
      };
      axios(config)
      .then(({data}) => {
          dispatch({type:'LOADING', payload: false})
          callback(data)
      }).catch(({response}) => {
          dispatch({type:'LOADING', payload: false})
          dispatch({type:'ERROR_LOG', payload: 'createNewPassword'})
            if (response.data.errors.message) {
                dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message})
            } else {
                dispatch({type:'ERROR_MESSAGE', payload: response.data.errors})            
            }
          setTimeout(() => {
              dispatch({type:'ERROR_MESSAGE', payload: ''})
              dispatch({type:'ERROR_LOG', payload: ''})
              callback(false)
          }, 2000);
      });
    }
}

export const deleteNewMember = (token, idMember, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
      const config = {
          method: 'delete',
          url: `${process.env.REACT_APP_URL_BE}/member/${idMember}`,
          headers: { 
              'Content-Type': 'application/json',
              'token': token
          },
      };
      axios(config)
      .then(({data}) => {
          dispatch({type:'LOADING', payload: false})
          callback(data)
      }).catch(({response}) => {
          dispatch({type:'LOADING', payload: false})
          dispatch({type:'ERROR_LOG', payload: 'createNewPassword'})
            if (response.data.errors.message) {
                dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message})
            } else {
                dispatch({type:'ERROR_MESSAGE', payload: response.data.errors})            
            }
          setTimeout(() => {
              dispatch({type:'ERROR_MESSAGE', payload: ''})
              dispatch({type:'ERROR_LOG', payload: ''})
              callback(false)
          }, 2000);
      });
    }
}