import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileNav from "../../components/profile/ProfileNav";
import { detailUser } from "../../redux/actions/User";
import PopupWindow from "../../components/PopupWindow";
import PhotoDefault from "../../assets/photo.png";
import { getDetailPlan } from "../../redux/actions/Plan";
import { getAllNotification } from "../../redux/actions/Notification";
import moment from 'moment'

export default function ProfilePlan() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loading = useSelector((state) => state.globalReducer.loading);
  const token = localStorage.getItem('id_token');
  let userDetail = localStorage.getItem('userDetail')
  userDetail = JSON.parse(userDetail)

  const [user, setUser] = useState({})
  const [selectedImage, setSelectedImage] = useState('');
  const [detailPlan, setDetailPlan] = useState({})

  useEffect(()=> {
    const docBody = document.querySelector("body");
    docBody.classList.remove("green-bg");
    docBody.classList.add("yellow-bg");
    dispatch(getAllNotification(token))
    dispatch(getDetailPlan(token, (result)=> {
      setDetailPlan(result);
    }))
    if (userDetail) {
      setUser(userDetail)
      setSelectedImage(userDetail.profileImage)
    } else {
      dispatch(detailUser(token, (result)=>{
        setUser(result)
        localStorage.setItem('userDetail', JSON.stringify(result))
        if (result === 'invalid token') {
          localStorage.removeItem('userDetail')
          localStorage.removeItem('id_token')
          localStorage.removeItem('input_button')
          navigate('/')
        }
      }))
    }
  },[])

  return (
    <div className="min-h-screen flex justify-center items-center">
      {loading? 
        <div className="fixed top-0 flex left-0 w-full h-full justify-center items-center bg-[#00000047] z-50">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status"></div>
        </div>:''
      }
      <div className="py-10">
        <div className="grid justify-items-center items-center h-screen md:py-0 ">
          <div className="h-auto min-h-[839px] min-w-[906px] bg-white flex gap-0 flex-col items-center rounded-2xl overflow-hidden">
            <div className="flex gap-6 items-center w-full h-auto bg-green-1 px-10 py-6">
              {
                selectedImage ?
                <>
                    <img
                    src={selectedImage}
                    className="h-28 w-28 object-contain bg-grey-1000 border-4 border-white rounded-xl"
                    alt="Thumb"
                    />
                </>
                :
                <img alt="logo" src={PhotoDefault} className='h-28 w-28 bg-grey-1000 border-4 border-white rounded-xl'/>
              }
              <div className="h-36 w-1/6 flex flex-col gap-3 justify-center">
                <h1 className="text-2xl text-white max-w-[500px] w-max">{user.firstName ? user.firstName : ''} {user.lastName ? user.lastName : ''}</h1>
                {user.firstName?<h2 className="bg-yellow-300 text-green-1  text-center rounded-xl">Principal Trainer</h2>:''}
              </div>
            </div>
            <div className="flex gap-12 w-full h-auto min-h-[630px] bg-white p-12 divide-x" style={{color:'#F2F2F2'}}>
              <ProfileNav />

              <div className="pl-12 flex w-full flex-col gap-6 ">
                <div className="flex flex-col items-start p-5 gap-[15px] border border-lightgrey-100 rounded-[10px]">
                    <p className="font-gilroyBold font-bold text-xl flex item-center tracking-wide text-green-1">Current Plan</p>
                    <div className="flex flex-col item-start gap-[5px]">
                      <p className="font-gilroyBold font-extrabold text-3xl text-black">{detailPlan.plan ? detailPlan.plan.title : "You Don't Have Plan"}</p>
                      {
                        detailPlan.plan?
                        detailPlan.plan.features.length !== 0 ?
                        detailPlan.plan.features.map((item, i)=>{
                          return (
                            <p key={i} className="font-gilroyMedium font-medium text-base items-center flex tracking-wide text-grey-3">{item}</p>
                          )
                        })
                        : '' : ''
                      }
                    </div>
                    <p className="text-lightgrey-700 font-gilroyMedium text-sm font-semibold tracking-wide flex items-center">Active Until: {detailPlan.activation? moment(detailPlan.activation.dateEnd).format('MMMM Do YYYY, h:mm:ss a'):'-'}</p>
                </div>
                <div className="flex flex-col items-start p-5 gap-[15px] border border-lightgrey-100 rounded-[10px]">
                  <p className="font-gilroyBold font-bold text-xl flex item-center tracking-wide text-green-1">Billing History</p>
                  <table className="w-full">
                    <thead className="border-b">
                      <tr>
                        <th className="text-left text-lightgrey-700 font-gilroyMedium font-semibold text-xs p-2">Date</th>
                        <th className="text-left text-lightgrey-700 font-gilroyMedium font-semibold text-xs p-2">Type</th>
                        <th className="text-left text-lightgrey-700 font-gilroyMedium font-semibold text-xs p-2">Transaction ID</th>
                        <th className="text-left text-lightgrey-700 font-gilroyMedium font-semibold text-xs p-2">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        detailPlan.billing_history?
                        detailPlan.billing_history.length !== 0 ? 
                        detailPlan.billing_history.map((item, j)=>{
                          return (
                            <tr key={j}>
                              <td className="p-2 text-left text-grey-3 font-gilroyMedium font-medium tracking-wide text-xs w-36">{moment(item.createdAt).format('MMM Do YYYY, h:mm a')}</td>
                              <td className="p-2 text-left text-grey-3 font-gilroyMedium font-medium tracking-wide text-xs">{item.title}</td>
                              <td className="p-2 text-left text-grey-3 font-gilroyMedium font-medium tracking-wide text-xs">{item.payment.id_transaction}</td>
                              <td className="p-2 text-left text-grey-3 font-gilroyMedium font-medium tracking-wide text-xs">${item.payment.totalPrice} USD</td>
                            </tr>
                          )
                        })
                        :'':''
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
