import clsx from "clsx";
import * as React from "react";
import { useState } from "react";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";

const vokal = ["a", "i", "u", "e", "o"];
export default function DraggableBox({
  word,
  prefix,
  suffixes,
  suffixes2,
  root,
  color,
  addNewItem,
  handleSetAlfabet,
  typeWord
}) {
  const [draged, setDrag] = React.useState(false);
  // const [pageX, setPageX] = React.useState(0);
  // const [pageY, setPageY] = React.useState(0);
  const featureDragable = useSelector((state) => state.globalReducer.featureDragable);
  const gameEvent = useSelector((state)=>state.globalReducer.gameEvent)

  const handleStop = (e) => {
    if (typeWord=== "prefix" || typeWord=== "root" || typeWord=== "suffixes" || typeWord=== "suffixes2") {
      addNewItem(e, word, "alfabet", typeWord);
    } else {
      addNewItem(e, word, typeWord);
    }
    setDrag(false);
  }

  const handleDrag = async (e) => {
    setDrag(true);
    if (typeWord=== "prefix" || typeWord=== "root" || typeWord=== "suffixes" || typeWord=== "suffixes2") {
      await addNewItem(e, word, "alfabet", typeWord, 'tempId');
    } else {
      await addNewItem(e, word, typeWord, 'tempId');
    }
    handleSetAlfabet();
  }

  return (
    <div className="flex">
      <div
          className={clsx(
            "flex justify-center justify-items-center  bg-white place-content-center cursor-pointer pt-2 maxXl:pt-1",
              root
              ? "block shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] w-[68px] maxXl:w-[58px] h-[45px] maxXl:h-[35px] rounded-[10px] maxXl:rounded-[8px] border-[3px] maxXl:border-2"
              : suffixes2
              ? "block shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] w-[68px] maxXl:w-[58px] h-[45px] maxXl:h-[35px] rounded-[10px] maxXl:rounded-[8px] border-[3px] maxXl:border-2"
              : "block shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] w-[50px] maxXl:w-[40px] h-[45px] maxXl:h-[35px] rounded-[10px] maxXl:rounded-[8px] border-[3px] maxXl:border-2",

            vokal.includes(word)
              ? "border-red text-red"
              : prefix
              ? "text-yellow-600 border-black"
              : root
              ? "text-root-600 border-black"
              : suffixes
              ? "text-red border-black"
              : suffixes2
              ? "text-red border-black"
              : "border-black text-black",

            draged ? "" : "absolute"
          )}
        >
          <p
            className={clsx(
              "text-center font-gilroyMedium font-bold h-10  text-sm",
              "unset"
            )}
          >
            {word}
          </p>
      </div>
      <Draggable position={draged ? { x: 0, y: 0 } : { x: 0, y: 0 }} grid={featureDragable.snap ? [50, 50] : ''} onStop={(e)=>{handleStop(e)}} onDrag={(e)=>{handleDrag(e)}} >
        <div
            className={clsx(
              "flex justify-center justify-items-center bg-white place-content-center cursor-pointer pt-2 maxXl:pt-1",
                draged
                ? "fixed justify-center justify-items-center bg-white place-content-center cursor-pointer px-[30px] rounded-[20px] border-[3px] shadow-[inset_0px_-18px_0px_rgba(156,_156,_156,_0.14)] fix min-w-[156px] min-h-[156px] hidden"
                : root
                ? "block shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] w-[68px] maxXl:w-[58px] h-[45px] maxXl:h-[35px] rounded-[10px] maxXl:rounded-[8px] border-[3px] maxXl:border-2"
                : suffixes2
                ? "block shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] w-[68px] maxXl:w-[58px] h-[45px] maxXl:h-[35px] rounded-[10px] maxXl:rounded-[8px] border-[3px] maxXl:border-2"
                : "block shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] w-[50px] maxXl:w-[40px] h-[45px] maxXl:h-[35px] rounded-[10px] maxXl:rounded-[8px] border-[3px] maxXl:border-2 min-w-[50px] maxXl:min-w-[40px]",
              
              vokal.includes(word)
                ? "border-red text-red"
                : prefix
                ? "text-yellow-600 border-black"
                : root
                ? "text-root-600 border-black min-w-[68px] maxXl:min-w-[58px]"
                : suffixes
                ? "text-red border-black min-w-[50px] maxXl:min-w-[40px]"
                : suffixes2
                ? "text-red border-black min-w-[68px] maxXl:min-w-[58px]"
                : "border-black text-black"
            )}
          >
            <p
              className={clsx(
                "text-center font-gilroyMedium font-bold h-10 text-sm",
                draged ? "text-[90px]" : "unset"
              )}
            >
              {word}
            </p>
        </div>
      </Draggable>
    </div>
  );
}
