import React from "react";
import Logo from "../assets/logo.png";
import { NavLink, Link } from "react-router-dom";

import Button from "./Button";

export default function Navbar(props) {
  // const navigate = useNavigate();
  const menuBurger = () => {
    const menu = document.querySelector(".mobile-menu");
    menu.classList.toggle("hidden");
  };

  // const goLogout = () => {
  //   localStorage.clear();
  //   navigate("/");
  // };

  return (
    <>
      <nav className="w-full shadow-3xl bg-white sticky top-0 z-50">
        <div className="mx-auto">
          <div className="flex justify-between py-3">
            <div className="w-full flex">
              {
                props.linkNonActive ? 
                <img alt="logo" src={Logo} className="w-[173.25px]" />
                :
                <NavLink
                  to="/"
                  activeclass="active"
                  className="navbarBtn group flex justify-center px-7 min-w-[207px] max-w-[207px] cursor-pointer rounded-[10px]"
                >
                  <img alt="logo" src={Logo} className="w-[173.25px]" />
                </NavLink>
              }
            </div>
            {props.token?'':<div className="items-center justify-end flex w-2/3 maxMd:hidden">
              <div className="flex flex-row items-start px-7">
                <div className="flex justify-center items-center gap-2.5">
                  {
                    !props.signUp ? '' : 
                    <Link to="/login/select-plan">
                      <button
                          className={`flex shadow-[inset_0px_6px_0px_rgba(255,_255,_255,_0.15)] justify-center justify-items-center items-center rounded-[10px] w-[110px] h-[45px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold`}
                        >
                          <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">Sign Up</span>
                      </button>
                    </Link>
                  }
                  {
                    !props.signIn ? '' :
                    <Link to="/login">
                      <button
                          className={`flex shadow-[inset_0px_6px_0px_rgba(255,_255,_255,_0.15)] justify-center justify-items-center items-center rounded-[10px] w-[110px] h-[45px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold`}
                        >
                          <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">Sign In</span>
                      </button>
                    </Link>
                  }
                </div>
              </div>
            </div>}
            {/* <!-- Mobile menu button --> */}
            <div className="md:hidden flex items-center mr-7">
              <button
                onClick={() => menuBurger()}
                className="outline-none mobile-menu-button"
              >
                <svg
                  className=" w-6 h-6 text-grey-500 hover:text-green "
                  x-show="!showMenu"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* <!-- mobile menu --> */}
        {props.token? '':<div className="hidden mobile-menu">
          <ul className="">
            {
              !props.signUp ? '' : 
              <li className="active flex justify-center py-3">
                <Link to="/login/select-plan">
                  <Button text={"Sign Up"} image={false} width={"w-[155px]"} />
                </Link>
              </li>
            }
            <li className="flex justify-center py-3">
              <Link to="/login">
                <Button text={"Sign In"} image={false} width={"w-[171px]"} />
              </Link>
            </li>
          </ul>
        </div>}
      </nav>
    </>
  );
}
