import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../assets/logo.png";
import Message from "../assets/Message.svg";
import PopupWindow from "../components/PopupWindow";

import { userVerifyEmail, userResendOtp } from "../redux/actions/Auth";

function VerifyEmailPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const param = useParams()

  const [codeVerify, setCodeVerify] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault()
    if (codeVerify !== '') {
      const user = {
        email: param.emailAddress,
        token: codeVerify
      }
      submitFormVerify(user)
    } else {
      dispatch({type:'ERROR_LOG', payload: 'codeVerifyEmpty'})
      dispatch({type:'ERROR_MESSAGE', payload: 'Please Input Code Verification'})
      setTimeout(() => {
          dispatch({type:'ERROR_MESSAGE', payload: ''})
          dispatch({type:'ERROR_LOG', payload: ''})
      }, 2000);   
    }
  }

  const submitFormVerify = async (user) => {
    await dispatch(userVerifyEmail(user, (result) => {
      if (result.message === 'User has been already verified. Please Login') {
        dispatch({type:'ERROR_LOG', payload: 'success'})
        dispatch({type:'ERROR_MESSAGE', payload: 'User has been already verified. Please Login'})
        setTimeout(() => {
            dispatch({type:'ERROR_MESSAGE', payload: ''})
            dispatch({type:'ERROR_LOG', payload: ''})
            navigate('/register/verified')
        }, 2000); 
      } else if (result.message === 'register succsesfully') {
        navigate('/register/verified')
      } else if (result.token && result.clientSecret) {
        localStorage.setItem('id_token', result.token)
        localStorage.setItem('userDetail', JSON.stringify(result.user))
        dispatch({type:'CLIENT_SECRET', payload: result.clientSecret})
        navigate('/game/select-plan/payment/process')
      }
    }));
  }

  const resendCode = async () => {
    const user = {
      email: param.emailAddress
    }
    await dispatch(userResendOtp(user, (result) => {
      if (result.message === 'User has been already verified. Please Login') {
        dispatch({type:'ERROR_LOG', payload: 'success'})
        dispatch({type:'ERROR_MESSAGE', payload: 'User has been already verified. Please Login'})
        setTimeout(() => {
            dispatch({type:'ERROR_MESSAGE', payload: ''})
            dispatch({type:'ERROR_LOG', payload: ''})
            navigate('/register/verified')
        }, 2000); 
      }  else if (result.message === 'A verification email has been sent') {
        dispatch({type:'ERROR_LOG', payload: 'success'})
        dispatch({type:'ERROR_MESSAGE', payload: 'A verification email has been sent'})
        setTimeout(() => {
            dispatch({type:'ERROR_MESSAGE', payload: ''})
            dispatch({type:'ERROR_LOG', payload: ''})
        }, 2000); 
      }
    }))
  }

  return (
    <>
      <div className="grid justify-items-center items-center h-screen">
        <PopupWindow/>
        <div className="bg-white rounded-[15px] shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14)] dark:bg-gray-800 dark:border-gray-700 py-[65px] px-[60px] max-w-[616px] min-w-[515px] min-h-[436px]">
          <div className="flex flex-col items-center">
            <img alt="logo" src={Message} className="w-[104.8px]" />
            <div className='w-[496px] text-center px-6'>
                <h1 className="text-4xl text-green-1 font-gilroyBold tracking-wide mt-5">Verify Your Email Address</h1>
                <p className="font-normal text-base mt-[26.16px] mb-2.5 text-center text-grey-2 tracking-wide">You will need to verify your email to complete regisration</p>
                <p className="font-normal text-base text-center text-grey-2 tracking-wide">An email has been sent code verify to <span className='text-blue'>{param.emailAddress}</span> to verify your account. if you have not received the email after a few minutes, please check your spam folder</p>
                <form className="w-full max-w-lg" onSubmit={e=>handleSubmit(e)}>
                    <div className="relative my-6">
                        <div className="w-full">
                            <input onChange={e=>setCodeVerify(e.target.value)} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" type="text" placeholder="Enter Code Verification" />
                        </div>
                    </div>
                    <button
                    className="flex shadow-[0px_4px_0px_#e9bf45,inset_0px_3px_0px_rgb(255_255_255_/_15%)] justify-center justify-items-center items-center rounded-[10px] w-full h-[62px] mt-[17.32px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold border-[3px] border-yellow hover:border-yellow" type="submit"
                    >
                        <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">Verfy Email</span>
                    </button>
                    <button type="button" onClick={()=>{resendCode()}} className="text-blue cursor-pointer font-gilroyBold w-full text-center mt-5">Resend Code</button>
                </form>
                <img alt="logo" src={Logo} className="w-[173.25px] mx-auto mt-5" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyEmailPage;
