import React, {useEffect, useState} from "react";
import Navbar from "./Navbar";
import UserCircle from "../../assets/iconMenuAdmin/userCircle.svg"
import UserThreeCircle from "../../assets/iconMenuAdmin/userThreeCircle.svg"
import Horizontal from "../../assets/iconMenuAdmin/horizontal.svg"
import { getAllAdmin, getAllUsers } from "../../redux/actions/Admin";
import { detailUserAdmin } from "../../redux/actions/User";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Dashboard({}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('id_token');
    const [listAdmin, setListAdmin] = useState([]);
    const [listUsers, setListUsers] = useState([]);

    useEffect(()=> {
        dispatch(getAllAdmin(token, (result)=> {
            if (result.status === 200) {
                setListAdmin(result.data);
            }
        }))
        dispatch(getAllUsers("", "", token, (result)=> {
            if (result.status === 200) {
                setListUsers(result.data);
            }
        }))
        dispatch(detailUserAdmin(token, true, (result)=>{
            localStorage.setItem('userDetail', JSON.stringify(result))
            if (result === 'invalid token') {
                localStorage.removeItem('userDetail')
                localStorage.removeItem('id_token')
                localStorage.removeItem('input_button')
                navigate('/')
            }
        }))
    },[])
    return(
        <div className="w-full sm:w-full lg:w-10/12 px-4">
            <Navbar/>
            <div className="flex flex-row mt-4">
                <div className="flex flex-col sm:flex-row gap-5 w-full sm:w-3/4 min-w-[338px] mx-auto sm:mx-auto justify-center">
                    <div className="flex flex-col gap-6 h-auto divide-y divide-lightgrey-100 sm:h-auto bg-white border-lightgrey-100 border-2 rounded-xl p-8 min-w-[60%]">
                        <div className="flex flex-col item-start gap-4">
                            <div className="flex flex-row items-center gap-9">
                                <img src={UserCircle} alt="UserCircle"/>
                                <img src={Horizontal} alt="Horizontal"/>
                                <div className="flex flex-col items-start gap-1">
                                    <p className="font-gilroyMedium font-bold text-5xl tracking-wider text-green-1">{listAdmin.Total?listAdmin.Total:'-'}</p>
                                    <p className="font-gilroyMedium font-medium text-xl text-grey-3">Admin</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-6 h-auto divide-y divide-lightgrey-100 sm:h-auto bg-white border-lightgrey-100 border-2 rounded-xl p-8 min-w-[60%]">
                        <div className="flex flex-col item-start gap-4">
                            <div className="flex flex-row items-center gap-9">
                                <img src={UserThreeCircle} alt="UserThreeCircle"/>
                                <img src={Horizontal} alt="Horizontal"/>
                                <div className="flex flex-col items-start gap-1">
                                    <p className="font-gilroyMedium font-bold text-5xl tracking-wider text-green-1">{listUsers.Total?listUsers.Total:'-'}</p>
                                    <p className="font-gilroyMedium font-medium text-xl text-grey-3">Member</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}