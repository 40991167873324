import React, {useState, useEffect} from "react";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { detailUserAdmin } from "../../redux/actions/User";
import PopupWindow from "../../components/PopupWindow";
import { getAllAdmin, CreateAdmin, UpdateAdmin, DeleteAdmin } from "../../redux/actions/Admin";
import AddUser from "../../assets/AddUser.svg"
import ThreeDots from "../../assets/threeDots.svg"
import closeIcon from "../../assets/close-icon.svg"
import IconUsers from "../../assets/iconUsers.svg"
import Edit from "../../assets/Edit.svg"
import Delete from "../../assets/Delete.svg"
import IconRemove from "../../assets/icon-remove.svg"
import moment from 'moment'

import showPw from "../../assets/show-pw.svg"
import hidePw from "../../assets/hide-pw.svg"

export default function ListAdmin() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const loading = useSelector((state) => state.globalReducer.loading);
    const token = localStorage.getItem('id_token');
    let userDetail = localStorage.getItem('userDetail')
    userDetail = JSON.parse(userDetail)

    const [user, setUser] = useState({})
    // const [selectedImage, setSelectedImage] = useState('');
    const [listAdmin, setListAdmin] = useState([])
    const [showAdd, setShowAdd] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const [username, setUsername] = useState('')
    const [tempUsername, setTempUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [idUser, setIdUser] = useState('')

    const showingEdit = (input) => {
        setUsername(input.username)
        setTempUsername(input.username)
        setEmail(input.email)
        setIdUser(input._id)
        setShowEdit(true)
    }

    const showingDelete = (input) => {
        setIdUser(input._id)
        setEmail(input.email)
        setUsername(input.username)
        setTempUsername(input.username)
        setShowDelete(true)
    }

    const resetForm = () => {
        setUsername('')
        setEmail('')
        setPassword('')
        setIdUser('')
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (username !== '' && email !== '' && password !== '') {
            const paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;
            if (!password.match(paswd)) {
            dispatch({type:'ERROR_LOG', payload: 'validatePassword'})
            dispatch({type:'ERROR_MESSAGE', payload: 'Password Between 6 to 20 Characters Which Contains At Least One Lowercase Letter, One Uppercase Letter, One Numeric Digit, And One Special Character'})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
            } else {   
                const user = {
                    username:username,
                    email:email,
                    password:password,
                }
                dispatch(CreateAdmin(user, token, (result)=> {
                    if (result.status === 201) {
                        setShowAdd(false)
                        dispatch({type:'ERROR_MESSAGE', payload: `Success Create User ${username}`})
                        dispatch({type:'ERROR_LOG', payload: `success`})
                        setTimeout(() => {
                            dispatch({type:'ERROR_MESSAGE', payload: ''})
                            dispatch({type:'ERROR_LOG', payload: ''})
                        }, 2000);
                        resetForm()
                        dispatch(getAllAdmin(token, (result)=> {
                            if (result.status === 200) {
                                setListAdmin(result.data.results);
                            }
                        }))
                    }
                }))
            }  
        } else {
            dispatch({type:'ERROR_LOG', payload: 'validateFormRegisterTrial'})
            dispatch({type:'ERROR_MESSAGE', payload: 'Please Input All Form'})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
        }
    }

    const handleSubmitEdit = (e) => {
        e.preventDefault()
        if (username !== '' && email !== '' && password !== '') {
            const paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;
            if (!password.match(paswd)) {
            dispatch({type:'ERROR_LOG', payload: 'validatePassword'})
            dispatch({type:'ERROR_MESSAGE', payload: 'Password Between 6 to 20 Characters Which Contains At Least One Lowercase Letter, One Uppercase Letter, One Numeric Digit, And One Special Character'})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
            } else {   
                const user = {
                    username:username,
                    email:email,
                    password:password
                }
                dispatch(UpdateAdmin(user, idUser, token, (result)=> {
                    if (result.status === 201) {
                        setShowEdit(false)
                        dispatch({type:'ERROR_MESSAGE', payload: `Success Edit User ${username}`})
                        dispatch({type:'ERROR_LOG', payload: `success`})
                        setTimeout(() => {
                            dispatch({type:'ERROR_MESSAGE', payload: ''})
                            dispatch({type:'ERROR_LOG', payload: ''})
                        }, 2000);
                        resetForm()
                        dispatch(getAllAdmin(token, (result)=> {
                            if (result.status === 200) {
                                setListAdmin(result.data.results);
                            }
                        }))
                    }
                }))
            } 
        } else if (username !== '' && email !== '') {
            const user = {
                username:username,
                email:email,
            }
            dispatch(UpdateAdmin(user, idUser, token, (result)=> {
                if (result.status === 201) {
                    setShowEdit(false)
                    dispatch({type:'ERROR_MESSAGE', payload: `Success Edit User ${username}`})
                    dispatch({type:'ERROR_LOG', payload: `success`})
                    setTimeout(() => {
                        dispatch({type:'ERROR_MESSAGE', payload: ''})
                        dispatch({type:'ERROR_LOG', payload: ''})
                    }, 2000);
                    resetForm()
                    dispatch(getAllAdmin(token, (result)=> {
                        if (result.status === 200) {
                            setListAdmin(result.data.results);
                        }
                    }))
                }
            }))
        } else {
            dispatch({type:'ERROR_LOG', payload: 'validateFormRegisterTrial'})
            dispatch({type:'ERROR_MESSAGE', payload: 'Please Input All Form'})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
        }
    }

    const handleDelete = () => {
        dispatch(DeleteAdmin(idUser, token, (result)=> {
            if (result.status === 200) {
                setShowDelete(false)
                dispatch({type:'ERROR_MESSAGE', payload: `Success Block Admin ${username}`})
                dispatch({type:'ERROR_LOG', payload: `success`})
                setTimeout(() => {
                    dispatch({type:'ERROR_MESSAGE', payload: ''})
                    dispatch({type:'ERROR_LOG', payload: ''})
                }, 2000);
                resetForm()
                dispatch(getAllAdmin(token, (result)=> {
                    if (result.status === 200) {
                        setListAdmin(result.data.results);
                    }
                }))
            }
        }))
    }

    useEffect(()=> {
        dispatch(getAllAdmin(token, (result)=> {
            if (result.status === 200) {
                setListAdmin(result.data.results);
            }
        }))
        dispatch(detailUserAdmin(token, false, (result)=>{
            setUser(result)
            localStorage.setItem('userDetail', JSON.stringify(result))
            if (result === 'invalid token') {
                localStorage.removeItem('userDetail')
                localStorage.removeItem('id_token')
                localStorage.removeItem('input_button')
                navigate('/')
            }
        }))
    },[])

    return (
        <>
            <PopupWindow/>
            <div className="w-full sm:w-full lg:w-10/12 px-4">
                <Navbar widthFit={true}/>
                <div className="flex flex-row mt-4">
                    <div className="flex flex-col sm:flex-row gap-5 w-full min-w-[338px] mx-auto sm:mx-auto justify-center">
                        {loading? 
                            <div className="fixed top-0 flex left-0 w-full h-full justify-center items-center bg-[#00000047] z-50">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status"></div>
                            </div>:''
                        }
                        <div className="flex flex-col items-start px-6 py-5 gap-1 w-full">
                            <div className="h-auto min-w-[906px] bg-white flex gap-0 flex-col rounded-2xl overflow-hidden w-full">
                                <div className="flex flex-col items-start p-5 gap-[15px] rounded-[10px] overflow-auto max-h-[30rem] w-full">
                                    <div className="flex flex-row item-center w-full justify-between gap-[15px]">
                                        <p className="font-gilroyBold font-bold text-xl flex item-center tracking-wide text-green-1">List Admin</p>
                                        <button onClick={()=>setShowAdd(true)} className="flex flex-row items-center py-1 px-3 gap-1 border border-blue rounded-full">
                                            <img src={AddUser} alt="AddUser"/>
                                            <p className="font-gilroyBold text-sm flex items-center tracking-wide text-blue">Add Admin</p>
                                        </button>
                                    </div>
                                    <table className="w-full">
                                        <thead className="border-b border-lightgrey-250">
                                        <tr>
                                            <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2">Username</th>
                                            <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2">Email Address</th>
                                            <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2">Created</th>
                                            <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            listAdmin.length !== 0 ? 
                                            listAdmin.map((item, j)=>{
                                            return (
                                                <tr key={j}>
                                                    <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs flex gap-1 items-center w-36"><img src={IconUsers} alt="icon-user"/>{item.username}</td>
                                                    <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs">{item.email}</td>
                                                    <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs">{moment(item.createdAt).format('MMM Do YYYY')}</td>
                                                    <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs cursor-pointer">
                                                        <div className="dropdown relative">
                                                            <button className="dropdown-toggle px-5" type="button" data-bs-toggle="dropdown" aria-expanded="false" id={`dropdownMenuButton${j}`}>
                                                                <img src={ThreeDots} alt="ThreeDots"/>
                                                            </button>
                                                            <ul className={`dropdown-menu -ml-9 p-2 min-w-full w-[101px] absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-3 m-0 bg-clip-padding border-none hidden`} aria-labelledby={`dropdownMenuButton${j}`}>
                                                                <li>
                                                                    <button onClick={()=>showingEdit(item)} className="text-left dropdown-item items-center text-sm py-1 px-4 font-normal w-full whitespace-nowrap bg-transparent text-gey-3 hover:bg-grey-1100 hover:text-gey-3 hover:rounded-md flex gap-2"><img src={Edit} alt="edit"/> Edit</button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={()=>showingDelete(item)} className="text-left dropdown-item items-center text-sm py-1 px-4 font-normal w-full whitespace-nowrap bg-transparent text-red hover:bg-grey-1100 hover:text-red hover:rounded-md flex gap-2"><img src={Delete} alt="Delete"/> Delete</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                            })
                                            :''
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {
                showAdd ? 
                <div className="bg-[#0000003d] w-full h-full top-0 fixed flex justify-center items-center z-40">
                    <div className="absolute flex flex-col item-center p-16 gap-7 bg-white shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14);] rounded-2xl">
                        <button onClick={()=>{setShowAdd(false);resetForm()}} className="bg-green-1 p-3 absolute top-5 rounded-full right-5">
                            <img src={closeIcon} alt="closeIcon" width={15}/>
                        </button>
                        <p className="font-gilroyBold font-bold text-4xl text-center tracking-wide text-green-1">Add New Admin</p>
                        <form onSubmit={e=>{handleSubmit(e)}} className="w-full max-w-lg min-w-[30rem] border-t-lightgrey-250 border-t-2">
                            <div className="relative mb-5 mt-8">
                                <div className="w-full px-3">
                                    <input onChange={e=>{setUsername(e.target.value)}} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-username" type="text" placeholder="Username" />
                                </div>
                            </div>
                            <div className="relative mb-5">
                                <div className="w-full px-3">
                                    <input onChange={e=>{setEmail(e.target.value)}} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" type="email" placeholder="Email Address" />
                                </div>
                            </div>
                            <div className='relative mb-5'>
                                <div className="w-full px-3">
                                    <input onChange={e=>{setPassword(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="Password" />
                                    <div className="absolute inset-y-0 right-3 pr-3 flex items-center text-sm">
                                        {
                                            showPassword?
                                            <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={showPw} alt="showPw" />
                                            :
                                            <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={hidePw} alt="hidePw" />
                                        }
                                    </div>
                                </div>
                            </div>  
                            <div className="flex flex-row items-start gap-[15px] w-full">
                                <button
                                    className={`flex justify-center justify-items-center items-center rounded-[10px] h-[49px] bg-green-1 hover:bg-green-2 text-yellow-300 font-semibold w-3/6`} type="submit"
                                    >
                                    <span className="ml-2 tracking-wide font-semibold text-base">Save</span>
                                </button>
                                <button
                                    onClick={()=>{setShowAdd(false);resetForm()}}
                                    className="flex justify-center justify-items-center items-center rounded-[5px] px-8 h-[49px] font-semibold border-[2px] border-green-1 hover:bg-lightgrey-50 w-3/6" type="button"
                                >
                                    <span className="ml-2 tracking-wide font-semibold text-base text-green-1">Cancel</span>
                                </button>
                            </div>
                        </form> 
                    </div>
                </div> : ''
            }
            {
                showEdit ? 
                <div className="bg-[#0000003d] w-full h-full top-0 fixed flex justify-center items-center z-40">
                    <div className="absolute flex flex-col item-center p-16 gap-7 bg-white shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14);] rounded-2xl">
                        <button onClick={()=>{setShowEdit(false);resetForm()}} className="bg-green-1 p-3 absolute top-5 rounded-full right-5">
                            <img src={closeIcon} alt="closeIcon" width={15}/>
                        </button>
                        <p className="font-gilroyBold font-bold text-4xl text-center tracking-wide text-green-1">Edit Admin <span className="text-blue">{tempUsername}</span></p>
                        <form onSubmit={e=>{handleSubmitEdit(e)}} className="w-full max-w-lg min-w-[30rem] border-t-lightgrey-250 border-t-2">
                            <div className="relative mb-5 mt-8">
                                <div className="w-full px-3">
                                    <input onChange={e=>{setUsername(e.target.value)}} value={username} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Username" />
                                </div>
                            </div>
                            <div className="relative mb-5">
                                <div className="w-full px-3">
                                    <input onChange={e=>{setEmail(e.target.value)}} value={email} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" type="email" placeholder="Email Address" />
                                </div>
                            </div>
                            <div className='relative mb-5'>
                                <div className="w-full px-3">
                                    <input onChange={e=>{setPassword(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="Password" />
                                    <div className="absolute inset-y-0 right-3 pr-3 flex items-center text-sm">
                                        {
                                            showPassword?
                                            <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={showPw} alt="showPw" />
                                            :
                                            <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={hidePw} alt="hidePw" />
                                        }
                                    </div>
                                </div>
                            </div>  
                            <div className="flex flex-row items-start gap-[15px] w-full">
                                <button
                                    className={`flex justify-center justify-items-center items-center rounded-[10px] h-[49px] bg-green-1 hover:bg-green-2 text-yellow-300 font-semibold w-3/6`} type="submit"
                                    >
                                    <span className="ml-2 tracking-wide font-semibold text-base">Save</span>
                                </button>
                                <button
                                    onClick={()=>{setShowEdit(false);resetForm()}}
                                    className="flex justify-center justify-items-center items-center rounded-[5px] px-8 h-[49px] font-semibold border-[2px] border-green-1 hover:bg-lightgrey-50 w-3/6" type="button"
                                >
                                    <span className="ml-2 tracking-wide font-semibold text-base text-green-1">Cancel</span>
                                </button>
                            </div>
                        </form> 
                    </div>
                </div> : ''
            }
            {
                showDelete ? 
                <div className="bg-[#0000003d] w-full h-full top-0 fixed flex justify-center items-center z-40">
                    <div className="absolute flex flex-col items-center p-16 gap-7 bg-white shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14);] rounded-2xl">
                        <button onClick={()=>{setShowDelete(false);resetForm()}} className="bg-green-1 p-3 absolute top-5 rounded-full right-5">
                            <img src={closeIcon} alt="closeIcon" width={15}/>
                        </button>
                        <img src={IconRemove} alt="IconRemove" width={100} />
                        <p className="font-gilroyBold font-bold text-4xl text-center tracking-wide text-red">Delete Member</p>
                        <p className="text-center font-medium text-base tracking-wide text-grey-2">Are you sure you want to delete member<br/><span className="text-blue">{email}</span> ?</p>
                        <div className="flex flex-row items-start gap-[15px] w-full">
                            <button
                            onClick={()=>{setShowDelete(false);resetForm()}}
                                className="flex justify-center justify-items-center items-center rounded-[5px] px-8 h-[49px] font-semibold border-[2px] border-green-1 hover:bg-lightgrey-50 w-3/6" type="button"
                            >
                                <span className="ml-2 tracking-wide font-semibold text-base text-green-1">No</span>
                            </button>
                            <button
                                onClick={(e)=>handleDelete(e)}
                                className={`flex justify-center justify-items-center items-center rounded-[10px] h-[49px] bg-green-1 hover:bg-green-2 text-yellow-300 font-semibold w-3/6`} type="button"
                                >
                                <span className="ml-2 tracking-wide font-semibold text-base">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
                : ''
            }
        </>
    );
}
