import React, {useEffect, useState} from "react";
import Logo from "../assets/logo.png";
import Touch from "../assets/Touch.svg";
import Mouse from "../assets/Mouse.svg";
import Trackpad from "../assets/Trackpad.svg";
import PhotoDefault from "../assets/photo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { detailUser } from "../redux/actions/User";

function HomePage() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  let token = localStorage.getItem('id_token');
  let userDetail = localStorage.getItem('userDetail')
  userDetail = JSON.parse(userDetail)
  const [showDropdown, setShowDropdown] = useState(false)
  const [selectedImage, setSelectedImage] = useState('');

  const handleTouchInput = (input) => {
    dispatch({type:"INPUT_BUTTON", payload:input})
    localStorage.removeItem('input_button')
    localStorage.setItem('input_button', input)
  }

  const goLogout = () => {
    localStorage.clear()
    navigate("/")
  }

  useEffect(() => {
    const docBody = document.querySelector("body");
    docBody.classList.remove("green-bg");
    docBody.classList.add("yellow-bg");
    if (!userDetail) {
      dispatch(detailUser(token, (result)=>{
        localStorage.setItem('userDetail', JSON.stringify(result))
        if (result.profileImage) setSelectedImage(result.profileImage)
        if (result === 'invalid token') {
          localStorage.removeItem('userDetail')
          localStorage.removeItem('id_token')
          localStorage.removeItem('input_button')
          navigate('/')
        }
      }))
    }
  }, [])

  return (
    <>
      <div className="grid justify-items-center items-center h-screen">
        <div className="bg-white rounded-[15px] shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14)] dark:bg-gray-800 dark:border-gray-700 p-4 min-w-[515px] min-h-[436px]">
          <div className="flex flex-col items-center pb-10">
            <h1 className="mt-[46.51px] text-[40px] text-green-1 font-bold  tracking-[0.05rem]">
              Blending Board
            </h1>
            <p className="font-medium text-lg mt-[26.16px]">
              Select input here
            </p>

            <Link to="/game">
              <button
                onClick={() => handleTouchInput('touch')}
                className="flex shadow-[0px_4px_0px_#e9bf45,inset_0px_3px_0px_rgb(255_255_255_/_15%)] justify-center justify-items-center items-center rounded-[10px] w-[273px] h-[62px] mt-[17.32px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold border-[3px] border-yellow hover:border-yellow"
              >
                <img alt="touch" className="cursor-pointer" src={Touch} />
                <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">TOUCH INPUT</span>
              </button>
              <button 
              onClick={() => handleTouchInput('mouse')}
              className="flex shadow-[0px_4px_0px_#e9bf45,inset_0px_3px_0px_rgb(255_255_255_/_15%)] justify-center justify-items-center items-center rounded-[10px] w-[273px] h-[62px] mt-[28.76px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold border-[3px] border-yellow hover:border-yellow">
                <img alt="mouse" className="cursor-pointer" src={Mouse} />
                <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">MOUSE INPUT</span>
              </button>
              <button 
              onClick={() => handleTouchInput('trackpad')}
              className="flex shadow-[0px_4px_0px_#e9bf45,inset_0px_3px_0px_rgb(255_255_255_/_15%)] justify-center justify-items-center items-center rounded-[10px] w-[273px] h-[62px] mt-[28.76px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold border-[3px] border-yellow hover:border-yellow">
                <img alt="trackpad" className="cursor-pointer" src={Trackpad} />
                <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">TRACKPAD INPUT</span>
              </button>
            </Link>
            <img alt="logo" src={Logo} className="mt-[45px] w-[173.25px]" />
          </div>
        </div>
      </div>
      <div className="fixed top-7 right-7 flex flex-row items-start">
          <div className="flex justify-center">
              <div className="dropdown relative">
                  <button
                      className="dropdown-toggle pl-6 text-white rounded transition duration-150 ease-in-out flex items-center font-poppins text-base font-semibold "
                      type="button"
                      onClick={()=>setShowDropdown(!showDropdown)}
                  >
                      {
                        selectedImage ?
                        <>
                            <img
                            src={selectedImage}
                            className="w-20 h-20 object-contain border-4 bg-grey-1000 border-white drop-shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15)] rounded-2xl"
                            alt="Thumb"
                            />
                        </>
                        :
                        <img alt="logo" src={PhotoDefault} className='w-20 h-20 border-4 border-white drop-shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15)] rounded-2xl'/>
                      }
                  </button>
                  <ul className={`dropdown-menu -ml-9 p-2 min-w-full w-[141px] absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-3 m-0 bg-clip-padding border-none ${showDropdown ? '' : 'hidden'}`} >
                      <li>
                          <Link to="/game/profile" className="text-start dropdown-item text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-black hover:bg-grey-1100 hover:text-black hover:rounded-md">Profile</Link>
                      </li>
                      {
                        userDetail ? 
                          userDetail.planActivation?
                            userDetail.planActivation.isActive ? 
                              ''
                              : <li>
                              <Link to="/game/select-plan" className="text-start dropdown-item text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-black hover:bg-grey-1100 hover:text-black hover:rounded-md">Select Plan</Link>
                            </li>
                            :
                          userDetail.user?
                          userDetail.user.planActivation.isActive ?
                          ''
                        : 
                        <li>
                          <Link to="/game/select-plan" className="text-start dropdown-item text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-black hover:bg-grey-1100 hover:text-black hover:rounded-md">Select Plan</Link>
                        </li>
                        : 
                        <li>
                          <Link to="/game/select-plan" className="text-start dropdown-item text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-black hover:bg-grey-1100 hover:text-black hover:rounded-md">Select Plan</Link>
                        </li>
                        :
                        <li>
                          <Link to="/game/select-plan" className="text-start dropdown-item text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-black hover:bg-grey-1100 hover:text-black hover:rounded-md">Select Plan</Link>
                        </li>
                      }
                      <li>
                          <button onClick={()=>goLogout()} className="text-left dropdown-item text-sm py-1 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-red hover:bg-grey-1100 hover:text-red hover:rounded-md">Log Out</button>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
    </>
  );
}

export default HomePage;
