import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Vector37 from "../../../assets/Vector37.svg";
import GluedSoundDragable from "./GluedSoundDragable"

const alphabet1 = ["ing", "ang", "ong", "ung"];

const alphabet2 = ["ink", "ank", "onk", "unk"];

export default function GluedSound(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  const gameEvent = useSelector((state) => state.globalReducer.gameEvent);
  const featureDragable = useSelector((state) => state.globalReducer.featureDragable);

  const [tempAlphabet1, setTempAlphabet1] = useState(alphabet1)

  const addNewItem = (e, word, type, tempId=false) => {
    if (user.planActivation) {
      if (!user.planActivation.isActive) {
        return
      }
    }
    if (!tempId) {
      dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
      const isIdTemp = (element) => element.id === "tempId";
      const isIdTes = (element) => element.id === "tesId";
      if (gameEvent.findIndex(isIdTemp) !== -1) gameEvent.splice(gameEvent.findIndex(isIdTemp), 1);
      if (gameEvent.findIndex(isIdTes) !== -1) gameEvent.splice(gameEvent.findIndex(isIdTes), 1);
      let styleBorderNText = "border-black text-black";
      let IdIncrement = 0;
      if (gameEvent.length !== 0)
        IdIncrement = gameEvent[gameEvent.length - 1].id;
        if (e.changedTouches) {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-350, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        } else {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-350, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        }
        dispatch({ type: "REDO_EVENT", payload: []})
    } else {
      const checkTile = gameEvent.find(({ id }) => id === "tempId");
      const checkTileTes = gameEvent.find(({ id }) => id === "tesId");
      if (checkTile || checkTileTes) {
        const tempObj = checkTile
        const isIdTemp = (element) => element.id === "tempId";
        gameEvent.splice(gameEvent.findIndex(isIdTemp), 1);
        if (gameEvent.findIndex(isIdTemp) === -1) {
          if (e.changedTouches) {
            tempObj['x'] = e.changedTouches['0'].pageX-100
            tempObj['y'] = e.changedTouches['0'].pageY-350
            tempObj['id'] = 'tesId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          } else {
            tempObj['x'] = e.pageX-100
            tempObj['y'] = e.pageY-350
            tempObj['id'] = 'tesId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          }
        } else {
          const isIdTes = (element) => element.id === "tesId";
          gameEvent.splice(gameEvent.findIndex(isIdTes), 1);
          if (e.changedTouches) {
            tempObj['x'] = e.changedTouches['0'].pageX-100
            tempObj['y'] = e.changedTouches['0'].pageY-350
            tempObj['id'] = 'tempId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          } else {
            tempObj['x'] = e.pageX-100
            tempObj['y'] = e.pageY-350
            tempObj['id'] = 'tempId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          }
        }
      } else {
        dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
        let styleBorderNText = "border-black text-black";
        let IdIncrement = 0;
        if (gameEvent.length !== 0)
          IdIncrement = gameEvent[gameEvent.length - 1].id;
          if (e.changedTouches) {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-350, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          } else {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-350, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          }
          dispatch({ type: "REDO_EVENT", payload: []})
      }
    }
  }
  
  const handleSetAlfabet = () => {
    setTempAlphabet1(alphabet1)
  }

  return (
    <div className={`animate__animated animate__fadeInLeft z-50`}>
      <div className=" ml-2 lex flex-col gap-1 fixed t-[45%] pt-1 z-50">
        <div className="flex flex-row gap-[5px]">
          <div className="flex  gap-[5px] bg-[#FFFFFF80] p-[5px] rounded-[10px] w-fit">
            {alphabet1.map((item, index) => (
              <GluedSoundDragable word={item} key={index} addNewItem={addNewItem} handleSetAlfabet={handleSetAlfabet} typeWord={'gluedSound'}/>
            ))}
          </div>

          <div className="flex  gap-[5px] bg-[#FFFFFF80] p-[5px] rounded-[10px] w-fit">
            {alphabet2.map((item, index) => (
              <GluedSoundDragable word={item} key={index} addNewItem={addNewItem} handleSetAlfabet={handleSetAlfabet} typeWord={'gluedSound'}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
