import axios from "axios";

export const getAllNotification = (token) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_URL_BE}/notif/user`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
        };
        axios(config)
        .then(({data}) => {
            console.log(data)
            const count = getCountNotif(data)
            localStorage.setItem('countNotif', count)
            dispatch({type:'ALL_NOTIFICATION', payload: data})
            dispatch({type:'LOADING', payload: false})
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'getAllNotification'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
        });
    }
}

export const readNotification = (id, token) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const config = {
            method: 'put',
            url: `${process.env.REACT_APP_URL_BE}/notif/read/${id}`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
        };
        axios(config)
        .then(({data}) => {
            const count = getCountNotif(data)
            localStorage.setItem('countNotif', count)
            dispatch({type:'ALL_NOTIFICATION', payload: data})
            dispatch({type:'LOADING', payload: false})
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'readNotification'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
        });
    }
}

export const readAllNotification = (token) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const config = {
            method: 'put',
            url: `${process.env.REACT_APP_URL_BE}/notif/read/all`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
        };
        axios(config)
        .then(({data}) => {
            const count = getCountNotif(data)
            localStorage.setItem('countNotif', count)
            dispatch({type:'ALL_NOTIFICATION', payload: data})
            dispatch({type:'LOADING', payload: false})
        }).catch(({response}) => {
            if (response.status !== 500) {
                dispatch({type:'ERROR_LOG', payload: 'readNotification'})
                dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
                setTimeout(() => {
                    dispatch({type:'ERROR_MESSAGE', payload: ''})
                    dispatch({type:'ERROR_LOG', payload: ''})
                }, 2000);
            }
            dispatch({type:'LOADING', payload: false})
        });
    }
}

function getCountNotif(data) {
    let count = 0
    data.forEach(el => {
        if (!el.read) {
            count+=1
        }
    });
    return count
}