import React from 'react'
import { Link } from 'react-router-dom'
import Error from '../assets/error-404.svg'

function ErrorPage () {
    return (
        <>
            <div className="grid justify-items-center items-center h-screen">
                <div className="bg-white rounded-[15px] shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14)] dark:bg-gray-800 dark:border-gray-700 p-4 max-w-[562px] max-h-[592px] gap-10 flex flex-col px-[60px] py-[50px]">
                    <img className='m-auto' src={Error} alt="error-img" />
                    <p className='font-gilroyMedium font-medium text-lg tracking-wide text-grey-2 text-center'>The page you were looking for couldn’t be found</p>
                    <Link to='/'
                      className="flex justify-center justify-items-center items-center rounded-[5px] px-8 w-fit m-auto py-3 font-semibold border-[2px] border-green-1 hover:bg-lightgrey-50" type="button"
                    >
                      <span className="ml-2 tracking-wide font-semibold text-sm text-green-1">Back to Homepage</span>
                    </Link>
                </div>
            </div>  
        </>
    )
}

export default ErrorPage