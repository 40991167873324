import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar";
import {useNavigate, useParams} from "react-router-dom";

import CreditCard from "../assets/payment/creditCard.svg"
import Paypal from "../assets/payment/paypal.svg"
import ApplePay from "../assets/payment/applePay.svg"
import successPayment from "../assets/payment/success-payment.svg"

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import PopupWindow from "../components/PopupWindow";
import CheckoutForm from "../components/CheckoutForm";
import CheckoutFormApplePay from "../components/CheckoutFormApplePay"
import { getPlanPaypal, updateAndGetSecret } from "../redux/actions/Plan";
import failPayment from '../assets/payment/fail-payment.svg'

const stripePromise = loadStripe(process.env.REACT_APP_CLIENT_SECRET);

export default function Plan() {
  const token = localStorage.getItem('id_token');
  let choosePlan = localStorage.getItem('choose_plan')
  let titlePlan = localStorage.getItem('title_plan')
  choosePlan = JSON.parse(choosePlan)
  const dispatch = useDispatch()
  const param = useParams()
  const navigate = useNavigate()
  const [termConditionActive, setTermConditionActive] = useState(false)
  const [whoActive, setWhoActive] = useState('creditCard')
  const [message, setMessage] = useState(null);

  const clientSecret = useSelector((state) => state.userReducer.clientSecret);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const setPaymentActive = (text) => {
    if (param.status === 'success') return
    localStorage.setItem('PaymentActive', text)
    setWhoActive(text)
  }

  const AddPlanPaypal = () => {
    dispatch(getPlanPaypal(token, choosePlan.kode, (result)=>{
      // console.log(result);
      window.location.replace(result.url)
    }))
  }

  const completedOrder = () => {
    if (param.status === 'success') navigate('/game/completed-payment')
  }

  useEffect(() => {
    const PaymentActive = localStorage.getItem('PaymentActive')
    if (PaymentActive) setWhoActive(PaymentActive)
    if (PaymentActive === 'paypal') setMessage('Payment Paypal Faied'); setTimeout(() => {setMessage(null)}, 2000);
    const docBody = document.querySelector("body");
    docBody.classList.remove("green-bg");
    docBody.classList.add("yellow-bg");
    if (param.status !== 'success') {
      dispatch(updateAndGetSecret({planCode:choosePlan.kode}, token, (result)=>{
        if (result.status === 201) {
            localStorage.setItem('title_plan', result.data.title);
            localStorage.setItem('choose_plan', JSON.stringify(result.data.plan));
            dispatch({type:'CLIENT_SECRET', payload: result.data.clientSecret})
        }
      }))
    }
  }, [])
  return (
    <div>
      <Navbar signUp={false} signIn={false}/>
      <div className="grid justify-items-center items-center min-h-screen">
        <PopupWindow/>
        <div className="p-14 w-4/5 max-w-[1095px]">
          <div className="min-h-[743px] max-w-[1095px] h-auto bg-white flex gap-6 flex-col items-start md:p-10 px-6 py-6 rounded-2xl">
          <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0" id="tabs-tab"
            role="tablist">
            <li className="nav-item" role="presentation">
              <div className="nav-link cursor-not-allowed block uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent focus:border-transparent text-lg font-gilroyBold">SIGN UP</div>
            </li>
            <li className="nav-item" role="presentation">
              <a href="#tabs-payment" className="nav-link block uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent focus:border-transparent text-lg font-gilroyBold active" id="tabs-payment-tab" data-bs-toggle="pill" data-bs-target="#tabs-payment" role="tab"
                aria-controls="tabs-payment" aria-selected="true">PAYMENT METHOD</a>
            </li>
          </ul>
          <div className="tab-content w-full" id="tabs-tabContent">
            <div className="tab-pane fade" id="tabs-signup" role="tabpanel" aria-labelledby="tabs-signup-tab">
            </div>
            <div className="tab-pane fade show active" id="tabs-payment" role="tabpanel" aria-labelledby="tabs-payment-tab">
              <div className="flex flex-row items-start">
                <div className="w-[55%]">
                  <h1 className="text-[40px] text-green-1 font-bold mb-6 tracking-[0.05rem]">Payment Method</h1>
                  {/* credit card */}
                  <div className="flex flex-row items-start pb-5">
                    <div className="flex flex-col w-full items-start gap-5">
                      <div className="flex flex-col w-full justify-center items-start p-5 gap-5 border border-lightgrey-100 rounded-[10px]">
                        <div className="flex flex-row item-center gap-5 w-full justify-between">
                          <div className="flex flex-row items-start gap-2.5">
                            <div className="flex flex-row item-start gap-2.5">
                              <input onClick={()=>setPaymentActive('creditCard')} checked={whoActive === 'creditCard' ? true : false} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                            </div>
                            <div className="flex flex-col items-start gap-0.5">
                              <p className="font-gilroyBold text-base text-grey-2">Credit or Debit Card</p>
                              <p className="font-gilroyMedium text-xs text-grey-3 font-medium tracking-wide max-w-[252px]">Safe money using your bank account Visa, Maestro Discover</p>
                            </div>
                          </div>
                          <div className="flex flex-row items-start gap-2 my-auto">
                            <img src={CreditCard} alt="creditCard" />
                          </div>
                        </div>
                        {
                          param.status !== 'success' ?
                            whoActive === 'creditCard' ? 
                            clientSecret && (
                              <Elements style={{width:"100% !important"}} options={options} stripe={stripePromise}>
                                <CheckoutForm/>
                              </Elements>
                            )
                            : ''
                          : ''
                        }
                        {
                          param.status === 'success' && whoActive === 'creditCard' ? 
                          <div className="flex flex-col w-full items-start p-5 gap-2.5 bg-grey-1100 border-lightgrey-100 border rounded-[5px] mt-5">
                            <div className="flex flex-row items-center justify-center gap-[5px] w-full">
                              <img src={successPayment} alt='successPayment'/>
                              <p className="font-gilroyBold font-bold text-base text-center text-green-1">Payment Successful!</p>
                            </div>
                            <p className="text-grey-3 font-gilroyMedium text-xs tracking-wide flex justify-center w-full">The payment of ${Number(choosePlan.price)*Number(choosePlan.month.split(' ')[0])+parseFloat((15/100)*Number(choosePlan.price)*Number(choosePlan.month.split(' ')[0]).toFixed(2))} USD has successfully been sent from your wallet</p>
                          </div>
                          : ''
                        }
                      </div>
                    </div>
                  </div>
                  {/* paypal */}
                  {/* <div className="flex flex-row items-start pb-5">
                    <div className="flex flex-col w-full items-start gap-5">
                      <div className="flex flex-col w-full justify-center items-start p-5 gap-5 border border-lightgrey-100 rounded-[10px]">
                        <div className="flex flex-row item-center gap-5 w-full justify-between">
                          <div className="flex flex-row items-start gap-2.5">
                            <div className="flex flex-row item-start gap-2.5">
                              <input onClick={()=>setPaymentActive('paypal')} checked={whoActive === 'paypal' ? true : false} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                            </div>
                            <div className="flex flex-col items-start gap-0.5">
                              <p className="font-gilroyBold text-base text-grey-2">PayPal</p>
                              <p className="font-gilroyMedium text-xs text-grey-3 font-medium tracking-wide max-w-[252px]">You will be redirected to PayPal website to complete your purchase securely</p>
                            </div>
                          </div>
                          <div className="flex flex-row items-start gap-2 my-auto">
                            <img src={Paypal} alt="Paypal" />
                          </div>
                        </div>
                        {
                          param.status === 'process' ?
                            whoActive === 'paypal' ? 
                              <button onClick={()=>AddPlanPaypal()} className={`flex justify-center justify-items-center items-center rounded-[5px] w-full h-[45px] bg-green-1 hover:bg-green-2 text-yellow-1000 font-bold`} >
                                <span className="ml-2 tracking-[0.08rem] font-bold text-lg">Pay Now</span>
                              </button>
                              : ''
                          : 
                          param.status === 'error' ? 
                          whoActive === 'paypal' ?
                          <>
                            {
                              message && <div className="flex w-full flex-col items-start p-5 gap-2.5 bg-grey-1100 border-lightgrey-100 border rounded-[5px] mt-5">
                                <div className="flex flex-row items-center justify-center gap-[5px] w-full">
                                  <img src={failPayment} alt='failPayment'/>
                                  <p className="font-gilroyBold font-bold text-base text-center text-red">{message}</p>
                                </div>
                                <p className="text-grey-3 font-gilroyMedium text-xs tracking-wide flex justify-center w-full">We aren’t able to process your payment. Please try again</p>
                              </div>
                              }
                            <button onClick={()=>AddPlanPaypal()} className={`flex justify-center justify-items-center items-center rounded-[5px] w-full h-[45px] bg-green-1 hover:bg-green-2 text-yellow-1000 font-bold`} >
                              <span className="ml-2 tracking-[0.08rem] font-bold text-lg">Try Again</span>
                            </button>
                          </>
                          : '' : ''
                        }
                        {
                          param.status === 'success' && whoActive === 'paypal' ? 
                          <div className="flex flex-col w-full items-start p-5 gap-2.5 bg-grey-1100 border-lightgrey-100 border rounded-[5px] mt-5">
                            <div className="flex flex-row items-center justify-center gap-[5px] w-full">
                              <img src={successPayment} alt='successPayment'/>
                              <p className="font-gilroyBold font-bold text-base text-center text-green-1">Payment Successful!</p>
                            </div>
                            <p className="text-grey-3 font-gilroyMedium text-xs tracking-wide flex justify-center w-full">The payment of ${Number(choosePlan.price)*Number(choosePlan.month.split(' ')[0])+parseFloat((15/100)*Number(choosePlan.price)*Number(choosePlan.month.split(' ')[0]).toFixed(2))} has successfully been sent from your wallet</p>
                          </div>
                          : ''
                        }
                      </div>
                    </div>
                  </div> */}
                  {/* apple pay */}
                  <div className="flex flex-row items-start pb-5">
                    <div className="flex flex-col w-full items-start gap-5">
                      <div className="flex flex-col w-full justify-center items-start p-5 gap-5 border border-lightgrey-100 rounded-[10px]">
                        <div className="flex flex-row item-center gap-5 w-full justify-between">
                          <div className="flex flex-row items-start gap-2.5">
                            <div className="flex flex-row item-start gap-2.5">
                              <input onClick={()=>setPaymentActive('applePay')} checked={whoActive === 'applePay' ? true : false} className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                            </div>
                            <div className="flex flex-col items-start gap-0.5">
                              <p className="font-gilroyBold text-base text-grey-2">Apple Pay</p>
                              <p className="font-gilroyMedium text-xs text-grey-3 font-medium tracking-wide max-w-[252px]">You will be directed to Apple Pay to complete the payment.</p>
                            </div>
                          </div>
                          <div className="flex flex-row items-start gap-2 my-auto">
                            <img src={ApplePay} alt="ApplePay" />
                          </div>
                        </div>
                        {
                          param.status === 'process' || param.status === 'error' ?
                            whoActive === 'applePay' ? 
                            clientSecret && (
                              <Elements options={options} stripe={stripePromise}>
                                <CheckoutFormApplePay clientSecret={clientSecret} titlePlan={titlePlan} totalPrice={Number(choosePlan.price)*Number(choosePlan.month.split(' ')[0])+parseFloat((15/100)*Number(choosePlan.price)*Number(choosePlan.month.split(' ')[0]).toFixed(2))}/>
                              </Elements>
                            )
                            : ''
                          : ''
                        }
                        {
                          param.status === 'success' && whoActive === 'applePay' ? 
                          <div className="flex flex-col w-full items-start p-5 gap-2.5 bg-grey-1100 border-lightgrey-100 border rounded-[5px] mt-5">
                            <div className="flex flex-row items-center justify-center gap-[5px] w-full">
                              <img src={successPayment} alt='successPayment'/>
                              <p className="font-gilroyBold font-bold text-base text-center text-green-1">Payment Successful!</p>
                            </div>
                            <p className="text-grey-3 font-gilroyMedium text-xs tracking-wide flex justify-center w-full">The payment of ${Number(choosePlan.price)*Number(choosePlan.month.split(' ')[0])+parseFloat((15/100)*Number(choosePlan.price)*Number(choosePlan.month.split(' ')[0]).toFixed(2))} USD has successfully been sent from your wallet</p>
                          </div>
                          : ''
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pl-10 w-[45%]">
                  <div className="box-border flex flex-col items-start p-5 gap-[15px] bg-grey-1100 border border-lightgrey-100 rounded-[10px]">
                    <p className="text-green-1 text-2xl font-gilroyMedium font-semibold tracking-wide">Order Summary</p>
                    <div className="flex flex-col item-start gap-2.5 w-full">
                      <div className="flex flex-row item-center p-2.5 gap-[50px] justify-between">
                        <div className="flex flex-col items-start">
                          <p className="font-gilroyMedium font-semibold text-lg text-green-1 tracking-wide">{titlePlan}</p>
                          <p className="font-gilroyMedium font-normal text-sm text-green-1 tracking-wide">{choosePlan.month}</p>
                        </div>
                        <p className="font-gilroyMedium font-semibold text-lg text-green-1 tracking-wide mt-auto mb-auto">${Number(choosePlan.price)*Number(choosePlan.month.split(' ')[0])} USD</p>
                      </div>
                      <div className=" border-t border-lightgrey-100"></div>
                      <div className="flex flex-row item-center p-2.5 gap-[50px] justify-between">
                        <div className="flex flex-col items-start">
                          <p className="font-gilroyMedium font-semibold text-base text-green-1 tracking-wide mt-auto mb-auto">Vat(15%)</p>
                        </div>
                        <p className="font-gilroyMedium font-semibold text-lg text-green-1 tracking-wide mt-auto mb-auto">${parseFloat(Number((15/100)*(Number(choosePlan.price)*Number(choosePlan.month.split(' ')[0]))).toFixed(2))} USD</p>
                      </div>
                      <div className=" border-t border-lightgrey-100"></div>
                      <div className="flex flex-row item-center p-2.5 gap-[50px] justify-between">
                        <div className="flex flex-col items-start">
                          <p className="font-gilroyMedium font-semibold text-lg text-green-1 tracking-wide">Total</p>
                          <p className="font-gilroyMedium font-normal text-sm text-green-1 tracking-wide">(1incl. VAT)</p>
                        </div>
                        <p className="font-gilroyMedium font-semibold text-lg text-green-1 tracking-wide mt-auto mb-auto">${Number(choosePlan.price)*Number(choosePlan.month.split(' ')[0])+parseFloat((15/100)*Number(choosePlan.price)*Number(choosePlan.month.split(' ')[0]).toFixed(2))} USD</p>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-8 mb-4">
                    <div className="w-full px-3">
                      <div className="form-check flex">
                        <input checked={param.status === 'success' ? true : termConditionActive} onChange={()=>setTermConditionActive(!termConditionActive)} className="form-check-input h-4 w-4 border accent-green-1 border-lightgrey-150 rounded-sm bg-grey-1100 checked:bg-green-1 checked:border-green-1 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" id="flexCheckDefault"/>
                        <label className="form-check-label inline-block text-grey-3" htmlFor="flexCheckDefault">
                          <p className='text-grey-3 text-sm'>By clicking the buttons, you agree to our company <span className="text-blue">Privacy Policy</span> and <span className="text-blue">Conditions of Use</span></p>
                        </label>
                      </div>        
                    </div>
                  </div>
                  <button onClick={()=>completedOrder()} className={`flex justify-center justify-items-center items-center rounded-[5px] w-full h-[60px] ${param.status!=='success'?'bg-lightgrey-250 cursor-not-allowed text-grey-3':'bg-green-1 hover:bg-green-2 text-yellow-1000'} font-semibold`} >
                      <span className="ml-2 tracking-[0.08rem] font-semibold text-lg">Complete Order</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}