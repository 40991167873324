import React from 'react'

function Button (props) {
    return (
        <>
            <button
                onClick={() => props.clik()}
                className={`flex shadow-[0px_4px_0px_#e9bf45,inset_0px_3px_0px_rgb(255_255_255_/_15%)] justify-center justify-items-center items-center rounded-[10px] px-5 ${props.width} h-[62px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold border-[3px] border-yellow hover:border-yellow`}
              >
                {props.image === false ?
                '':<img alt="touch" className="cursor-pointer" src={props.image} />}
                <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">{props.text}</span>
            </button>
        </>
    ) 
}

export default Button