const initialState = {
    user: [],
    clientSecret: '',
    userSecretPayment: {}
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case "USER":
        return { ...state, user: action.payload };
      case "CLIENT_SECRET":
        return { ...state, clientSecret: action.payload };
      case "USER_SECRET_PAYMENT":
        return { ...state, userSecretPayment: action.payload };
      default:
        return state;
    }
  };
  
  export default userReducer;
  