import React, {useState} from "react";
import DraggableBox from "../../common/Draggable";
import { useDispatch, useSelector } from "react-redux";

const prefix = [
  "re",
  "de",
  "pre",
  "pro",
  "un",
  "in",
  "mis",
  "dis",
  "con",
  "sub",
  "non",
  "mid",
  "trans",
  "inter",
  "anti",
];

const roots = [
  "ject",
  "tract",
  "rupt",
  "struct",
  "sist",
  "gress",
  "sect",
  "port",
  "form",
  "tort",
  "cur",
  "fer",
  "mote",
  "spect",
  "vis",
  "vid"
];

const suffixes = [
  "s",
  "es",
  "ing",
  "ed",
  "er",
  "est",
  "ful",
  "less",
  "able",
  "ible",
  "ish",
  "ive",
  "ly",
  "en",
  "ness",
  "ment",
  "al",
];

const suffixes2 = [
  "(t)ion",
  "(s)ion",
  "(c)Ian",
  " (t)ious",
  "(c)ious",
  "(x)ious",
  "(t)ient",
  "(c)ient",
  "(t)iate",
  "(c)iate",
  "ture",
];

export default function PrefixPanel() {
  const dispatch = useDispatch();
  const gameEvent = useSelector((state) => state.globalReducer.gameEvent);
  const user = useSelector((state) => state.userReducer.user);
  const featureDragable = useSelector((state) => state.globalReducer.featureDragable);
  const [advanceVowelLeft, setAdvanceVowelLeft] = useState(prefix)

  const handleAddDragItem = (e, word, type, typeText, tempId=false) => {
    if (user.planActivation) {
      if (!user.planActivation.isActive) {
        return
      }
    }
    if (!tempId) {
      dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
      const isIdTemp = (element) => element.id === "tempId";
      const isIdTes = (element) => element.id === "tesId";
      if (gameEvent.findIndex(isIdTemp) !== -1) gameEvent.splice(gameEvent.findIndex(isIdTemp), 1);
      if (gameEvent.findIndex(isIdTes) !== -1) gameEvent.splice(gameEvent.findIndex(isIdTes), 1);
      let IdIncrement = 0;
      if (gameEvent.length !== 0)
        IdIncrement = gameEvent[gameEvent.length - 1].id;
      if (typeText === "prefix") {
        let styleBorderNText = "text-yellow-600 border-black";
        if (e.changedTouches) {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-350, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: 'prefix', value:word}]})
        } else {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-350, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: 'prefix', value:word}]})
        }
        dispatch({ type: "REDO_EVENT", payload: []})
      } else if (typeText === "root") {
        let styleBorderNText = "text-root-600 border-black";
        if (e.changedTouches) {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-350, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        } else {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-350, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        }
        dispatch({ type: "REDO_EVENT", payload: []})
      } else {
        let styleBorderNText = "text-red border-black";
        if (e.changedTouches) {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-350, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: 'suffixes', value:word}]})
        } else {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-350, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: 'suffixes', value:word}]})
        }
        dispatch({ type: "REDO_EVENT", payload: []})
      }
    } else {
      const checkTile = gameEvent.find(({ id }) => id === "tempId");
      const checkTileTes = gameEvent.find(({ id }) => id === "tesId");
      if (checkTile || checkTileTes) {
        const tempObj = checkTile
        const isIdTemp = (element) => element.id === "tempId";
        gameEvent.splice(gameEvent.findIndex(isIdTemp), 1);
        if (gameEvent.findIndex(isIdTemp) === -1) {
          if (e.changedTouches) {
            tempObj['x'] = e.changedTouches['0'].pageX-100
            tempObj['y'] = e.changedTouches['0'].pageY-350
            tempObj['id'] = 'tesId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          } else {
            tempObj['x'] = e.pageX-100
            tempObj['y'] = e.pageY-350
            tempObj['id'] = 'tesId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          }
        } else {
          const isIdTes = (element) => element.id === "tesId";
          gameEvent.splice(gameEvent.findIndex(isIdTes), 1);
          if (e.changedTouches) {
            tempObj['x'] = e.changedTouches['0'].pageX-100
            tempObj['y'] = e.changedTouches['0'].pageY-350
            tempObj['id'] = 'tempId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          } else {
            tempObj['x'] = e.pageX-100
            tempObj['y'] = e.pageY-350
            tempObj['id'] = 'tempId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          }
        }
      } else {
        dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
        let IdIncrement = 0;
        if (gameEvent.length !== 0)
          IdIncrement = gameEvent[gameEvent.length - 1].id;
        if (typeText === "prefix") {
          let styleBorderNText = "text-yellow-600 border-black";
          if (e.changedTouches) {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-350, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: 'prefix', value:word}]})
          } else {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-350, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: 'prefix', value:word}]})
          }
          dispatch({ type: "REDO_EVENT", payload: []})
        } else if (typeText === "root") {
          let styleBorderNText = "text-root-600 border-black";
          if (e.changedTouches) {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-350, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          } else {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-350, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          }
          dispatch({ type: "REDO_EVENT", payload: []})
        } else {
          let styleBorderNText = "text-red border-black";
          if (e.changedTouches) {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-350, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: 'suffixes', value:word}]})
          } else {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-350, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: 'suffixes', value:word}]})
          }
          dispatch({ type: "REDO_EVENT", payload: []})
        }
      }
    }
  };

  const handleSetAlfabet = () => {
      setAdvanceVowelLeft(prefix)
    }

  return (
    <>
      <div className="flex justify-center w-full">
        <div className="px-3 flex flex-col gap-1">
          <div className="flex flex-col gap-2 p-[9px] w-full rounded-2xl shadow-[inset_2px_2px_0px_rgba(0,_0,_0,_0.1);] bg-yellow-300">
            <div className="grid grid-cols-5 w-auto max-w-3xl min-w-[16rem] gap-[0.5rem]">
              {prefix.map((item, index) => (
                  <DraggableBox prefix word={item} key={index} addNewItem={handleAddDragItem} handleSetAlfabet={handleSetAlfabet} typeWord={'prefix'}/>
              ))}
            </div>
          </div>
        </div>

        <div className="px-3 flex flex-col gap-1">
          <div className="flex flex-col gap-2 p-[9px] w-full rounded-2xl shadow-[inset_2px_2px_0px_rgba(0,_0,_0,_0.1);] bg-root-600">
            <div className="grid grid-cols-6 w-auto max-w-3xl min-w-[25rem] gap-[0.5rem]">
              {roots.map((item, index) => (
                  <DraggableBox root word={item} key={index} addNewItem={handleAddDragItem} handleSetAlfabet={handleSetAlfabet} typeWord={'root'}/>
              ))}
            </div>
          </div>
        </div>

        <div className="px-3 flex flex-col gap-1">
          <div className="flex flex-col gap-2 p-[9px] w-full rounded-2xl shadow-[inset_2px_2px_0px_rgba(0,_0,_0,_0.1);] bg-red">
            <div className="grid grid-cols-6 w-auto max-w-3xl min-w-[19rem] gap-[0.5rem]">
              {suffixes.map((item, index) => (
                  <DraggableBox suffixes word={item} key={index} addNewItem={handleAddDragItem} handleSetAlfabet={handleSetAlfabet} typeWord={'suffixes'}/>
              ))}
            </div>
          </div>
        </div>

        <div className="px-3 flex flex-col gap-1">
          <div className="flex flex-col gap-2 p-[9px] w-full rounded-2xl shadow-[inset_2px_2px_0px_rgba(0,_0,_0,_0.1);] bg-red">
            <div className="grid grid-cols-4 w-auto max-w-3xl min-w-[17rem] gap-[0.5rem]">
              {suffixes2.map((item, index) => (
                  <DraggableBox suffixes2 word={item} key={index} addNewItem={handleAddDragItem} handleSetAlfabet={handleSetAlfabet} typeWord={'suffixes2'}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
