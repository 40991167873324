import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDoubleTap } from 'use-double-tap';
import { FaCheck } from "react-icons/fa";

import BasicFooter from "../components/game/gameFooter";
import Tabs from "../components/game/gameTabs/index";
import InitialBlend from "../components/game/initialBlend";
import Background from "../assets/hover/background.png";
// import DraggableBox from "../components/common/Draggable";
import Draggable from "react-draggable";
import clsx from "clsx";

import Vector36 from "../assets/Vector36.svg";
import Vector37 from "../assets/Vector37.svg";
import PhotoDefault from "../assets/photo.png";
// import GluedSound from "../components/game/gluedSound";

import { Stage, Layer, Line, Rect, Group } from 'react-konva';
import { Html } from "react-konva-utils";
import { useNavigate, Link } from "react-router-dom";
import { detailUser } from "../redux/actions/User";

import Heart from "../assets/heart-game.png"

function Game() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let token = localStorage.getItem('id_token');
  let userDetail = localStorage.getItem('userDetail')
  userDetail = JSON.parse(userDetail)
  const gameEvent = useSelector((state) => state.globalReducer.gameEvent);
  const finalBlendShow = useSelector((state) => state.globalReducer.finalBlendShow);
  const statusPage = useSelector((state) => state.globalReducer.statusPage);
  const featureDragable = useSelector((state) => state.globalReducer.featureDragable);
  const activeTile = useSelector((state) => state.globalReducer.activeTile);
  const [statusBg, setStatusBg] = useState("yellow");
  const [statusColorDraw, setStatusColorDraw] = useState("#B32F2A");
  const [selectedImage, setSelectedImage] = useState('');
  const [waitingClick, setWaitingClick] = useState(null);
  const [lastClick, setLastClick] = useState(0);

  const [showDropdown, setShowDropdown] = useState(false)

  const [lines, setLines] = useState([]);
  const isDrawing = useRef(false);

  const goLogout = () => {
    localStorage.clear()
    navigate("/")
  }

  const processClick = (e, item, eventClick) => {
    const eventTouch = localStorage.getItem('input_button')
    if (eventTouch === 'touch' && eventClick === "touch") {
      if(lastClick&&e.timeStamp - lastClick < 250 && waitingClick){
        setLastClick(0);
        clearTimeout(waitingClick);
        setWaitingClick(null);
        handleDoubleClick(item)
      } else {
        setLastClick(e.timeStamp);
        setWaitingClick(setTimeout(()=>{
        setWaitingClick(null);
        }, 251))
      }
    } else if (eventTouch !== 'touch' && eventClick !== "touch") {
      if(lastClick&&e.timeStamp - lastClick < 250 && waitingClick){
        setLastClick(0);
        clearTimeout(waitingClick);
        setWaitingClick(null);
        handleDoubleClick(item)
      } else {
        setLastClick(e.timeStamp);
        setWaitingClick(setTimeout(()=>{
        setWaitingClick(null);
        }, 251))
      }
    }
    }

  const handleMouseDown = (e, eventClick) => {
    const eventTouch = localStorage.getItem('input_button')
    if (eventTouch === 'touch' && eventClick === "touch") {
      isDrawing.current = true;
      const pos = e.target.getStage().getPointerPosition();
      let tool = 'none'
      if (featureDragable.pen) {
        tool = 'pen'
      } else if (featureDragable.erase) {
        tool = 'eraser'
      } else {
        tool = 'none'
        isDrawing.current = false;
      }
      if (tool !== 'none') {
        dispatch({ type: "GAME_EVENT", payload: [...gameEvent, { typeEvent: 'drawing', tool: tool, statusColor: statusColorDraw, points: [pos.x, pos.y]}]})
      }
    } else if (eventTouch !== 'touch' && eventClick !== "touch") {
      isDrawing.current = true;
      const pos = e.target.getStage().getPointerPosition();
      let tool = 'none'
      if (featureDragable.pen) {
        tool = 'pen'
      } else if (featureDragable.erase) {
        tool = 'eraser'
      } else {
        tool = 'none'
        isDrawing.current = false;
      }
      if (tool !== 'none') {
        dispatch({ type: "GAME_EVENT", payload: [...gameEvent, { typeEvent: 'drawing', tool: tool, statusColor: statusColorDraw, points: [pos.x, pos.y]}]})
      }
    }
  };
  
  const handleClickLine = async (idx, eventClick) => {
    const eventTouch = localStorage.getItem('input_button')
    if (eventTouch === 'touch' && eventClick === "touch") {
      if (gameEvent.length === 0){
        return;
      }
      const currentLine = gameEvent[idx];
      if (currentLine.active) {
        currentLine['shadowColor'] = false
        currentLine['shadowBlur'] = 0
        currentLine['active'] = false
      } else {
        currentLine['shadowColor'] = true
        currentLine['shadowBlur'] = 8
        currentLine['active'] = true
      }
  
      const tempLine = gameEvent;
      tempLine.splice(idx, 1);
      dispatch({ type: "GAME_EVENT", payload: [...tempLine, currentLine]})
    } else if (eventTouch !== 'touch' && eventClick !== "touch") {
      if (gameEvent.length === 0){
        return;
      }
      const currentLine = gameEvent[idx];
      if (currentLine.active) {
        currentLine['shadowColor'] = false
        currentLine['shadowBlur'] = 0
        currentLine['active'] = false
      } else {
        currentLine['shadowColor'] = true
        currentLine['shadowBlur'] = 8
        currentLine['active'] = true
      }
  
      const tempLine = gameEvent;
      tempLine.splice(idx, 1);
      dispatch({ type: "GAME_EVENT", payload: [...tempLine, currentLine]})
      // setLines(tempLine)
    }
  }
  
  const handleMouseMove = (e, eventClick) => {
    // no drawing - skipping
    const eventTouch = localStorage.getItem('input_button')
    if (eventTouch === 'touch' && eventClick === "touch") {
      if (!isDrawing.current) {
        return;
      }
      const stage = e.target.getStage();
      const point = stage.getPointerPosition();
      let lastLine = gameEvent[gameEvent.length - 1];
      // add point
      lastLine.points = lastLine.points.concat([point.x, point.y]);
  
      // replace last
      gameEvent.splice(gameEvent.length - 1, 1, lastLine);
      // setLines(lines.concat());
      dispatch({ type: "GAME_EVENT", payload: gameEvent.concat()})
    } else if (eventTouch !== 'touch' && eventClick !== "touch") {
      if (!isDrawing.current) {
        return;
      }
      const stage = e.target.getStage();
      const point = stage.getPointerPosition();
      let lastLine = gameEvent[gameEvent.length - 1];
      // add point
      lastLine.points = lastLine.points.concat([point.x, point.y]);
  
      // replace last
      gameEvent.splice(gameEvent.length - 1, 1, lastLine);
      // setLines(lines.concat());
      dispatch({ type: "GAME_EVENT", payload: gameEvent.concat()})
    }
  };

  const handleMouseUp = (e, eventClick) => {
    const eventTouch = localStorage.getItem('input_button')
    if (eventTouch === 'touch' && eventClick === "touch") {
      isDrawing.current = false;
    } else if (eventTouch !== 'touch' && eventClick !== "touch") {
      isDrawing.current = false;
    }
  };

  const changeBg = (event) => {
    const docBody = document.querySelector("body");
    if (event === "yellow") {
      docBody.classList.remove("green-bg");
      docBody.classList.add("yellow-bg");
      setStatusBg("yellow");
      if (statusColorDraw === "#fff") setStatusColorDraw("#333333")
    } else {
      docBody.classList.remove("yellow-bg");
      docBody.classList.add("green-bg");
      setStatusBg("green");
      if (statusColorDraw === "#333333") setStatusColorDraw("#fff")
    }
  };

  const handleShowFinalBlend = () => {dispatch({ type: "FINAL_BLEND_SHOW", payload: !finalBlendShow })};

  const handleDoubleClick = (event) => {
    const isEmpty = Object.keys(activeTile).length === 0;
    if (!isEmpty) {
      if (activeTile[`${event.id}`] === true) {
        let tempObj = activeTile;
        delete tempObj[`${event.id}`];
        dispatch({ type: "ACTIVE_TILE", payload: { ...tempObj } });
      } else {
        dispatch({
          type: "ACTIVE_TILE",
          payload: { ...activeTile, [event.id]: true },
        });
      }
    } else {
      dispatch({ type: "ACTIVE_TILE", payload: { [event.id]: true } });
    }
  };

  // const handlePositionTarget = async (id, e, data) => {
  //   function extractTranslateFromTransform(transform) {
  //       let translateValue = null;
  //       let translate = transform.match(/translate\(.*\)/)?.[0];
  //       if (translate) {
  //           translateValue = {};
  //           if (translate.indexOf(',') !== -1) {
  //               translateValue.x = parseFloat(translate.substring(translate.indexOf('(') + 1, translate.indexOf(',')));
  //               translateValue.y = parseFloat(translate.substring(translate.indexOf(',') + 1));
  //           } else {
  //               translateValue.x = translateValue.y = parseFloat(translate.substring(translate.indexOf('(') + 1));
  //           }
  //       }
  //       return translateValue;
  //   }
    
  //   const translate = extractTranslateFromTransform(document.getElementById(id).style.transform);
  //   // const eventGame = gameEvent
  //   // eventGame.forEach(el => {
  //   //   if (el.id === id) {
  //   //     el.x = e.x
  //   //     el.y = e.y
  //   //   }
  //   // });
  //   // await dispatch({ type: "GAME_EVENT", payload: [...eventGame]})
  // }
  
  const handlePositionTargetStop = async (id) => {
    // dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
    function extractTranslateFromTransform(transform) {
      let translateValue = null;
      let translate = transform.match(/translate\(.*\)/)?.[0];
      if (translate) {
          translateValue = {};
          if (translate.indexOf(',') !== -1) {
              translateValue.x = parseFloat(translate.substring(translate.indexOf('(') + 1, translate.indexOf(',')));
              translateValue.y = parseFloat(translate.substring(translate.indexOf(',') + 1));
          } else {
              translateValue.x = translateValue.y = parseFloat(translate.substring(translate.indexOf('(') + 1));
          }
      }
      return translateValue;
  }
  
  const translate = extractTranslateFromTransform(document.getElementById(id).style.transform);
  const eventGame = gameEvent
    eventGame.forEach(el => {
      if (el.id === id) {
        el.x = translate.x
        el.y = translate.y
      } 
    });
    dispatch({ type: "GAME_EVENT", payload: [...eventGame]})
  }

  useEffect(() => {
    let inputButton = localStorage.getItem("input_button");
    dispatch({ type: "INPUT_BUTTON", payload: inputButton });
    dispatch({ type: "GAME_EVENT", payload: []})
    const docBody = document.querySelector("body");
    docBody.classList.remove("green-bg");
    docBody.classList.add("yellow-bg");
    setStatusBg("yellow");
    if (userDetail.profileImage) setSelectedImage(userDetail.profileImage)
    dispatch(detailUser(token, (result)=>{
      if (result === 'invalid token' || result === 'user not Found') {
        localStorage.removeItem('userDetail')
        localStorage.removeItem('id_token')
        localStorage.removeItem('input_button')
        navigate('/')
      }
    }))
  }, [])
  
  return (
    <div className="min-h-screen flex relative flex-col h-screen">
      <div className="grid justify-items-center items-center sticky top-0">
        <div className="bg-white maxLg:w-full shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-6px_0px_#F4F4F4] rounded-b-lg w-screen h-fit p-4">
          <Tabs />
        </div>
      </div>
      
      {
      statusPage === 'Syllable Labels'?
      ''
      :
      statusPage === 'Basic Tiles' ?  
      finalBlendShow ? (<InitialBlend padding={'pt-4'} animation={"fadeIn"} />) : (<InitialBlend padding={'pt-4'} animation={"fadeOut"} />)
      :
      finalBlendShow ? (<InitialBlend padding={'pt-[70px]'} animation={"fadeIn"} />) : (<InitialBlend padding={'pt-[70px]'} animation={"fadeOut"} />)
      }

      <div className="mt-auto h-full w-full relative overflow-hidden">

        {
          statusPage === 'Basic Tiles' || statusPage === 'Syllable Labels' ?
          ''
          :
          <button 
            onClick={() => handleShowFinalBlend()}
            className="absolute z-50 left-3 top-2 w-fit shadow-[0px_4px_0px_#EBEBEB,_inset_0px_6px_0px_rgba(255,_255,_255,_0.15)] px-8 maxXl:px-5 p-[13px] maxXl:p-[8px] bg-green-1 rounded-[10px] text-xs maxXl:text-[10px] font-bold text-yellow-300 hover:bg-green-2 border-[3px] border-white shadowText"
          >
            Initial and Final Blends
          </button>
        }

        <div className={`w-full h-full p-2 pb-28 ${featureDragable.pen ? 'cursor-pen' : featureDragable.erase ? 'cursor-erase' : ''}`}>
          <div className="w-full h-full grid grid-cols-5 gap-6 relative">
            <div className="absolute z-50 top-1 right-5 flex flex-row items-start">
                <div className="flex justify-center">
                    <div className="dropdown relative">
                        <button
                            className="dropdown-toggle pl-6 text-white rounded transition duration-150 ease-in-out flex items-center font-poppins text-base font-semibold "
                            type="button"
                            onClick={()=>setShowDropdown(!showDropdown)}
                        >
                          {
                            selectedImage ?
                            <>
                                <img
                                src={selectedImage}
                                className="w-20 h-20 maxXl:w-14 maxXl:h-14 object-contain border-4 bg-grey-1000 border-white drop-shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15)] rounded-2xl"
                                alt="Thumb"
                                />
                            </>
                            :
                            <img alt="logo" src={PhotoDefault} className='w-20 h-20 maxXl:w-14 maxXl:h-14 border-4 border-white drop-shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15)] rounded-2xl'/>
                          }
                        </button>
                        <ul className={`dropdown-menu -ml-9 p-2 maxXl:p-1 min-w-full w-[141px] maxXl:w-28 absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-3 m-0 bg-clip-padding border-none ${showDropdown ? '' : 'hidden'}`} >
                          <li>
                            <Link to="/game/profile" className="text-start dropdown-item text-sm maxXl:text-[10px] py-1 maxXl:py-[1px] px-4 font-normal block w-full whitespace-nowrap bg-transparent text-black hover:bg-grey-1100 hover:text-black hover:rounded-md">Profile</Link>
                          </li>
                            {
                              userDetail ? 
                              userDetail.planActivation ? 
                              !userDetail.planActivation.isActive ? 
                                <li>
                                  <Link to="/game/select-plan" className="text-start dropdown-item text-sm maxXl:text-[10px] py-1 maxXl:py-[1px] px-4 font-normal block w-full whitespace-nowrap bg-transparent text-black hover:bg-grey-1100 hover:text-black hover:rounded-md">Select Plan</Link>
                                </li>
                                : ''
                              :
                              !userDetail.isActive ?
                              <li>
                                  <Link to="/game/select-plan" className="text-start dropdown-item text-sm maxXl:text-[10px] py-1 maxXl:py-[1px] px-4 font-normal block w-full whitespace-nowrap bg-transparent text-black hover:bg-grey-1100 hover:text-black hover:rounded-md">Select Plan</Link>
                                </li>
                                : ''
                              : ''
                            }
                          <li>
                              <button onClick={()=>goLogout()} className="text-left dropdown-item text-sm maxXl:text-[10px] py-1 maxXl:py-[1px] px-4 font-normal block w-full whitespace-nowrap bg-transparent text-red hover:bg-grey-1100 hover:text-red hover:rounded-md">Log Out</button>
                          </li>
                        </ul>
                    </div>
                    {console.log(window.screen.width < 1335)}
                </div>
            </div>
              <Stage
                width={window.innerWidth}
                height={window.innerHeight}
                onTouchMove={(e)=>handleMouseMove(e, 'touch')}
                onTouchEnd={(e)=>handleMouseUp(e, 'touch')}
                onTouchStart={(e)=>handleMouseDown(e, 'touch')}
                onMouseDown={(e)=>handleMouseDown(e, 'click')}
                onMousemove={(e)=>handleMouseMove(e, 'click')}
                onMouseup={(e)=>handleMouseUp(e, 'click')}
                className={featureDragable.pen || featureDragable.erase ? 'z-10' : ''}
              >
                <Layer>
                  {
                  gameEvent.length !== 0 ?
                  gameEvent.map((line, i) => (
                    <>
                      {
                        line.typeEvent === 'drawing' ?
                          <Line
                            className='cursor-pointer'
                            key={i}
                            onTap={()=>handleClickLine(i, 'touch')}
                            onClick={()=>handleClickLine(i, 'mouse')}
                            onMouseEnter={e => {
                              if (!featureDragable.pen && !featureDragable.erase) {
                                const container = e.target.getStage().container();
                                container.style.cursor = "pointer";
                              }
                            }}
                            onMouseLeave={e => {
                              const container = e.target.getStage().container();
                              container.style = "default";
                            }}
                            points={line.points}
                            stroke={line.statusColor}
                            shadowColor={line.shadowColor ? statusBg === 'yellow' ? 'black' : 'white' : ''}
                            shadowBlur={line.shadowBlur}
                            strokeWidth={line.tool === 'eraser' ? 15:5}
                            tension={0.5}
                            lineCap="round"
                            globalCompositeOperation={
                              line.tool === 'eraser' ? 'destination-out' : line.tool === 'pen' ? 'source-over' : ''
                            }
                          />
                        : ''
                      }
                    </>
                  )): ''}
                </Layer>
              </Stage>
            {gameEvent.length !== 0
              ? gameEvent.map((item, i) => (
                  <Draggable
                    position={{x: item.x, y: item.y}}
                    grid={
                      featureDragable.snap ? 
                        window.screen.width <= 1335 ?
                      [10.4,10.4] : [15.6, 15.6]
                      : ''
                    }
                    // onDrag={(e, data)=>{handlePositionTarget(item.id, e, data)}}
                    onStop={()=>handlePositionTargetStop(item.id)}
                    key={item.id}
                  >
                    {item.type === "alfabet" && item.typeEvent !== 'drawing'? (
                      <div
                        id={item.id}
                        onClick={(e)=>processClick(e, item, 'mouse')}
                        onTouchStart={(e)=>processClick(e, item, 'touch')}
                        className={clsx(
                          "flex fixed justify-center justify-items-center bg-white place-content-center cursor-pointer px-[30px] maxXl:px-5 rounded-[20px] border-[3px] shadow-[inset_0px_-18px_0px_rgba(156,_156,_156,_0.14)] fix min-w-[156px] maxXl:min-w-[116px] min-h-[156px] maxXl:min-h-[116px]",
                          item.styleBorderNText,
                          activeTile[`${item.id}`]
                            ? statusBg === "yellow" ? "drop-shadow-[0px_0px_5px_#040404]":"drop-shadow-[0px_0px_5px_#FFFFFF]"
                            : ""
                        )}
                      >
                        <p
                          className={clsx(
                            "text-center font-gilroyMedium font-bold h-10 text-[90px] maxXl:text-[60px]"
                          )}
                        >
                          {item.value}
                        </p>
                      </div>
                    ) 
                    :
                    item.type === "suffixes"  && item.typeEvent !== 'drawing'? (
                      <div
                        id={item.id}
                        onClick={(e)=>processClick(e, item, 'mouse')}
                        onTouchStart={(e)=>processClick(e, item, 'touch')}
                        className={clsx(
                          "flex fixed justify-center justify-items-center bg-white place-content-center cursor-pointer pl-2.5 pr-[30px] rounded-[20px] border-[3px]",
                          "shadow-[inset_0px_-18px_0px_rgba(156,_156,_156,_0.14)] fix min-w-[156px] maxXl:min-w-[116px] min-h-[156px] maxXl:min-h-[116px]",
                          item.styleBorderNText,
                          activeTile[`${item.id}`]
                            ? statusBg === "yellow" ? "drop-shadow-[0px_0px_5px_#040404]":"drop-shadow-[0px_0px_5px_#FFFFFF]"
                            : ""
                        )}
                      >
                        <p
                          className={clsx(
                            "text-center font-gilroyMedium font-bold h-10 mr-auto",
                            "text-[90px] maxXl:text-[60px]"
                          )}
                        >
                          {item.value}
                        </p>
                      </div>
                    ) 
                    :
                    item.type === "prefix" && item.typeEvent !== 'drawing' ? (
                      <div
                        id={item.id}
                        onClick={(e)=>processClick(e, item, 'mouse')}
                        onTouchStart={(e)=>processClick(e, item, 'touch')}
                        className={clsx(
                          "flex fixed justify-center justify-items-center bg-white place-content-center cursor-pointer pr-2.5 pl-[30px] rounded-[20px] border-[3px]",
                          "shadow-[inset_0px_-18px_0px_rgba(156,_156,_156,_0.14)] fix min-w-[156px] maxXl:min-w-[116px] min-h-[156px] maxXl:min-h-[116px]",
                          item.styleBorderNText,
                          activeTile[`${item.id}`]
                            ? statusBg === "yellow" ? "drop-shadow-[0px_0px_5px_#040404]":"drop-shadow-[0px_0px_5px_#FFFFFF]"
                            : ""
                        )}
                      >
                        <p
                          className={clsx(
                            "text-center font-gilroyMedium font-bold h-10 ml-auto",
                            "text-[90px] maxXl:text-[60px]"
                          )}
                        >
                          {item.value}
                        </p>
                      </div>
                    ) 
                    :
                    item.type === "labels" && item.typeEvent !== 'drawing' ? (
                      <div
                        id={item.id}
                        onClick={(e)=>processClick(e, item, 'mouse')}
                        onTouchStart={(e)=>processClick(e, item, 'touch')}
                        className={clsx(
                          "flex fixed justify-center justify-items-center bg-white place-content-center cursor-pointer px-[10px] py-[5px] maxXl:py-[2px] maxXl:rounded-[10px] rounded-[15px] border-[3px] maxXl:border-2",
                          "shadow-[inset_0px_-18px_0px_rgba(156,_156,_156,_0.14)] maxXl:shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] fix min-w-[90px] maxXl:min-w-[50px] min-h-[85px] maxXl:min-h-[45px]",
                          item.styleBorderNText,
                          activeTile[`${item.id}`]
                            ? statusBg === "yellow" ? "drop-shadow-[0px_0px_5px_#040404]":"drop-shadow-[0px_0px_5px_#FFFFFF]"
                            : ""
                        )}
                      >
                        <p
                          className={clsx(
                            "text-center font-gilroyMedium font-bold h-10 ",
                            "text-[40px] maxXl:text-[20px]"
                          )}
                        >
                          {item.value}
                        </p>
                      </div>
                    )
                    : item.type === "initialBlend" && item.typeEvent !== 'drawing' || item.type === "gluedSound" && item.typeEvent !== 'drawing' ? (
                      <div
                        id={item.id}
                        onClick={(e)=>processClick(e, item, 'mouse')}
                        onTouchStart={(e)=>processClick(e, item, 'touch')}
                        className={clsx(
                          "fixed justify-center justify-items-center bg-white place-content-center cursor-pointer pb-5 rounded-[20px] border-[3px] shadow-[inset_0px_-18px_0px_rgba(156,_156,_156,_0.14)] fix min-w-[156px] maxXl:min-w-[116px] min-h-[156px] maxXl:min-h-[116px]",
                          item.styleBorderNText,
                          activeTile[`${item.id}`]
                            ? statusBg === "yellow" ? "drop-shadow-[0px_0px_5px_#040404]":"drop-shadow-[0px_0px_5px_#FFFFFF]"
                            : ""
                        )}
                      >
                        <p
                          className={clsx(
                            "text-center font-gilroyMedium font-bold h-10 text-[90px] maxXl:text-[60px]"
                          )}
                        >
                          {item.value}
                        </p>
                        <img
                          draggable="false"
                          alt="Vector"
                          src={item.type === "gluedSound" ? Vector37 : Vector36}
                          className={`${item.type === "gluedSound" ? 'bottom-3' : 'bottom-0'} left-[16px] absolute w-[120px] maxXl:w-[80px]`}
                        />
                      </div>
                    ) 
                    : 
                    item.type === 'image' && item.typeEvent !== 'drawing' ? (
                      (
                        <img id={item.id} src={Heart} draggable="false" alt='heart'
                          onClick={(e)=>processClick(e, item, 'mouse')}
                        onTouchStart={(e)=>processClick(e, item, 'touch')}
                          className={clsx(
                            "fixed cursor-pointer pt-2",
                            "fix w-[140.17px] maxXl:w-[100.17px] h-[116px] maxXl:h-[86px]",
                            activeTile[`${item.id}`]
                              ? statusBg === "yellow" ? "drop-shadow-[0px_0px_5px_#040404]":"drop-shadow-[0px_0px_5px_#FFFFFF]"
                              : ""
                          )}
                        />
                      )
                    )
                    :
                    item.typeEvent !== 'drawing' ?
                    (
                      <div
                        id={item.id}
                        onClick={(e)=>processClick(e, item, 'mouse')}
                        onTouchStart={(e)=>processClick(e, item, 'touch')}
                        className={clsx(
                          "flex fixed justify-center justify-items-center place-content-center cursor-pointer pt-2 rounded-[20px] border-[3px]",
                          "shadow-[inset_0px_-18px_0px_rgba(156,_156,_156,_0.14)] fix min-w-[156px] maxXl:min-w-[116px] min-h-[156px] maxXl:min-h-[116px]",
                          activeTile[`${item.id}`]
                            ? statusBg === "yellow" ? "drop-shadow-[0px_0px_5px_#040404]":"drop-shadow-[0px_0px_5px_#FFFFFF]"
                            : ""
                        )}
                        style={{ background: item.value }}
                      ></div>
                    )
                    : <></>
                    }
                  </Draggable>
                ))
              : ""}
          </div>
        </div>

        <div className="tooltip2 w-15 maxXl:w-12 py-2 px-2 right-7 bottom-32 h-28 maxXl:h-20 gap-2 shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15)] bg-white rounded-lg flex flex-col z-20">
          <button
            onClick={() => changeBg("yellow")}
            className={`w-12 maxXl:w-8 h-12 maxXl:h-8 bg-yellow-1000 shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] rounded-lg grid items-center ${
              statusBg === "yellow" ? "border-[3px] border-black" : ""
            } justify-items-center`}
          >
            {statusBg === "yellow" ? <FaCheck color="#000" className="text-2xl maxXl:text-sm" /> : ""}
          </button>
          <button
            onClick={() => changeBg("green")}
            className={`w-12 maxXl:w-8 h-12 maxXl:h-8 bg-green-1 shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] rounded-lg grid items-center ${
              statusBg === "green" ? "border-[3px] border-[#000]" : ""
            } justify-items-center`}
          >
            {statusBg === "green" ? <FaCheck color="#fff" className="text-2xl maxXl:text-sm" /> : ""}
          </button>

          <img
            alt="Icon Ink"
            src={Background}
            className="tooltiptext2 bottom-32 maxXl:bottom-[5.5rem] right-3 max-w-[150px] maxXl:max-w-[100px]"
          />
        </div>
      </div>
      <BasicFooter statusBg={statusBg} setStatusColorDraw={setStatusColorDraw} statusColorDraw={statusColorDraw} lines={lines} setLines={setLines}/>
    </div>
  );
}

export default Game;
