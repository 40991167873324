import React, { useEffect } from "react";
import ErrorPage from "./views/ErrorPage";
import HomePage from "./views/HomePage";
import LandingPage from "./views/LandingPage";
import Game from "./views/Game";
import Register from "./views/FormRegisterTrial";
import VerifyEmailPage from "./views/VerifyEmailPage";
import VerifiedPage from "./views/VerifiedPage";
import ForgotPasswordPage from "./views/ForgotPasswordPage";
import CheckEmailPage from "./views/CheckEmailPage";
import CreateNewPassword from "./views/CreateNewPassword";
import SuccessResetPasswordPage from "./views/SuccessResetPasswordPage";
import LoginPage from "./views/LoginPage";

import Profile from "./views/Profile";
import ProfilePlan from "./views/Profile/plan";
import Member from "./views/Profile/member";
import ProfileNotification from "./views/Profile/notification";
import ProfilePassword from "./views/Profile/password";

import { Routes, Route, useNavigate, Navigate, useLocation, Outlet } from "react-router-dom";
import SelectPlanPage from "./views/SelectPlanPage";
import SignUpSelectPlanPage from "./views/SignUpSelectPlanPage";
import PaymentPage from "./views/PaymentPage";
import OrderCompletedPage from "./views/OrderCompletedPage";
import SelectPlanGamePage from "./views/SelectPlanGamePage";

// ADMIN
import Dashboard from "./views/admin/Dashboard";
import ListAdmin from "./views/admin/ListAdmin";
import ListMember from "./views/admin/ListMember";
import Sidebar from "./views/admin/Sidebar";

function App() {
  let token = localStorage.getItem('id_token');
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (token === "undefined" && location.pathname === '/home' || token === "undefined" && location.pathname !== '/game' || token === null && location.pathname === '/home' || token === null && location.pathname === '/game' || !token && location.pathname === '/home' || !token && location.pathname === '/game')  {
        navigate("/login");
          // document.body.style.backgroundColor = "#FFD456";
          // document.body.style.backgroundImage = "url('grid2.svg')";
        return
    } else {
      if (token !== "undefined" && token !== null && token) {
        if (location.pathname.includes('/login') || location.pathname.includes('/register')) {
          navigate('/home');
        }
      } else {
        if (location.pathname.includes('/home') || location.pathname.includes('/game')) {
          navigate('/login')
          // document.body.style.backgroundColor = "#FFD456";
          // document.body.style.backgroundImage = "url('grid2.svg')";
        } else {
          navigate(location.pathname)
        }
      }
    }
    // let tokenData = localStorage.getItem('id_token').includes(".") ?
    //     JSON.parse(atob(localStorage.getItem('id_token').split('.')[1])) :
    //     localStorage.getItem('id_token')


    // if (typeof tokenData === 'object') {
    //     if (tokenData.hasOwnProperty('user_id')) {
    //         // getMenus()
    //         if (window.location.pathname.includes('/login')) {
    //             navigate("/home");
    //         }
    //         return
    //     }
    // }
    // navigate("/login");
  }, []);

  const ProtectedRoute = ({ redirectPath = '/login', children }) => {
    document.body.style.backgroundColor = "";
    document.body.style.backgroundImage = "";
    const docBody = document.querySelector("body");
    docBody.classList.add("yellow-bg");
    if (token === "undefined" && location.pathname.includes('/home') || token === "undefined" && location.pathname.includes('/game') || token === null && location.pathname.includes('/home') || token === null && location.pathname.includes('/game') || !token && location.pathname.includes('/home') || !token && location.pathname.includes('/game')) {
      return <Navigate to='/login' replace />;
    }

    const userDetail = localStorage.getItem('userDetail')
    if (JSON.parse(userDetail)) {
      if (JSON.parse(userDetail).role) {
        return <Navigate to='/admin/dashboard' replace />;
      }
    }

    return children ? 
    <>
      {children}
    </>
    : 
    <Outlet />;
  };

  const GuardRoute = ({ redirectPath = '/login', children }) => {
    document.body.style.backgroundColor = "#F9F9F9";
    document.body.style.backgroundImage = "url('')";
    if (token === "undefined" && location.pathname.includes('/admin') || token === null && location.pathname.includes('/admin') || !token && location.pathname.includes('/admin')) {
      document.body.style.backgroundColor = null;
      document.body.style.backgroundImage = null;
      return <Navigate to='/login' replace />;
    }

    // const userDetail = localStorage.getItem('userDetail')
    // if (JSON.parse(userDetail)) {
    //   if (!JSON.parse(userDetail).role) {
    //     return <Navigate to='/' replace />;
    //   }
    // }

    return children ? 
    <>
      {children}
    </>
    : 
    <Outlet />;
  };

  return (
    <Routes>
      <Route exact path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
      <Route exact path="/game" element={<ProtectedRoute><Game /></ProtectedRoute>} />
      <Route exact path="/game/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      <Route exact path="/game/profile/plan" element={<ProtectedRoute><ProfilePlan /></ProtectedRoute>} />
      <Route exact path="/game/profile/member" element={<ProtectedRoute><Member /></ProtectedRoute>} />
      <Route exact path="/game/profile/notification" element={<ProtectedRoute><ProfileNotification /></ProtectedRoute>}/>
      <Route exact path="/game/profile/security" element={<ProtectedRoute><ProfilePassword /></ProtectedRoute>} />
      <Route exact path="/game/select-plan" element={<ProtectedRoute><SelectPlanGamePage /></ProtectedRoute>} />
      <Route exact path="/game/select-plan/payment/:status" element={<ProtectedRoute><PaymentPage /></ProtectedRoute>} />
      <Route exact path="/game/completed-payment" element={<ProtectedRoute><OrderCompletedPage /></ProtectedRoute>} />
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/login" element={<LoginPage />} />
      <Route exact path="/login/create-new-password/:token" element={<CreateNewPassword />} />
      <Route exact path="/login/success-reset-password" element={<SuccessResetPasswordPage />} />
      <Route exact path="/login/forgot-password" element={<ForgotPasswordPage />} />
      <Route exact path="/login/check-email" element={<CheckEmailPage />} />
      <Route exact path="/login/select-plan" element={<SelectPlanPage />} />
      <Route exact path="/login/select-plan/register/:code" element={<SignUpSelectPlanPage />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/register/verify-email/:emailAddress" element={<VerifyEmailPage />} />
      <Route exact path="/register/verified" element={<VerifiedPage />} />
      <Route
        exact
        path="/admin/dashboard"
        element={
          <div className="flex flex-wrap h-full">
            <GuardRoute>
              <Sidebar />
              <Dashboard />
            </GuardRoute>
          </div>
        }
      /> 
      <Route
        exact
        path="/admin/list-member"
        element={
          <div className="flex flex-wrap h-full">
            <GuardRoute />
            <Sidebar />
            <ListMember />
          </div>
        }
      />
      <Route
        exact
        path="/admin/list-admin"
        element={
          <div className="flex flex-wrap h-full">
            <GuardRoute />
            <Sidebar />
            <ListAdmin />
          </div>
        }
      />
      <Route exact path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
