import clsx from "clsx";
import * as React from "react";
import { useState } from "react";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
const vokal = ["a", "i", "u", "e", "o"];
export default function DragableFit({ 
  word, 
  color, 
  addNewItem,
  handleSetAlfabet,
  typeWord }) {
  const [draged, setDrag] = useState(false);
  const featureDragable = useSelector((state) => state.globalReducer.featureDragable);

  const handleDrag = async (e) => {
    setDrag(true);
    if (typeWord=== "prefix" || typeWord=== "root" || typeWord=== "suffixes" || typeWord=== "suffixes2") {
      await addNewItem(e, word, "alfabet", typeWord, 'tempId');
    } else {
      await addNewItem(e, word, typeWord, 'tempId');
    }
    handleSetAlfabet();
  }

  return (
    <div className="flex">
      <div
        className={clsx(
          "flex justify-center justify-items-center  bg-white place-content-center cursor-pointer pt-2 ",
          "border-black",
          // draged
          //   ? "fixed shadow-[inset_0px_-28px_0px_rgba(156,_156,_156,_0.14)] w-max h-[184.71px] px-[25px] py-[10px] border-8 rounded-[35px]"
          "block shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] w-max h-[45px] px-[25px] py-[10px] border-[3px] rounded-[10px]"
        )}
      >
        <p
          className={clsx(
            "text-center font-gilroyExtrabold h-10 unset",
          )}
        >
          {word}
        </p>
      </div>
      <Draggable position={draged ? { x: 0, y: 0 } : { x: 0, y: 0 }} grid={featureDragable.snap ? [50, 50] : ''} onStop={(e)=>{addNewItem(e, word, typeWord);setDrag(false);}} onDrag={(e)=>{handleDrag(e)}}>
        <div
          className={clsx(
            draged ?
            "fixed justify-center justify-items-center bg-white place-content-center cursor-pointer px-[10px] py-[5px] rounded-[15px] border-[3px] shadow-[inset_0px_-18px_0px_rgba(156,_156,_156,_0.14)] fix min-w-[90px] min-h-[85px] w-max hidden" :
            "fixed flex justify-center justify-items-center  bg-white place-content-center cursor-pointer pt-2 border-black shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] w-max h-[45px] px-[25px] py-[10px] border-[3px] rounded-[10px]"
          )}
        >
          <p
            className={clsx(
              draged ? "text-center font-gilroyMedium font-bold h-10 text-[40px]" : "text-center font-gilroyExtrabold h-10 unset",
            )}
          >
            {word}
          </p>
        </div>
      </Draggable>
    </div>
  );
}
