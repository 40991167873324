import React, {useState} from "react";
import Logo from "../assets/logo.png";
import {useNavigate, useParams} from "react-router-dom"
import showPw from "../assets/show-pw.svg"
import hidePw from "../assets/hide-pw.svg"
import { useDispatch } from "react-redux"
import { createNewPassword } from "../redux/actions/Auth";
import PopupWindow from "../components/PopupWindow";
function CreateNewPassword() {
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordValidate, setPasswordValidate] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (password === '') {
            dispatch({type:'ERROR_LOG', payload: 'createNewPassword'})
            dispatch({type:'ERROR_MESSAGE', payload: 'Please Input New Password'})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
        } else if (passwordValidate === '') {
            dispatch({type:'ERROR_LOG', payload: 'createNewPassword'})
            dispatch({type:'ERROR_MESSAGE', payload: 'Please Input Confirm Password'})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
        } else if (password !== passwordValidate) {
            dispatch({type:'ERROR_LOG', payload: 'createNewPassword'})
            dispatch({type:'ERROR_MESSAGE', payload: 'Your Password & Confirm Password Not Same'})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
        } else {
            const paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;
            if (!password.match(paswd)) {
                dispatch({type:'ERROR_LOG', payload: 'validatePassword'})
                dispatch({type:'ERROR_MESSAGE', payload: 'Password Between 6 to 20 Characters Which Contains At Least One Lowercase Letter, One Uppercase Letter, One Numeric Digit, And One Special Character'})
                setTimeout(() => {
                    dispatch({type:'ERROR_MESSAGE', payload: ''})
                    dispatch({type:'ERROR_LOG', payload: ''})
                }, 2000);
            } else {   
                const user = {
                    password: password
                }
                await dispatch(createNewPassword(user, params.token, (result) => {
                    if (result.message === 'create new password succsesfully') {
                        navigate('/login/success-reset-password')
                    }
                }))
            }
        }
    }
    return (
        <>
            <div className="grid justify-items-center items-center h-screen">
                <PopupWindow/>
                <div className="bg-white rounded-[15px] shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14)] dark:bg-gray-800 dark:border-gray-700 py-[65px] px-[60px] max-w-[616px] min-w-[515px] min-h-[436px]">
                <div className="flex flex-col items-center">
                    <div className='w-[496px] text-center px-6'>
                        <h1 className="text-4xl text-green-1 font-gilroyBold tracking-wide mt-5">Create New Password</h1>
                        <p className="font-normal text-base mt-[26.16px] mb-8 text-center text-grey-2 tracking-wide">Your new password must be different from previous used paswords.</p>
                        <form className="w-full max-w-lg" onSubmit={e=>handleSubmit(e)}>
                            <div className='relative mb-5'>
                                <div className="w-full px-3">
                                    <input onChange={e=>{setPassword(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="New Password" />
                                    <div className="absolute inset-y-0 right-3 pr-3 flex items-center text-sm">
                                        {
                                            showPassword?
                                            <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={showPw} alt="showPw" />
                                            :
                                            <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={hidePw} alt="hidePw" />
                                        }
                                    </div>
                                </div>
                            </div>       
                            <div className='relative mb-5'>
                                <div className="w-full px-3">
                                <input onChange={e=>{setPasswordValidate(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="Confirm New Password" />
                                </div>
                            </div>
                            <button
                            className="flex shadow-[0px_4px_0px_#e9bf45,inset_0px_3px_0px_rgb(255_255_255_/_15%)] justify-center justify-items-center items-center rounded-[10px] w-full h-[62px] mt-[17.32px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold border-[3px] border-yellow hover:border-yellow"
                            >
                                <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">Reset Password</span>
                            </button>
                        </form>
                        <img alt="logo" src={Logo} className="w-[173.25px] mx-auto mt-5" />
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}

export default CreateNewPassword;
