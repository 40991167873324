import { alphabet1, alphabet2 } from "../../../constant";
import DraggableBox from "../../common/Draggable";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

export default function CapitalPanel() {
  const dispatch = useDispatch();
  const gameEvent = useSelector((state) => state.globalReducer.gameEvent);
  const user = useSelector((state) => state.userReducer.user);
  const featureDragable = useSelector((state) => state.globalReducer.featureDragable);
  // const finalBlendShow = useSelector((state) => state.globalReducer.finalBlendShow);
  const [tempAlphabet, setTempAlphabet] = useState(alphabet1)

  const handleAddDragItem = (e, word, type, tempId=false) => {
    if (user.planActivation) {
      if (!user.planActivation.isActive) {
        return
      }
    }
    if (!tempId) {
      dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
      const isIdTemp = (element) => element.id === "tempId";
      const isIdTes = (element) => element.id === "tesId";
      if (gameEvent.findIndex(isIdTemp) !== -1) gameEvent.splice(gameEvent.findIndex(isIdTemp), 1);
      if (gameEvent.findIndex(isIdTes) !== -1) gameEvent.splice(gameEvent.findIndex(isIdTes), 1);
      const vokal = ["a", "i", "u", "e", "o"];
      let IdIncrement = 0;
      if (gameEvent.length !== 0)
        IdIncrement = gameEvent[gameEvent.length - 1].id;
      if (vokal.includes(word)) {
        let styleBorderNText = "border-red text-red uppercase";
        if (e.changedTouches) {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-300, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        } else {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-300, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        }
        dispatch({ type: "REDO_EVENT", payload: []})
      } else {
        let styleBorderNText = "border-black text-black uppercase";
        if (e.changedTouches) {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-300, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        } else {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-300, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        }
        dispatch({ type: "REDO_EVENT", payload: []})
      }
    } else {
      const checkTile = gameEvent.find(({ id }) => id === "tempId");
      const checkTileTes = gameEvent.find(({ id }) => id === "tesId");
      if (checkTile || checkTileTes) {
        const tempObj = checkTile
        const isIdTemp = (element) => element.id === "tempId";
        gameEvent.splice(gameEvent.findIndex(isIdTemp), 1);
        if (gameEvent.findIndex(isIdTemp) === -1) {
          if (e.changedTouches) {
            tempObj['x'] = e.changedTouches['0'].pageX-100
            tempObj['y'] = e.changedTouches['0'].pageY-300
            tempObj['id'] = 'tesId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          } else {
            tempObj['x'] = e.pageX-100
            tempObj['y'] = e.pageY-300
            tempObj['id'] = 'tesId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          }
        } else {
          const isIdTes = (element) => element.id === "tesId";
          gameEvent.splice(gameEvent.findIndex(isIdTes), 1);
          if (e.changedTouches) {
            tempObj['x'] = e.changedTouches['0'].pageX-100
            tempObj['y'] = e.changedTouches['0'].pageY-300
            tempObj['id'] = 'tempId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          } else {
            tempObj['x'] = e.pageX-100
            tempObj['y'] = e.pageY-300
            tempObj['id'] = 'tempId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          }
        }
      } else {
        dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
        const vokal = ["a", "i", "u", "e", "o"];
        let IdIncrement = 0;
        if (gameEvent.length !== 0) IdIncrement = gameEvent[gameEvent.length-1].id;
        if (vokal.includes(word)) {
          let styleBorderNText = 'border-red text-red uppercase'
          if (e.changedTouches) {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-300, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          } else {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-300, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          }
          dispatch({ type: "REDO_EVENT", payload: []})
        } else {
          let styleBorderNText = 'border-black text-black uppercase'
          if (e.changedTouches) {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-300, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          } else {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-300, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          }
          dispatch({ type: "REDO_EVENT", payload: []})
        }
      }
    }
  };

  const handleSetAlfabet = () => {
    setTempAlphabet(alphabet1)
  }

  return (
    <>
      <div className="px-6 flex justify-center gap-1">
        <div className="flex flex-col gap-2 p-[9px] w-fit uppercase rounded-2xl shadow-[inset_2px_2px_0px_rgba(0,_0,_0,_0.1);] bg-[#f4f4f4] ">
          <div className="flex flex-col w-3xl min-w-fit max-w-3xl gap-[0.5rem]">
            <div className="flex gap-[0.5rem] w-3xl min-w-fit">
              {alphabet1.map((item, index) => (
                <DraggableBox word={item} key={index} addNewItem={handleAddDragItem} handleSetAlfabet={handleSetAlfabet} typeWord={'alfabet'}/>
              ))}
            </div>

            <div className="flex gap-[0.5rem] w-3xl min-w-fit ">
              {alphabet2.map((item, index) => (
                <DraggableBox word={item} key={index} addNewItem={handleAddDragItem} handleSetAlfabet={handleSetAlfabet} typeWord={'alfabet'}/>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
