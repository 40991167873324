import React from "react";
import LogoEdward from "../../assets/logo.png"
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import UserYellow from "../../assets/iconMenuAdmin/3-user-yellow.svg"
import User from "../../assets/iconMenuAdmin/3-user.svg"
import CategoryYellow from "../../assets/iconMenuAdmin/category-yellow.svg"
import Category from "../../assets/iconMenuAdmin/category.svg"
import ProfileYellow from "../../assets/iconMenuAdmin/profile-yellow.svg"
import Profile from "../../assets/iconMenuAdmin/profile.svg"
import Logout from "../../assets/Logout.svg"
import { useSelector } from "react-redux";

export default function Sidebar () {
    const location = useLocation();
    const sideBarActive = useSelector((state) => state.adminReducer.sideBarActive);
    const navigate = useNavigate()
    const goLogout = () => {
        localStorage.clear()
        navigate("/login")
    }
    return(
        <nav className={`w-full
        lg:w-2/12
        px-4
        tex-left
        absolute
        inset-y-0
        left-0
        transform
        -translate-x-full
        md:relative 
        md:translate-x-0
        ${sideBarActive?'relative translate-x-0':''}
        transition
        duration-200
        ease-in-out
        bg-white
        z-40`}>
            <ul className="relative h-full">
                <li className="relative flex justify-center p-4 border-b border-lightgrey-250">
                    <img src={LogoEdward} alt="logoEdward" width={150}/>
                </li>
                <li className="relative p-2 navLinkDashboard">
                    <NavLink exact to="/admin/dashboard" className={`flex items-center text-base font-gilroyMedium py-4 lg:px-1 px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out text-grey-3 lg:gap-2 gap-4`}>
                        {location.pathname === '/admin/dashboard' ? <img src={CategoryYellow} alt="category-yellow"/>:<img src={Category} alt="category"/>} Dashboard
                    </NavLink>
                </li>
                <li className="relative p-2 navLinkDashboard">
                    <NavLink exact to="/admin/list-member" className={`flex items-center text-base font-gilroyMedium py-4 lg:px-1   px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out text-grey-3 lg:gap-2 gap-4`}>
                    {location.pathname === '/admin/list-member' ? <img src={UserYellow} alt="UserYellow"/>:<img src={User} alt="User"/>}Member
                    </NavLink>
                </li>
                <li className="relative p-2 navLinkDashboard">
                    <NavLink exact to="/admin/list-admin" className={`flex items-center text-base font-gilroyMedium py-4 lg:px-1    px-6 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap rounded hover:text-gray-900 hover:bg-gray-100 transition duration-300 ease-in-out text-grey-3 lg:gap-2 gap-4`}>
                        {location.pathname === '/admin/list-admin' ? <img src={ProfileYellow} alt="ProfileYellow"/>:<img src={Profile} alt="Profile"/>}Admin
                    </NavLink>
                </li>
                <li className={`p-2 ${sideBarActive ? '' : 'absolute'} bottom-5`}>
                    <button onClick={()=>goLogout()} className="flex items-center gap-3 cursor-pointer w-fit py-4 px-6 h-12">
                        <img src={Logout} alt='logout'/>
                        <div className="text-red text-xl">log out</div>
                    </button>
                </li>
            </ul>
        </nav>
    )
}