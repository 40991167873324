import axios from "axios";

export const detailUser = (token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_URL_BE}/user/detail`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
        };
        axios(config)
        .then(({data}) => {
            dispatch({type:'USER', payload: data})
            dispatch({type:'LOADING', payload: false})
            localStorage.removeItem('userDetail')
            localStorage.setItem('userDetail', JSON.stringify(data))
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'detailUser'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const detailUserAdmin = (token, loading=true, callback) => {
    return function (dispatch) {
        if (loading) dispatch({type:'LOADING', payload: true})
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_URL_BE}/admin/detail`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
        };
        axios(config)
        .then(({data}) => {
            dispatch({type:'USER', payload: data})
            if (loading) dispatch({type:'LOADING', payload: false})
            localStorage.removeItem('userDetail')
            localStorage.setItem('userDetail', JSON.stringify(data))
            callback(data)
        }).catch(({response}) => {
            if (loading) dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'detailUser'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const editUser = (user, selectedImage, token, userId, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const data = JSON.stringify({
            'firstName': user.firstName,
            'lastName': user.lastName,
            'username': user.username,
            'phoneNumber': user.phoneNumber,
            'address': user.address,
            'profileImage': selectedImage
        });
        const config = {
            method: 'put',
            url: `${process.env.REACT_APP_URL_BE}/user/${userId}`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
            data : data
        };
        axios(config)
        .then(({data}) => {
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'editUser'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const editPassword = (user, token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const data = JSON.stringify({
            'oldPass': user.oldPassword,
            'newPass': user.newPassword,
        });
        const config = {
            method: 'put',
            url: `${process.env.REACT_APP_URL_BE}/user/editpassword`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
            data : data
        };
        axios(config)
        .then(({data}) => {
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'editPassword'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}