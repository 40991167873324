import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileNav from "../../components/profile/ProfileNav";
import { detailUser, editPassword } from "../../redux/actions/User";
import showPw from "../../assets/show-pw.svg"
import hidePw from "../../assets/hide-pw.svg"
import PopupWindow from "../../components/PopupWindow";
import PhotoDefault from "../../assets/photo.png";
import { getAllNotification } from "../../redux/actions/Notification";

export default function ProfilePassword() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = localStorage.getItem('id_token');
  let userDetail = localStorage.getItem('userDetail')
  userDetail = JSON.parse(userDetail)

  const [user, setUser] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [selectedImage, setSelectedImage] = useState('');

  const handleCancel = () => {
    setOldPassword('')
    setNewPassword('')
    setPasswordConfirm('')
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (oldPassword !== '' && newPassword !== '' && passwordConfirm !== '') {
      if (newPassword === passwordConfirm) {
        const paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;
        if (!newPassword.match(paswd)) {
          dispatch({type:'ERROR_LOG', payload: 'validatePassword'})
          dispatch({type:'ERROR_MESSAGE', payload: 'Password Between 6 to 20 Characters Which Contains At Least One Lowercase Letter, One Uppercase Letter, One Numeric Digit, And One Special Character'})
          setTimeout(() => {
              dispatch({type:'ERROR_MESSAGE', payload: ''})
              dispatch({type:'ERROR_LOG', payload: ''})
          }, 2000);
        } else {   
            const user = {
              oldPassword: oldPassword,
              newPassword: newPassword,
            }
            await dispatch(editPassword(user, token, async (result) => {
              if (result.message === 'create new password succsesfully') {
                await dispatch(getAllNotification(token))
                handleCancel()
                dispatch({type:'ERROR_LOG', payload: 'success'})
                dispatch({type:'ERROR_MESSAGE', payload: result.message})
                setTimeout(() => {
                    dispatch({type:'ERROR_MESSAGE', payload: ''})
                    dispatch({type:'ERROR_LOG', payload: ''})
                }, 2000);
              }
            }))
        }  
      } else {
        dispatch({type:'ERROR_LOG', payload: 'passwordNotSame'})
        dispatch({type:'ERROR_MESSAGE', payload: 'Passwords Are Not The Same'})
        setTimeout(() => {
            dispatch({type:'ERROR_MESSAGE', payload: ''})
            dispatch({type:'ERROR_LOG', payload: ''})
        }, 2000);
      }
    } else {
      dispatch({type:'ERROR_LOG', payload: 'validateFormRegisterTrial'})
      dispatch({type:'ERROR_MESSAGE', payload: 'Please Input All Form'})
      setTimeout(() => {
          dispatch({type:'ERROR_MESSAGE', payload: ''})
          dispatch({type:'ERROR_LOG', payload: ''})
      }, 2000);
    }
  }

  useEffect(()=> {
    const docBody = document.querySelector("body");
    docBody.classList.remove("green-bg");
    docBody.classList.add("yellow-bg");
    dispatch(getAllNotification(token))
    if (userDetail) {
      setUser(userDetail)
      setSelectedImage(userDetail.profileImage)
    } else {
      dispatch(detailUser(token, (result)=>{
        setUser(result)
        localStorage.setItem('userDetail', JSON.stringify(result))
        if (result === 'invalid token') {
          localStorage.removeItem('userDetail')
          localStorage.removeItem('id_token')
          localStorage.removeItem('input_button')
          navigate('/')
        }
      }))
    }
  },[])

  return (
    <div className="min-h-screen flex justify-center items-center">
      <PopupWindow/>
      <div className="py-10">
        <div className="grid justify-items-center items-center h-screen md:py-0 ">
          <div className="h-auto min-h-[839px] min-w-[906px] bg-white flex gap-0 flex-col items-center rounded-2xl overflow-hidden">
            <div className="flex gap-6 items-center w-full h-auto bg-green-1 px-10 py-6">
              {
                selectedImage ?
                <>
                    <img
                    src={selectedImage}
                    className="h-28 w-28 object-contain bg-grey-1000 border-4 border-white rounded-xl"
                    alt="Thumb"
                    />
                </>
                :
                <img alt="logo" src={PhotoDefault} className='h-28 w-28 bg-grey-1000 border-4 border-white rounded-xl'/>
              }
              <div className="h-36 w-1/6 flex flex-col gap-3 justify-center">
                <h1 className="text-2xl text-white max-w-[500px] w-max">{user.firstName ? user.firstName : ''} {user.lastName ? user.lastName : ''}</h1>
                {user.firstName?<h2 className="bg-yellow-300 text-green-1  text-center rounded-xl">Principal Trainer</h2>:''}
              </div>
            </div>
            <div className="flex gap-12 w-full h-auto min-h-[630px] bg-white p-12 divide-x" style={{color:'#F2F2F2'}}>
              <ProfileNav />

              <div className="pl-12 flex w-full flex-col gap-6 ">
                <form onSubmit={e=>handleSubmit(e)} className="flex flex-col items-start p-5 gap-[15px] border border-lightgrey-100 rounded-[10px]">
                  <div className="flex flex-row item-center gap-[15px]">
                    <p className="font-gilroyBold font-bold text-xl flex item-center tracking-wide text-green-1">Password &amp; Security</p>
                  </div>
                  <p className="text-grey-3 font-medium text-base flex item-center tracking-wide">Please enter your current password to change your password</p>
                  <div className='relative mb-5 w-full'>
                    <div className="w-full">
                      <input value={oldPassword} onChange={e=>{setOldPassword(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded text-black py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="Current Password" />
                      <div className="absolute inset-y-0 right-3 pr-3 flex items-center text-sm">
                          {
                              showPassword?
                              <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={showPw} alt="showPw" />
                              :
                              <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={hidePw} alt="hidePw" />
                          }
                      </div>
                    </div>
                  </div>       
                  <div className='relative mb-5 w-full'>
                    <div className="w-full">
                      <input value={newPassword} onChange={e=>{setNewPassword(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded text-black py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="New Password" />
                    </div>
                  </div>
                  <div className='relative mb-5 w-full'>
                    <div className="w-full">
                      <input value={passwordConfirm} onChange={e=>{setPasswordConfirm(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded text-black py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="Confirm Password" />
                    </div>
                  </div>
                  <div className="flex flex-row items-start gap-[15px] w-full">
                    <button
                        className={`flex justify-center justify-items-center items-center rounded-[10px] h-[49px] bg-green-1 hover:bg-green-2 text-yellow-300 font-semibold w-3/6`}
                        type='submit'
                      >
                        <span className="ml-2 tracking-wide font-semibold text-base">Update Password</span>
                    </button>
                    <button
                      onClick={()=>handleCancel()}
                      className="flex justify-center justify-items-center items-center rounded-[5px] px-8 h-[49px] font-semibold border-[2px] border-green-1 hover:bg-lightgrey-50 w-3/6" type="button"
                    >
                      <span className="ml-2 tracking-wide font-semibold text-base text-green-1">Cancel</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
