import axios from "axios";

export const getAllPlan = (loading = true, callback) => {
    return function (dispatch) {
        if (loading) dispatch({type:'LOADING', payload: true})        
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_URL_BE}/plan`,
            headers: { 
                'Content-Type': 'application/json',
            },
        };
        axios(config)
        .then(({data}) => {
            dispatch({type:'ALL_PLAN', payload: data})
            if (loading) dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            if (loading) dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'getAllPlan'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const getDetailPlan = (token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const config = {
            method: 'get',
            url: `${process.env.REACT_APP_URL_BE}/plan/user`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
        };
        axios(config)
        .then(({data}) => {
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            // dispatch({type:'ERROR_LOG', payload: 'getDetailPlan'})
            // dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            // setTimeout(() => {
            //     dispatch({type:'ERROR_MESSAGE', payload: ''})
            //     dispatch({type:'ERROR_LOG', payload: ''})
            //     callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            // }, 2000);
        });
    }
}

export const registerUserPlanSendOtp = (user, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const data = JSON.stringify({
            'firstName': user.firstname,
            'lastName': user.lastname,
            'email': user.email,
            'password': user.password,
            'planCode': user.planCode
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_URL_BE}/user/register/paid`,
            headers: { 
                'Content-Type': 'application/json',
            },
            data: data
        };
        axios(config)
        .then(({data}) => {
            dispatch({type:'EMAIL_VERIFY', payload: data.email})
            dispatch({type:'LOADING', payload: false})
            callback(data.message)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'registerUserPlanSendOtp'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const updateAndGetSecret = (user, token, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const data = JSON.stringify({
            'planCode': user.planCode,
        });

        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_URL_BE}/payment/secret`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
            data: data
        };
        axios(config)
        .then((result) => {
            dispatch({type:'USER_SECRET_PAYMENT', payload: result.data})
            dispatch({type:'LOADING', payload: false})
            callback(result)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            dispatch({type:'ERROR_LOG', payload: 'updateAndGetSecret'})
            dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
                callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            }, 2000);
        });
    }
}

export const getPlanPaypal = (token, code, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const data = JSON.stringify({
            'planCode': code,
        });
        const config = {
            method: 'post',
            url: `${process.env.REACT_APP_URL_BE}/payment/paypal/create`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
            data:data
        };
        axios(config)
        .then(({data}) => {
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch((error) => {
            dispatch({type:'LOADING', payload: false})
            callback(error)
            // dispatch({type:'ERROR_LOG', payload: 'getDetailPlan'})
            // dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            // setTimeout(() => {
            //     dispatch({type:'ERROR_MESSAGE', payload: ''})
            //     dispatch({type:'ERROR_LOG', payload: ''})
            //     callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            // }, 2000);
        });
    }
}

export const updateApplePay = (token, client_secret, callback) => {
    return function (dispatch) {
        dispatch({type:'LOADING', payload: true})
        const data = JSON.stringify({
            'client_secret': client_secret,
        });
        const config = {
            method: 'put',
            url: `${process.env.REACT_APP_URL_BE}/payment/applepay`,
            headers: { 
                'Content-Type': 'application/json',
                'token': token
            },
            data:data
        };
        axios(config)
        .then(({data}) => {
            dispatch({type:'LOADING', payload: false})
            callback(data)
        }).catch(({response}) => {
            dispatch({type:'LOADING', payload: false})
            // dispatch({type:'ERROR_LOG', payload: 'getDetailPlan'})
            // dispatch({type:'ERROR_MESSAGE', payload: response.data.errors.message ? response.data.errors.message : response.data.errors})
            // setTimeout(() => {
            //     dispatch({type:'ERROR_MESSAGE', payload: ''})
            //     dispatch({type:'ERROR_LOG', payload: ''})
            //     callback(response.data.errors.message ? response.data.errors.message : response.data.errors)
            // }, 2000);
        });
    }
}