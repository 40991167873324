const initialState = {
    errorLog: '',
    errorMessage: '',
    emailVerify: ''
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ERROR_LOG":
        return { ...state, errorLog: action.payload };
      case "ERROR_MESSAGE":
        return { ...state, errorMessage: action.payload };
      case "EMAIL_VERIFY":
        return { ...state, emailVerify: action.payload };
      default:
        return state;
    }
  };
  
  export default authReducer;
  