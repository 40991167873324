import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { alphabet1, alphabet2, phonic } from "../../../constant";
import DraggableBox from "../../common/Draggable";
import DragableColor from "./DragableColor"
import Heart from "../../../assets/heart.png"
import HeartGame from "../../../assets/heart-game.png"
import Draggable from "react-draggable"
import clsx from "clsx";

export default function BasicPanel() {  
  const dispatch = useDispatch();
  const gameEvent = useSelector((state)=>state.globalReducer.gameEvent)
  const finalBlendShow = useSelector((state) => state.globalReducer.finalBlendShow);
  const featureDragable = useSelector((state) => state.globalReducer.featureDragable);

  const [tempAlphabet1, setTempAlphabet1] = useState(alphabet1)
  const [tempPhonic, setTempPhonic] = useState(phonic)
  const [dragImg, setDragImg] = useState(false)

  const handleShowFinalBlend = () => {
    dispatch({ type: "FINAL_BLEND_SHOW", payload: !finalBlendShow });
  };

  const addNewItem = (e, word, type, tempId=false) => {
    if (!tempId) {
      dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
      const isIdTemp = (element) => element.id === "tempId";
      const isIdTes = (element) => element.id === "tesId";
      if (gameEvent.findIndex(isIdTemp) !== -1) gameEvent.splice(gameEvent.findIndex(isIdTemp), 1);
      if (gameEvent.findIndex(isIdTes) !== -1) gameEvent.splice(gameEvent.findIndex(isIdTes), 1);
      const vokal = ["a", "i", "u", "e", "o"];
      let IdIncrement = 0;
      if (gameEvent.length !== 0) IdIncrement = gameEvent[gameEvent.length-1].id;
      if (vokal.includes(word)) {
        let styleBorderNText = 'border-red text-red'
        if (e.changedTouches) {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-80, y:e.changedTouches['0'].pageY-320, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        } else {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-80, y:e.pageY-320, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        }
        dispatch({ type: "REDO_EVENT", payload: []})
      } else {
        let styleBorderNText = 'border-black text-black'
        if (e.changedTouches) {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-80, y:e.changedTouches['0'].pageY-320, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        } else {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-80, y:e.pageY-320, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        }
        dispatch({ type: "REDO_EVENT", payload: []})
      }
    } else {
      const checkTile = gameEvent.find(({ id }) => id === "tempId");
      const checkTileTes = gameEvent.find(({ id }) => id === "tesId");
      if (checkTile || checkTileTes) {
        const tempObj = checkTile
        const isIdTemp = (element) => element.id === "tempId";
        gameEvent.splice(gameEvent.findIndex(isIdTemp), 1);
        if (gameEvent.findIndex(isIdTemp) === -1) {
          if (e.changedTouches) {
            tempObj['x'] = e.changedTouches['0'].pageX-80
            tempObj['y'] = e.changedTouches['0'].pageY-320
            tempObj['id'] = 'tesId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          } else {
            tempObj['x'] = e.pageX-80
            tempObj['y'] = e.pageY-320
            tempObj['id'] = 'tesId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          }
        } else {
          const isIdTes = (element) => element.id === "tesId";
          gameEvent.splice(gameEvent.findIndex(isIdTes), 1);
          if (e.changedTouches) {
            tempObj['x'] = e.changedTouches['0'].pageX-80
            tempObj['y'] = e.changedTouches['0'].pageY-320
            tempObj['id'] = 'tempId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          } else {
            tempObj['x'] = e.pageX-80
            tempObj['y'] = e.pageY-320
            tempObj['id'] = 'tempId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          }
        }
      } else {
        dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
        const vokal = ["a", "i", "u", "e", "o"];
        let IdIncrement = 0;
        if (gameEvent.length !== 0) IdIncrement = gameEvent[gameEvent.length-1].id;
        if (vokal.includes(word)) {
          let styleBorderNText = 'border-red text-red'
          if (e.changedTouches) {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-80, y:e.changedTouches['0'].pageY-320, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          } else {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-80, y:e.pageY-320, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          }
          dispatch({ type: "REDO_EVENT", payload: []})
        } else {
          let styleBorderNText = 'border-black text-black'
          if (e.changedTouches) {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-80, y:e.changedTouches['0'].pageY-320, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          } else {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-80, y:e.pageY-320, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          }
          dispatch({ type: "REDO_EVENT", payload: []})
        }
      }
    }
  }

  const handleSetAlfabet = () => {
    setTempAlphabet1(alphabet1)
    setTempPhonic(phonic)
  }

  const handleDrag = async (e) => {
    setDragImg(true);;
    await addNewItem(e, 'heart', 'image', 'tempId');
    handleSetAlfabet();
  }
  
  return (
    <>
      <div className="flex flex-col gap-1">
        <div className="flex flex-col gap-2 p-[9px] w-full rounded-2xl shadow-[inset_2px_2px_0px_rgba(0,_0,_0,_0.1);] bg-lightgrey-150 ">
          <div className="flex flex-col w-3xl min-w-fit max-w-3xl gap-[0.5rem]">
            <div className="flex gap-[0.5rem] w-3xl min-w-fit">
              {tempAlphabet1.map((item, index) => (
                  <DraggableBox word={item} key={index} addNewItem={addNewItem} handleSetAlfabet={handleSetAlfabet} typeWord={'alfabet'}/>
              ))}
            </div>

            <div className="flex gap-[0.5rem] w-3xl min-w-fit">
              {alphabet2.map((item, index) => (
                  <DraggableBox word={item} key={index} addNewItem={addNewItem} handleSetAlfabet={handleSetAlfabet} typeWord={'alfabet'}/>
              ))}
            </div>
          </div>
        </div>
        <button
          onClick={() => handleShowFinalBlend()}
          className=" shadow-[0px_4px_0px_#EBEBEB,_inset_0px_6px_0px_rgba(255,_255,_255,_0.15)] w-fit px-8 maxXl:px-5 p-[13px] maxXl:p-[8px] bg-green-1 rounded-[10px] text-xs maxXl:text-[10px] font-bold text-yellow-300  hover:bg-green-2 border-[3px] border-white shadowText"
        >
          Initial and Final Blends
        </button>
      </div>

      <div className="flex flex-col gap-1">
        <div className="flex flex-col gap-2 p-[9px] w-full rounded-2xl shadow-[inset_2px_2px_0px_rgba(0,_0,_0,_0.1);] bg-lightgrey-150 ">
          <div className="flex flex-col w-3xl min-w-fit max-w-3xl gap-[0.5rem]">
            {phonic.map((item, index) => (
              <div key={index} className="flex gap-[0.5rem] w-3xl min-w-fit ml-auto">
                {item.type === "text"
                  ? item.content.map((el, j) => (
                        <DraggableBox word={el} key={j} addNewItem={addNewItem} handleSetAlfabet={handleSetAlfabet} typeWord={'alfabet'}/>
                    ))
                  : 
                  <>
                    <div className="flex">
                      <img draggable="false" className="w-[50px] maxXl:w-[40px] h-[45px] maxXl:h-[35px] max-w-fit cursor-pointer" src={Heart} alt="heart" />
                      <Draggable  position={dragImg ? { x: -70, y: -30 } : { x: 0, y: 0 }} grid={featureDragable.snap ? [50, 50] : ''} onStop={(e)=>{addNewItem(e, 'heart', 'image');setDragImg(false);}} onDrag={(e)=>{handleDrag(e)}} className="z-50">
                        <img draggable="false"  className={clsx(dragImg ? "hidden fixed cursor-pointer pt-2 fix w-[140.17px] h-[116px] z-50" : "fixed w-[50px] maxXl:w-[40px] h-[45px] maxXl:h-[35px] cursor-pointer")} src={dragImg?HeartGame:Heart} alt="heartGame" /></Draggable>
                    </div>
                    {item.content.map((color, k) => {
                        return (
                          <DragableColor color={color} key={k} addNewItem={addNewItem} handleSetAlfabet={handleSetAlfabet}/>
                        );
                      })}
                  </>
                    }
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
