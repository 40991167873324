import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import PopupWindow from "../../components/PopupWindow";
import ProfileNav from "../../components/profile/ProfileNav";
import { editUser, detailUser } from "../../redux/actions/User";
import {useNavigate} from 'react-router-dom'
import PhotoDefault from "../../assets/photo.png";
import EditPhoto from "../../assets/edit-photo.svg";
import { getAllNotification } from "../../redux/actions/Notification";
export default function Profile() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = localStorage.getItem('id_token');
  let userDetail = localStorage.getItem('userDetail')
  userDetail = JSON.parse(userDetail)
  
  
  const [user, setUser] = useState({})
  const [name, setName] = useState({})
  const [selectedImage, setSelectedImage] = useState('');
  const loading = useSelector((state) => state.globalReducer.loading);
  
  const submitEditProfile = (e) => {
    e.preventDefault()
    const userId = userDetail._id
    dispatch(editUser(user, selectedImage, token, userId,  (result) => {
      if (result.message === 'edit succsesfully') {
        dispatch(detailUser(token, (result)=>{
          setUser(result)
          setName(result)
          setSelectedImage(result.profileImage)
          localStorage.setItem('userDetail', JSON.stringify(result))
        }))
      } else if (result === 'invalid token') {
        localStorage.removeItem('userDetail')
        localStorage.removeItem('id_token')
        localStorage.removeItem('input_button')
        navigate('/')
      }
    }))
  }

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
        const base64 = await convertBase64(e.target.files[0])
        setSelectedImage(base64);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  useEffect(()=> {
    const docBody = document.querySelector("body");
    docBody.classList.remove("green-bg");
    docBody.classList.add("yellow-bg");
    dispatch(getAllNotification(token))
    if (userDetail) {
      setUser(userDetail)
      setName(userDetail)
      setSelectedImage(userDetail.profileImage)
    } else {
      dispatch(detailUser(token, (result)=>{
        setUser(result)
        setName(result)
        setSelectedImage(result.profileImage)
        localStorage.setItem('userDetail', JSON.stringify(result))
        if (result === 'invalid token') {
          localStorage.removeItem('userDetail')
          localStorage.removeItem('id_token')
          localStorage.removeItem('input_button')
          navigate('/')
        }
      }))
    }
  },[])

  return (
    <div className="min-h-screen flex justify-center items-center">
      <PopupWindow/>
      <div className="py-10">
        <div className="grid justify-items-center items-center h-screen md:py-0 ">
          <div className="h-auto min-h-[839px] min-w-[906px] bg-white flex gap-0 flex-col items-center rounded-2xl overflow-hidden">
            <div className="flex gap-6 items-center w-full h-auto bg-green-1 px-10 py-6">
              {
                selectedImage ?
                <>
                    <img
                    src={selectedImage}
                    className="h-28 w-28 object-contain bg-grey-1000 border-4 border-white rounded-xl"
                    alt="Thumb"
                    />
                </>
                :
                <img alt="logo" src={PhotoDefault} className='h-28 w-28 bg-grey-1000 border-4 border-white rounded-xl'/>
              }
              <div className="h-36 w-1/6 flex flex-col gap-3 justify-center">
                <h1 className="text-2xl text-white max-w-[500px] w-max">{name.firstName ? name.firstName : ''} {name.lastName ? name.lastName : ''}</h1>
                {name.firstName?<h2 className="bg-yellow-300 text-green-1  text-center rounded-xl">Principal Trainer</h2>:''}
              </div>
            </div>
            <div className="flex gap-12 w-full h-auto min-h-[630px] bg-white p-12 divide-x" style={{color:'#F2F2F2'}}>
              <ProfileNav />
              <form onSubmit={e=>{submitEditProfile(e)}} className="pl-12 flex w-full flex-col gap-6 ">
                <div className="relative w-fit">
                  <label className='cursor-pointer upload-image w-full h-full'>
                    <input
                        type="file"
                        accept='image/*'
                        onChange={imageChange}
                        hidden
                    />
                  </label>
                  {
                      selectedImage ?
                      <>
                          <img
                          src={selectedImage}
                          className="h-28 w-28 object-contain bg-grey-1000 rounded-[15px] border-white"
                          alt="Thumb"
                          />
                      </>
                      :
                      <img alt="logo" src={PhotoDefault} className='h-28 w-28 bg-grey-1000 rounded-[15px] border-white'/>
                  }
                  <img alt="logo" src={EditPhoto} className='h-5 w-5 absolute -bottom-1 -right-2'/>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="flex gap-12">
                    <input
                      type="text"
                      id="first_name"
                      value={user.firstName}
                      onChange={e=>setUser({...user, firstName: e.target.value})}
                      className="bg-[#F9F9F9] border border-[#E0E0E0] text-black text-base rounded-lg block w-full p-2.5"
                      placeholder="First Name"
                      required
                    />

                    <input
                      type="text"
                      id="last_name"
                      value={user.lastName}
                      onChange={e=>setUser({...user, lastName: e.target.value})}
                      className="bg-[#F9F9F9] border border-[#E0E0E0] text-black text-base rounded-lg block w-full p-2.5"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                  <input
                    type="text"
                    id="username"
                    value={user.username}
                    onChange={e=>setUser({...user, username: e.target.value})}
                    className="bg-[#F9F9F9] border border-[#E0E0E0] text-black text-base rounded-lg block w-full p-2.5"
                    placeholder="Username"
                    required
                  />
                  <input
                    type="text"
                    id="email"
                    value={user.email}
                    disabled
                    className="bg-[#F9F9F9] border border-[#E0E0E0] text-grey-3 cursor-not-allowed text-base rounded-lg block w-full p-2.5"
                    placeholder="Email Address"
                    required
                  />

                  <input
                    type="number"
                    id="phone_number"
                    value={user.phoneNumber}
                    onChange={e=>setUser({...user, phoneNumber: e.target.value})}
                    className="bg-[#F9F9F9] border border-[#E0E0E0] text-black text-base rounded-lg block w-full p-2.5"
                    placeholder="Phone Number"
                    required
                  />

                  <input
                    type="text"
                    id="address"
                    value={user.address}
                    onChange={e=>setUser({...user, address: e.target.value})}
                    className="bg-[#F9F9F9] border border-[#E0E0E0] text-black text-base rounded-lg block w-full p-2.5"
                    placeholder="Address"
                    required
                  />
                  <button
                      className={`flex shadow-[inset_0px_6px_0px_rgba(255,_255,_255,_0.15)] justify-center justify-items-center items-center rounded-[10px] w-[110px] h-[45px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold`}
                      type='submit'
                    >
                      <span className="ml-2 tracking-[0.08rem] font-bold text-lg">Save</span>
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
