import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbar";
import {Link, useNavigate, useParams} from "react-router-dom";

import showPw from "../assets/show-pw.svg"
import hidePw from "../assets/hide-pw.svg"
import { registerUserPlanSendOtp } from "../redux/actions/Plan";
import { useDispatch } from "react-redux";
import PopupWindow from "../components/PopupWindow";

export default function SignUpSelectPlanPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const param = useParams()
  const [showPassword, setShowPassword] = useState(false)

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [termConditionActive, setTermConditionActive] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (firstname !== '' && lastname !== '' && email !== '' && password !== '' && passwordConfirm !== '') {
      if (password === passwordConfirm) {
        const paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;
        if (!password.match(paswd)) {
          dispatch({type:'ERROR_LOG', payload: 'validatePassword'})
          dispatch({type:'ERROR_MESSAGE', payload: 'Password Between 6 to 20 Characters Which Contains At Least One Lowercase Letter, One Uppercase Letter, One Numeric Digit, And One Special Character'})
          setTimeout(() => {
              dispatch({type:'ERROR_MESSAGE', payload: ''})
              dispatch({type:'ERROR_LOG', payload: ''})
          }, 2000);
        } else {   
          if (!termConditionActive) {
            dispatch({type:'ERROR_LOG', payload: 'termConditionActive'})
            dispatch({type:'ERROR_MESSAGE', payload: 'Please Accept Term Condition'})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);    
          } else {
            const user = {
              firstname: firstname,
              lastname: lastname,
              email: email,
              password: password,
              passwordConfirm: passwordConfirm,
              planCode: param.code
            }
            console.log(user);
            submitRegisterForm(user)
          }
        }  
      } else {
        dispatch({type:'ERROR_LOG', payload: 'passwordNotSame'})
        dispatch({type:'ERROR_MESSAGE', payload: 'Passwords Are Not The Same'})
        setTimeout(() => {
            dispatch({type:'ERROR_MESSAGE', payload: ''})
            dispatch({type:'ERROR_LOG', payload: ''})
        }, 2000);
      }
    } else {
      dispatch({type:'ERROR_LOG', payload: 'validateFormRegisterTrial'})
      dispatch({type:'ERROR_MESSAGE', payload: 'Please Input All Form'})
      setTimeout(() => {
          dispatch({type:'ERROR_MESSAGE', payload: ''})
          dispatch({type:'ERROR_LOG', payload: ''})
      }, 2000);
    }
  }

  const submitRegisterForm = async (user) => {
    await dispatch(registerUserPlanSendOtp(user, (result)=>{
      if (result === 'email is not verified,please resend token !' || result === 'A verification email has been sent') {
        navigate(`/register/verify-email/${user.email}`)
      }
    }))
  }
  useEffect(() => {
    const docBody = document.querySelector("body");
    docBody.classList.remove("green-bg");
    docBody.classList.add("yellow-bg");
  }, [])
  return (
    <div>
      <Navbar signUp={false} signIn={true}/>
      <div className="grid justify-items-center items-center min-h-screen">
        <PopupWindow/>
        <div className="p-14 w-4/5 max-w-[1095px]">
          <div className="min-h-[743px] max-w-[1095px] h-auto bg-white flex gap-6 flex-col items-start md:p-10 px-6 py-6 rounded-2xl">
          <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0" id="tabs-tab"
            role="tablist">
            <li className="nav-item" role="presentation">
              <a href="#tabs-signup" className="nav-link block font-mediu uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent focus:border-transparent text-lg font-gilroyBold active" id="tabs-signup-tab" data-bs-toggle="pill" data-bs-target="#tabs-signup" role="tab" aria-controls="tabs-signup"
                aria-selected="true">SIGN UP</a>
            </li>
            <li className="nav-item cursor-not-allowed" role="presentation">
              <div className="nav-link block uppercase border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent focus:border-transparent text-lg font-gilroyBold">PAYMENT METHOD</div>
            </li>
          </ul>
          <div className="tab-content w-full" id="tabs-tabContent">
            <div className="tab-pane fade show active" id="tabs-signup" role="tabpanel" aria-labelledby="tabs-signup-tab">
              <div className="flex flex-col items-center pb-10">
                <h1 className="text-[40px] text-green-1 font-bold tracking-[0.05rem]">Sign Up</h1>
                {/* form */}
                <form onSubmit={e=>{handleSubmit(e)}} className="w-full max-w-lg">
                  <div className="flex flex-wrap relative mt-8">
                      <div className="w-full md:w-1/2 px-3 md:mb-0 mb-5">
                        <input onChange={e=>{setFirstname(e.target.value)}} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="First Name" />
                      </div>
                      <div className="w-full md:w-1/2 px-3 mb-5">
                        <input onChange={e=>{setLastname(e.target.value)}} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-last-name" type="text" placeholder="Last Name" />
                      </div>
                  </div>
                  <div className="relative mb-5">
                      <div className="w-full px-3">
                        <input onChange={e=>{setEmail(e.target.value)}} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" type="email" placeholder="Email Address" />
                      </div>
                  </div>
                  <div className='relative mb-5'>
                    <div className="w-full px-3">
                      <input onChange={e=>{setPassword(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="Password" />
                      <div className="absolute inset-y-0 right-3 pr-3 flex items-center text-sm">
                          {
                              showPassword?
                              <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={showPw} alt="showPw" />
                              :
                              <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={hidePw} alt="hidePw" />
                          }
                      </div>
                    </div>
                  </div>       
                  <div className='relative mb-5'>
                    <div className="w-full px-3">
                      <input onChange={e=>{setPasswordConfirm(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="Confirm Password" />
                    </div>
                  </div>
                  <div className="relative mb-5">
                      <div className="w-full px-3">
                        <div className="form-check flex">
                          <input checked={termConditionActive} onChange={()=>setTermConditionActive(!termConditionActive)} className="form-check-input h-4 w-4 border accent-green-1 border-lightgrey-150 rounded-sm bg-grey-1100 checked:bg-green-1 checked:border-green-1 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" id="flexCheckDefault"/>
                          <label className="form-check-label inline-block text-grey-3" htmlFor="flexCheckDefault">
                            <p className='text-grey-3'>I agree to all the</p>
                          </label>
                          <span className='text-blue cursor-pointer font-gilroyBold ml-1' data-bs-toggle="modal" data-bs-target="#exampleModal">Term &amp; Privacy Policy</span>
                        </div>        
                      </div>
                  </div>
                  <button
                    className="flex shadow-[0px_4px_0px_#e9bf45,inset_0px_3px_0px_rgb(255_255_255_/_15%)] justify-center justify-items-center items-center rounded-[10px] w-[97%] h-[62px] mt-[17.32px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold border-[3px] border-yellow hover:border-yellow mx-[8px]"
                  >
                    <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">Sign Up Now</span>
                  </button>
                  <p className="text-grey-3 w-full text-center mt-5">Have an Account? <Link to="/login" className="text-blue cursor-pointer font-gilroyBold">Sign in now</Link></p>
                </form>        
                <div className="modal fade fixed top-0 left-0 hidden w-[99%] h-full outline-none overflow-x-hidden overflow-y-auto"
                  id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog min-w-[648px] relative w-auto pointer-events-none">
                    <div className="modal-content border-none relative flex flex-col min-w-[648px] pointer-events-auto bg-white bg-clip-padding rounded-xl outline-none text-current p-8 shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14)]">
                      <div className="modal-body relative px-8 py-4 w-auto">
                        <p className='text-grey-2 text-base font-gilroyMedium tracking-wide'>AGREEMENT</p>
                        <p className='font-gilroyBold text-3xl font-bold tracking-wide text-green-1 mb-5'>Terms of Service</p>
                        <div className='max-h-[406px] pr-3 overflow-y-auto text-grey-2 tracking-wide font-normal font-gilroyMedium text-sm'>
                          <p className="font-gilroyExtrabold">Last Updated: July 8, 2022.</p>
                          <p>Welcome to the Edwards Orton-Gillingham. Please read these Terms of Service (the “Terms”) carefully because they govern your use of our websites; our mobile and desktop applications (“Apps”), our application program interfaces (“APIs”), our software development kits (“SDKs”) and our collaboration tools and other products and services we may later own or operate (collectively, with the Sites, Apps, APIs, and SDKs, the “Services”).</p>
                          <br/>
                          <p className="font-gilroyExtrabold">1. Agreement to these Terms</p>
                          <p>By using the Services, you agree to be bound by these Terms. If you don’t agree to these Terms, do not use the Services. If you are accessing and using the Services on behalf of a company (such as your employer) or other legal entity, you represent and warrant that you have the authority to bind that company or other legal entity to these Terms. In that case, “you” and “your” will refer to that company or other legal entity.</p>
                          <br/>
                          <p className="font-gilroyExtrabold">2. Changes to the Terms or Services</p>
                          <p>We may modify the Terms at any time, at our sole discretion. If we do so, we will let you know either by posting the modified Terms on the Site or through other communications. If you continue to use the Services after such a change, you are indicating that you agree to the modified Terms. We may also change or discontinue all or any part of the Services, at any time and without notice, at our sole discretion.</p>
                          <br/>
                          <p className="font-gilroyExtrabold">3. Service Terms</p>
                          <p>3.1. Who may use the Services. You may only use the Services if you are old enough to consent (by yourself and not by a parent or guardian) to share your data under applicable law. For example, you must be 13 years or older under United States law, or 16 years or older under European Union law.</p>
                          <br/>
                          <p>3.2. Use Restrictions. Except as otherwise expressly authorized in these Terms, you will not, and will ensure your employees, contractors, and other persons associated with your Edwards Orton-Gillingham account (“Authorized Users”) do not, and will not encourage or assist third parties to: (i) reverse engineer, decompile, disassemble, or otherwise attempt to discover the source code, object code, or underlying structure, ideas, know-how, or algorithms relevant to the Services (except to the extent that such a restriction is impermissible under applicable law); (ii) provide, sell, resell, transfer, sublicense, lend, distribute, rent, or otherwise allow others to access or use the Services; and (iii) copy, modify, create derivative works of, or remove proprietary notices from the Services.</p>
                          <br/>
                          <p>3.3. Acceptable Use Policy. You will comply with, and will ensure your Authorized Users comply with, Edwards Orton-Gillingham’s Acceptable Use Policy.</p>
                          <br/>
                          <p>3.4. Authorized Users; Accounts. As part of the registration process, you will identify an administrative username and password for your Edwards Orton-Gillingham account. You represent and warrant that all registration information, including with respect to the list of domains owned or controlled by you for purposes of domain capture, you provide is truthful, accurate, and complete, and that you will maintain the accuracy of such information. You are responsible and liable for maintaining control over your account, including the confidentiality of your username and password, and are solely responsible and liable for all activities that occur on or through your account and all Authorized Users’ accounts, whether authorized by you or not.</p>
                          <br/>
                          <p>3.5. Feedback; Use Rights. We welcome feedback, comments, and suggestions (“Feedback”). As we need to be able to freely work with your Feedback to improve the Services, you hereby irrevocably transfer and assign all right, title, and interest (including all intellectual property rights, such as copyrights or trade secrets) in and to the Feedback, including any and all “moral rights” that you might have in such Feedback, and you hereby forever waive and agree never to assert any and all “moral rights” you may have in the Feedback. Additionally, Edwards Orton-Gillingham will have the right to collect and analyze data and other information relating to the provision, use, and performance of various aspects of the Services, and related systems and technologies, and Edwards Orton-Gillingham will be free to use such data and information to maintain, improve, and enhance Edwards Orton-Gillingham’s products and services.</p>
                          <br/>
                          <p>3.6. Reservation of Rights. As between the parties, Edwards Orton-Gillingham owns all right, title, and interest in the Services, and you own all right, title, and interest in any application(s) and/or material(s) that are developed by you on the Services or uploaded to the Services by you (“User Content”). Except as expressly set forth in these Terms, each party retains all right, title, and interest in and to its intellectual property rights. All rights not expressly granted are reserved, and no license, covenant, immunity, transfer, authorization, or other right will be implied, by reason of statute, estoppel, or otherwise, under these Terms.</p>
                          <br/>
                          <p>3.7. Patent Assertion Entities. A “Patent Assertion Entity,” sometimes referred to as a ‘non-practicing entity’ or a ‘patent troll,’ is (a) any entity that derives or seeks to derive most of its revenue from the offensive assertion of patent rights, or (b) directly or indirectly controls, is controlled by, or is under common control with an entity described in (a). If you are a Patent Assertion Entity or are acting on behalf of, or for the benefit of a Patent Assertion Entity, you will not assert, or authorize, assist, encourage, or enable any third party to assert, any claim, or pursue any actions, suits, proceedings, or demands, against Edwards Orton-Gillingham or its affiliates that allege that the Services infringe, misappropriate, or otherwise violate any intellectual property rights (including patents). This section will survive any termination or expiration of these Terms.</p>
                          <br/>
                          <p className="font-gilroyExtrabold">4. License</p>
                          <p>Subject to the terms and conditions of these Terms, we hereby grant you a limited, non-exclusive, non-transferable, non-sublicensable license worldwide (with the exception of (i) jurisdictions that are embargoed or designated as supporting terrorist activities by the United States Government and (ii) jurisdictions whose laws do not permit engaging in business with Edwards Orton-Gillingham or use of the Services) for you to access and use the Services for your internal business or personal purposes, depending on your account type. If you have a free account you may use the Services for business or personal purposes, but paid accounts are for business use only, and may not be used for personal or other non-commercial purposes.</p>
                        </div>
                        <div className='flex justify-between mt-5'>
                          <button
                            onClick={()=>setTermConditionActive(true)}
                            className="flex shadow-[0px_4px_0px_#e9bf45,inset_0px_3px_0px_rgb(255_255_255_/_15%)] justify-center justify-items-center items-center rounded-[10px] w-fit px-8 h-[62px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold border-[3px] border-yellow-1000 hover:border-yellow-1000" data-bs-dismiss="modal" aria-label="Close"
                          >
                            <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">I agree with terms</span>
                          </button>
                          <button
                            onClick={()=>setTermConditionActive(false)}
                            className="flex shadow-[inset_0px_6px_0px_rgba(255,_255,_255,_0.15)] justify-center justify-items-center items-center rounded-[5px] w-fit px-8 h-[62px] font-bold border-[2px] border-green-1 hover:bg-lightgrey-50" data-bs-dismiss="modal" aria-label="Close"
                          >
                            <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText text-green-1">Not Right Now...</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>    
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}