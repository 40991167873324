import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import {useSelector, useDispatch} from 'react-redux'

import failPayment from '../../assets/payment/fail-payment.svg'
import successPayment from '../../assets/payment/success-payment.svg'

import {useParams} from 'react-router-dom'
export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://playground.edwardsog.com/game/select-plan/payment/success",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      setTimeout(() => {
        setMessage('')
      }, 4000);
    } else {
      setMessage("An unexpected error occurred.");
      setTimeout(() => {
        setMessage('')
      }, 4000);
    }

    setIsLoading(false);
  };

  return (
    <form className="w-full" id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      {
      message && <div className="flex flex-col items-start p-5 gap-2.5 bg-grey-1100 border-lightgrey-100 border rounded-[5px] mt-5">
      <div className="flex flex-row items-center justify-center gap-[5px] w-full">
        <img src={failPayment} alt='failPayment'/>
        <p className="font-gilroyBold font-bold text-base text-center text-red">{message}</p>
      </div>
      <p className="text-grey-3 font-gilroyMedium text-xs tracking-wide flex justify-center w-full">We aren’t able to process your payment. Please try again</p>
    </div>
      }
      <button disabled={isLoading || !stripe || !elements} className={`flex justify-center justify-items-center items-center rounded-[5px] w-full h-[45px] bg-green-1 hover:bg-green-2 text-yellow-1000 font-bold mt-5`}>
        {isLoading&&<div className="spinner-border animate-spin inline-block w-4 h-4 border-4 rounded-full" role="status"></div>}
        <span className="ml-2 tracking-[0.08rem] font-bold text-lg">Pay Now</span>
      </button>
    </form>
  );
}