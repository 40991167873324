import React, {useState} from "react";
import { Tab } from "@headlessui/react";

import PrefixPanel from "../../prefix/PrefixPanel";
import BasicPanel from "../../basic/BasicPanel";

import GluedPanel from "../../glued/GluedPanel";
import VowelPanel from "../../vowel/VowelPanel";
import CapitalPanel from "../../capital/CapitalPanel";
import SyllaboardsPanel from "../../syllaboards/SyllaboardsPanel";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ArrowUp from "../../../assets/ArrowUp.svg"
import ArrowDown from "../../../assets/ArrowDown.svg"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Tabs() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  const statusPage = useSelector((state) => state.globalReducer.statusPage);
  const [hide, setHide] = useState(false)
  const [show, setShow] = useState(false)
  let [categories] = useState([
    "Basic Tiles",
    "Glued Sounds",
    "Advanced Vowels",
    "Prefixes, Roots & Suffixes",
    "Capital Letters",
    "Syllable Labels",
  ]);

  return (
    <div className="w-full px-2 sm:px-0">
      <Tab.Group>
        <Tab.List className="flex space-x-2 shadow-[inset_2px_2px_0px_rgba(0,_0,_0,_0.1)] bg-[#f4f4f4] rounded-[12px] p-[5px]">
          {categories.map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-[10px] py-3.5 maxXl:py-2 text-base tracking-[0.05em] border-4 flex justify-center",
                  "focus:outline-none",
                  selected
                    ? "shadow-[inset_0px_4px_0px_rgba(255,_255,_255,_0.25)] bg-green-1 text-yellow-300 shadowText border-white font-extrabold"
                    : "text-grey-2 font-semibold bg-[#e0e0e0] border-white",
                  category === "Prefixes, Roots & Suffixes" ? 'min-w-[12rem]' : 'min-w-[9rem]'
                )
              }
              onClick={(e) => {
                if(e.target.textContent.trim() !== "") {
                  if (e.target.textContent.trim() === statusPage) {
                    if (show) {
                      setHide(true)
                      setShow(false)
                    } else {
                      setHide(false)
                      setShow(true)
                    }
                  } else {
                    dispatch({ type:"STATUS_PAGE", payload: e.target.textContent.trim() });
                    dispatch({ type: "FINAL_BLEND_SHOW", payload: false });
                    dispatch({ type: "GLUED_SOUND", payload: false });
                  }
                }
              }}
            >
              <p className="m-0 w-full my-auto maxXl:text-[10px]">{category}</p> {statusPage === category ? <img onClick={()=>{if (show) {
                  setHide(true)
                  setShow(false)
                } else {
                  setHide(false)
                  setShow(true)
                }}} className={`ml-auto mr-3 my-auto w-6 maxXl:w-4`} src={hide?ArrowUp:ArrowDown} alt="ArrowUp"/> : ''}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-4 relative">
          {/* BASIC */}
          <Tab.Panel className={`w-full bg-white flex justify-between ${hide?'hidden':''}`}>
            <BasicPanel />
          </Tab.Panel>
          {/* GLUED SOUNDS */}
          <Tab.Panel className={`w-full bg-white flex justify-between ${hide?'hidden':''}`}>
              <GluedPanel />
              {
                user.planActivation?
                  user.planActivation.isActive ?
                  '' :
                  user.user ? 
                    user.user.planActivation.isActive ?
                      '' :
                      <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                        <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-16">
                          <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                        </div>
                      </div>
                  : 
                  <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                    <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-16">
                      <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                    </div>
                  </div>
                  :
                  user.user ? 
                    user.user.planActivation.isActive ?
                      '' :
                  <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                    <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-16">
                      <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                    </div>
                  </div>
                  :
                  <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                    <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-16">
                      <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                    </div>
                  </div>
              }
          </Tab.Panel>
          {/* ADVANCED VOWELS */}
          <Tab.Panel className={`w-full bg-white flex justify-center ${hide?'hidden':''}`}>
            <VowelPanel />
            {
                user.planActivation?
                user.planActivation.isActive ?
                '' :
                user.user ? 
                user.user.planActivation.isActive ?
                '' :
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-10">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div>
                : 
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-10">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div>
                :
                user.user ? 
                    user.user.planActivation.isActive ?
                      '' :
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-10">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div>
                :<div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-10">
                  <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                </div>
              </div>
              }
          </Tab.Panel>
          {/* PREFIEX & ROOT */}
          <Tab.Panel className={`w-full bg-white flex justify-between ${hide?'hidden':''}`}>
            <PrefixPanel />
            {
                user.planActivation?
                user.planActivation.isActive ?
                '' :
                user.user ? 
                user.user.planActivation.isActive ?
                '' :
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-16">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div>
                : 
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-16">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div>
                :
                user.user ? 
                user.user.planActivation.isActive ?
                '' :
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-16">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div>
                :<div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-16">
                  <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                </div>
              </div>
              }
          </Tab.Panel>
          {/* CAPITAL LATTERS */}
          <Tab.Panel className={`${hide?'hidden':''}`}>
            {
                user.planActivation?
                user.planActivation.isActive ?
                '' :
                user.user ? 
                user.user.planActivation.isActive ?
                '' :
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-10">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div>
                : 
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-10">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div>
                :
                user.user ? 
                user.user.planActivation.isActive ?
                '' :
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-10">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div> :
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-10">
                  <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                </div>
              </div>
              }
            <CapitalPanel />
          </Tab.Panel>
          {/* Syllaboards */}
          <Tab.Panel className={`${hide?'hidden':''}`}>
            {
                user.planActivation?
                user.planActivation.isActive ?
                '' :
                user.user ? 
                user.user.planActivation.isActive ?
                '' :
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-2">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div>
                : 
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-2">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div>
                :
                user.user ? 
                user.user.planActivation.isActive ?
                '' :
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-2">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div>
                :
                <div className="w-full h-full absolute rounded-xl bg-[#ffffffde] z-50">
                  <div className="flex flex-row item-start px-5 py-2.5 gap-2.5 bg-grey-2 w-fit rounded-md m-auto mt-2">
                    <p className="font-gilroyMedium text-lg tracking-wide text-white">Upgrade to Access All the Features of Blending Board. <Link to="select-plan"><button className="text-yellow-1000">Upgrade Now!</button></Link></p>
                  </div>
                </div>
              }
            <SyllaboardsPanel />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}
