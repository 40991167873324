import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileNav from "../../components/profile/ProfileNav";
import { detailUser } from "../../redux/actions/User";
import PhotoDefault from "../../assets/photo.png";
import PopupWindow from "../../components/PopupWindow";
import { getAllNotification, readAllNotification, readNotification } from "../../redux/actions/Notification";
import moment from 'moment'

export default function ProfileNotification() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = localStorage.getItem('id_token');
  let userDetail = localStorage.getItem('userDetail')
  userDetail = JSON.parse(userDetail)
  const AllNotification = useSelector((state) => state.globalReducer.AllNotification);

  const [user, setUser] = useState({})
  const [selectedImage, setSelectedImage] = useState('');
  
  useEffect(()=> {
    const docBody = document.querySelector("body");
    docBody.classList.remove("green-bg");
    docBody.classList.add("yellow-bg");
    dispatch(getAllNotification(token))
    if (userDetail) {
      setUser(userDetail)
      setSelectedImage(userDetail.profileImage)
    } else {
      dispatch(detailUser(token, (result)=>{
        setUser(result)
        localStorage.setItem('userDetail', JSON.stringify(result))
        if (result === 'invalid token') {
          localStorage.removeItem('userDetail')
          localStorage.removeItem('id_token')
          localStorage.removeItem('input_button')
          navigate('/')
        }
      }))
    }
  },[])

  return (
    <div className="min-h-screen flex justify-center items-center">
      <PopupWindow/>
      <div className="py-10">
        <div className="grid justify-items-center items-center h-screen md:py-0 ">
          <div className="h-auto min-h-[839px] min-w-[906px] bg-white flex gap-0 flex-col items-center rounded-2xl overflow-hidden">
            <div className="flex gap-6 items-center w-full h-auto bg-green-1 px-10 py-6">
              {
                selectedImage ?
                <>
                    <img
                    src={selectedImage}
                    className="h-28 w-28 object-contain bg-grey-1000 border-4 border-white rounded-xl"
                    alt="Thumb"
                    />
                </>
                :
                <img alt="logo" src={PhotoDefault} className='h-28 w-28 bg-grey-1000 border-4 border-white rounded-xl'/>
              }
              <div className="h-36 w-1/6 flex flex-col gap-3 justify-center">
                <h1 className="text-2xl text-white max-w-[500px] w-max">{user.firstName ? user.firstName : ''} {user.lastName ? user.lastName : ''}</h1>
                {user.firstName?<h2 className="bg-yellow-300 text-green-1  text-center rounded-xl">Principal Trainer</h2>:''}
              </div>
            </div>
            <div className="flex gap-12 w-full h-auto min-h-[630px] bg-white p-12 divide-x" style={{color:'#F2F2F2'}}>
              <ProfileNav />

              <div className="pl-12 flex w-full flex-col gap-6 ">
                <div className="flex flex-col items-start p-5 gap-[15px] border border-lightgrey-100 rounded-[10px]">
                  <div className="flex flex-row item-center w-full justify-between gap-[15px]">
                    <p className="font-gilroyBold font-bold text-xl flex item-center tracking-wide text-green-1">Notifications</p>
                    <p onClick={()=>dispatch(readAllNotification(token))} className="font-gilroyMedium font-semibold text-sm cursor-pointer flex item-end tracking-wide underline text-blue">Mark all as read</p>
                  </div>
                  <div className="flex flex-col items-start w-full max-h-[450px] overflow-auto">
                    {
                      AllNotification.length !== 0 ?
                      AllNotification.map((item)=>{
                        return(
                          <div onClick={()=>dispatch(readNotification(item._id, token))} key={item._id} className={`flex flex-row items-center px-2.5 py-[15px] gap-[15px] ${!item.read?'bg-[#ffd45626]':'bg-white'} hover:bg-grey-1100 cursor-pointer border-b w-full border-b-lightgrey-100 rounded-[5px]`}>
                            <div className="w-11 h-11 bg-yellow-1000 rounded-full"><img src={item.notif.icon} alt="icon" className="m-auto pt-3" width={20} /></div>
                            <div className="flex flex-col item-start gap-0.5">
                              <p className="font-gilroyMedium text-base font-semibold flex items-center tracking-wide text-grey-2">{`${item.notif.title} ${item.notif.code === '03' ? item.planUser : ""}`}</p>
                              <p className="font-gilroyMedium text-xs flex items-center tracking-wide text-lightgrey-700">{moment(item.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
                            </div>
                          </div>
                        )
                      }) : ''  
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
