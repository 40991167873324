import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'

// reducers
import globalReducer from './reducers/global'
import authReducer from './reducers/auth'
import userReducer from './reducers/user'
import adminReducer from './reducers/admin'

const reducers = combineReducers({
    globalReducer,
    authReducer,
    userReducer,
    adminReducer,
})

const store = createStore(reducers, compose(applyMiddleware(thunk)))
export default store