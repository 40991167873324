import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Vector36 from '../../assets/Vector36.svg'
import Draggable from "react-draggable"
import clsx from "clsx";
import InitialBlendDragable from "./InitialBlendDragable"

const alphabet1 = [
"bl","cl","fl","gl","pl","sl","spl"
];

const alphabet2 = [
"br","cr","dr","fr","gr","pr","tr","scr"
];

const alphabet3 = [
    "sk","sn","sm","sp","sw","tw","str"
];

const alphabet4 = [
    "nd","nt","mp","ft","st","ct"
];


export default function InitialBlend(props) {
  const dispatch = useDispatch()
  const gameEvent = useSelector((state)=>state.globalReducer.gameEvent)
  const featureDragable = useSelector((state) => state.globalReducer.featureDragable);
  const [drag, setDrag] = useState(false)
  const [tempAlphabet1, setTempAlphabet1] = useState(alphabet1)

  const handleAddDragItem = (word, type) => {
    let styleBorderNText = 'border-black text-black'
    let IdIncrement = 0;
    if (gameEvent.length !== 0) IdIncrement = gameEvent[gameEvent.length-1].id;
    dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:300, y:0, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
    dispatch({ type: "REDO_EVENT", payload: []})
  }

  const addNewItem = (e, word, type, tempId=false) => {
    if (!tempId) {
      dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
      const isIdTemp = (element) => element.id === "tempId";
      const isIdTes = (element) => element.id === "tesId";
      if (gameEvent.findIndex(isIdTemp) !== -1) gameEvent.splice(gameEvent.findIndex(isIdTemp), 1);
      if (gameEvent.findIndex(isIdTes) !== -1) gameEvent.splice(gameEvent.findIndex(isIdTes), 1);
      let styleBorderNText = 'border-black text-black'
      let IdIncrement = 0;
      if (gameEvent.length !== 0) IdIncrement = gameEvent[gameEvent.length-1].id;
      if (e.changedTouches) {
        dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-350, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
      } else {
        dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-350, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
      }
      dispatch({ type: "REDO_EVENT", payload: []})
    } else {
      const checkTile = gameEvent.find(({ id }) => id === "tempId");
      const checkTileTes = gameEvent.find(({ id }) => id === "tesId");
      if (checkTile || checkTileTes) {
        const tempObj = checkTile
        const isIdTemp = (element) => element.id === "tempId";
        gameEvent.splice(gameEvent.findIndex(isIdTemp), 1);
        if (gameEvent.findIndex(isIdTemp) === -1) {
          if (e.changedTouches) {
            tempObj['x'] = e.changedTouches['0'].pageX-100
            tempObj['y'] = e.changedTouches['0'].pageY-350
            tempObj['id'] = 'tesId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          } else {
            tempObj['x'] = e.pageX-100
            tempObj['y'] = e.pageY-350
            tempObj['id'] = 'tesId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          }
        } else {
          const isIdTes = (element) => element.id === "tesId";
          gameEvent.splice(gameEvent.findIndex(isIdTes), 1);
          if (e.changedTouches) {
            tempObj['x'] = e.changedTouches['0'].pageX-100
            tempObj['y'] = e.changedTouches['0'].pageY-350
            tempObj['id'] = 'tempId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          } else {
            tempObj['x'] = e.pageX-100
            tempObj['y'] = e.pageY-350
            tempObj['id'] = 'tempId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          }
        }
      } else {
        dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
        let styleBorderNText = 'border-black text-black'
      let IdIncrement = 0;
      if (gameEvent.length !== 0) IdIncrement = gameEvent[gameEvent.length-1].id;
      if (e.changedTouches) {
        dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-350, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
      } else {
        dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-350, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
      }
      dispatch({ type: "REDO_EVENT", payload: []})
      }
    }
  }

  const handleSetAlfabet = () => {
    setTempAlphabet1(alphabet1)
  }

  return (
      <div className={`animate__animated ${props.animation === 'fadeIn' ? 'animate__fadeInLeft': 'animate__fadeOutLeft'} z-10`}>
          <div className={`ml-2 flex flex-col gap-1 fixed t-[45%] ${props.padding} z-10`}>
              <div className="flex flex-col gap-[5px]">
                <div className="flex  gap-[5px] bg-[#FFFFFF80] p-[5px] rounded-[10px] w-fit">
                  {alphabet1.map((item, index) => (
                    <InitialBlendDragable word={item} key={index} addNewItem={addNewItem} handleSetAlfabet={handleSetAlfabet} typeWord={'initialBlend'}/>
                  ))}
                </div>   

                <div className="flex  gap-[5px] bg-[#FFFFFF80] p-[5px] rounded-[10px] w-fit">
                  {alphabet2.map((item, index) => (
                    <InitialBlendDragable word={item} key={index} addNewItem={addNewItem} handleSetAlfabet={handleSetAlfabet} typeWord={'initialBlend'}/>
                  ))}
                </div> 

                <div className="flex  gap-[5px] bg-[#FFFFFF80] p-[5px] rounded-[10px] w-fit">
                  {alphabet3.map((item, index) => (
                    <InitialBlendDragable word={item} key={index} addNewItem={addNewItem} handleSetAlfabet={handleSetAlfabet} typeWord={'initialBlend'}/>
                  ))}
                </div> 

                <div className="flex  gap-[5px] bg-[#FFFFFF80] p-[5px] rounded-[10px] w-fit">
                  {alphabet4.map((item, index) => (
                    <InitialBlendDragable word={item} key={index} addNewItem={addNewItem} handleSetAlfabet={handleSetAlfabet} typeWord={'initialBlend'}/>
                  ))}
                </div>                                                                                   
              </div>        
          </div>

      </div>
  );
}
