export const alphabet1 = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
];

export const alphabet2 = [
  "n",
  "o",
  "p",
  "qu",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];

export const phonic = [{
  type:"text",
  content: [
    "sh",
    "th",
    "ch",
    "wh",
    "ph",
    "tch",
    "dge"
  ]},
{
  type:"text",
  content: [
    "ff",
    "ll",
    "ss",
    "zz",
    "ck",
    "tch",
    "dge"
  ]},
{
  type:"color",
  content: [
    "#00CC00",
    "#FFFF00",
    "#FF0000",
    "#FFAA00",
    "#0000CC",
    "#9900CC",
    "#FF6FE8"
]}];

export const advancedVowelLeft = [
  "ee",
  "ea",
  "ai",
  "ay",
  "oa",
  "ow",
  "oe",
  "oo",
  "ie",
  "igh",
  "ou",
  "oi",
  "oy",
  "au",
  "aw",
  "ue",
  "eu",
];

export const advancedVowelRight = [
  "ar",
  "or",
  "er",
  "ir",
  "ur",
  "for",
  "air",
  "ear",
  "oar",
  "ore",
  "our",
  "oor",
];

export const syllaboard = [
  "closed",
  "open",
  "magic e",
  "vowel teams",
  "r-controlled",
  "consonant LE",
];
