import React, {useEffect, useState} from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { FaCheck } from "react-icons/fa";
import Button from "../components/Button";
import Navbar from "../components/Navbar";
import {Link, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlan, updateAndGetSecret } from "../redux/actions/Plan";
import PopupWindow from "../components/PopupWindow";

export default function SelectPlanGamePage() {
    const token = localStorage.getItem('id_token');
    let userDetail = localStorage.getItem('userDetail')
    userDetail = JSON.parse(userDetail)
    const loading = useSelector((state) => state.globalReducer.loading);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [plans, setPlans] = useState([]);
  const [idPlan, setIdPlan] = useState({})
  
  useEffect(() => {
    const docBody = document.querySelector("body");
    docBody.classList.remove("green-bg");
    docBody.classList.add("yellow-bg");
    fatchAllPlan()
  }, [])

  const fatchAllPlan = async () => {
    dispatch(getAllPlan(true, (result)=>{
      setPlans(result.reverse())
      let newObj = {}
      if (result.length !== 0) {
        result.forEach((el, i) => {
          newObj = {...newObj, [i]: el.plan[0]}
        });
        setIdPlan(newObj)
      }
    }))
  }

  const choosePlan = (plan, idx) => {
      plan.plan.forEach(el => {
          for (const key in idPlan) {
              if (idPlan[key].kode === el.kode) {
                  const user = {
                      planCode: idPlan[key].kode
                    }
            dispatch(updateAndGetSecret(user, token, (result)=>{
                if (result.status === 201) {
                    localStorage.setItem('title_plan', result.data.title);
                    localStorage.setItem('choose_plan', JSON.stringify(result.data.plan));
                    dispatch({type:'CLIENT_SECRET', payload: result.data.clientSecret})
                    navigate(`payment/process`)
                }
            }))
        }
      }
    });
  }

  const changePlan = (plan, idx) => {
    let objPlan = idPlan
    let newObj = {}
    for (const key in objPlan) {
      if (key != idx) {
        newObj = {[key]: objPlan[key]}
      }
    }
    // let newObj = objPlan.filter(([key, value]) => key !== idx);
    newObj = {...newObj, [idx]: plan}
    setIdPlan(newObj)
  }

  return (
    <>
      <Navbar signUp={false} signIn={false}/>
      <div className="grid justify-items-center items-center min-h-screen">
      <PopupWindow/>
      {
        loading ?
        '' :
        <div className="p-14 max-w-[1095px]">
          <div className="max-w-[1095px] h-auto bg-white flex gap-10 flex-col items-center p-8 sm1:p-20 rounded-2xl">
            <h1 className="text-4xl font-gilroyBold text-center text-green-1 capitalize">
              Select your plan
            </h1>
            <p className="text-center max-w-[488.8px] text-grey-500">
              Please choose your plan based on your needs and requirements. Each of this plan have a 6 months and 12 months options.
            </p>
            <div className="flex flex-col  sm:flex-row gap-5 w-full sm:w-3/4 min-w-[275px] mx-auto sm:mx-auto justify-center">
              {
              plans.length !== 0 ?
              plans.map((item, i) => (
                <div
                  key={i}
                  className="flex flex-col gap-6 h-auto divide-y divide-lightgrey-100 sm:h-auto bg-white border-lightgrey-100 border-2 rounded-xl p-8 min-w-[275px]"
                >
                  <div className="flex flex-col">
                    <h1 className="text-xl font-gilroyBold uppercase text-center text-green-1">
                      {item.title}
                    </h1>
                  </div>
                  <div className="flex flex-col gap-5 pt-6">
                    <div className="h-12">
                      {item.features.map((feature) => (
                        <div className="flex gap-4 items-center" key={feature}>
                          <FaCheck color="#356733" />
                          <p className="text-grey-500 text-xs">{feature}</p>
                        </div>
                      ))}
                    </div>
                    <Tab.Group>
                      <Tab.List className="flex space-x-0 rounded-3xl bg-lightgrey-100 p-[2px]">
                        {item.plan.map((el, j) => (
                          <Tab
                            key={`month${j}`}
                            onClick={()=>changePlan(el, i)}
                            className={({ selected }) =>
                              clsx(
                                "w-full rounded-3xl px-[10px] py-[1px] text-sm tracking-wide border-4",
                                "focus:outline-none",
                                selected
                                  ? " bg-yellow-300 border-none text-green-1 font-bold"
                                  : "text-grey-2 border-lightgrey-100 bg-lightgrey-100 "
                              )
                            }
                          >
                            {el.month}
                          </Tab>
                        ))}
                      </Tab.List>
                      <Tab.Panels>
                        {
                          item.plan.map((el) => (
                            <Tab.Panel key={el.kode}>
                              <p className="text-xl font-medium text-center  text-green-1">
                                ${" "}
                                <span className="text-4xl font-semibold">
                                  {el.price}
                                </span>{" "} USD
                                / month{" "}
                              </p>
                            </Tab.Panel>
                          ))
                        }
                      </Tab.Panels>
                    </Tab.Group>
                    
                      <button
                          onClick={() => choosePlan(item, i)}
                          className={`flex shadow-[0px_4px_0px_#e9bf45,inset_0px_3px_0px_rgb(255_255_255_/_15%)] justify-center justify-items-center items-center rounded-[10px] px-5 w-full h-[62px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold border-[3px] border-yellow hover:border-yellow`}
                        >
                          <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">Choose Plan</span>
                      </button>
                  </div>
                </div>
              )):''}
            </div>
          </div>
        </div>
      }
      </div>
    </>
  );
}
