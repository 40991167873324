import clsx from "clsx";
import * as React from "react";
import { useState } from "react";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";

const vokal = ["a", "i", "u", "e", "o"];
export default function DraggableBox({
  word,
  prefix,
  suffixes,
  suffixes2,
  root,
  color,
  addNewItem,
  handleSetAlfabet,
  typeWord
}) {
  const [draged, setDrag] = React.useState(false);
  const featureDragable = useSelector((state) => state.globalReducer.featureDragable);

  const handleDrag = async (e, color) => {
    setDrag(true);
    await addNewItem(e, color, 'color', 'tempId');
    handleSetAlfabet();
  }
  return (
    <div className="flex z-0">
        <div
            className={`shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] w-[50px] maxXl:w-[40px] h-[45px] maxXl:h-[35px] border-[3px] maxXl:border-2 place-content-center rounded-[10px] cursor-pointer`}
            style={{ background: color }}
        ></div>
        <Draggable position={draged ? { x: -70, y: -30 } : { x: 0, y: 0 }} grid={featureDragable.snap ? [50, 50] : ''} onStop={(e)=>{addNewItem(e, color, 'color');setDrag(false);}} onDrag={(e)=>{handleDrag(e, color)}} className="z-50">
            <div className={clsx(draged ? "hidden fixed justify-center justify-items-center place-content-center cursor-pointer pt-2 rounded-[20px] border-[3px] shadow-[inset_0px_-18px_0px_rgba(156,_156,_156,_0.14)] fix min-w-[156px] min-h-[156px] z-50" : "fixed shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] w-[50px] maxXl:w-[40px] h-[45px] maxXl:h-[35px] border-[3px] maxXl:border-2 place-content-center rounded-[10px] cursor-pointer")} style={{ background: color }}></div>
        </Draggable>
    </div>
  );
}
