import clsx from "clsx";
import * as React from "react";
import { useState } from "react";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import Vector36 from '../../assets/Vector36.svg'

const vokal = ["a", "i", "u", "e", "o"];
export default function InitialBlendDragable({
  word,
  color,
  addNewItem,
  handleSetAlfabet,
  typeWord
}) {
  const [drag, setDrag] = React.useState(false);
  const featureDragable = useSelector((state) => state.globalReducer.featureDragable);
  const handleDrag = async (e, word, typeWord) => {
    setDrag(true);
    await addNewItem(e, word, typeWord, 'tempId');
    handleSetAlfabet();
  }
  return (
    <div className="flex">
        <div className="shadow-[inset_0px_-8px_0px_rgba(156,_156,_156,_0.14);] maxXl:shadow-[inset_0px_-4px_0px_rgba(156,_156,_156,_0.14);] w-[38px] maxXl:w-7 h-[34px] maxXl:h-6 border-[2px] maxXl:border-[1.5px] border-[#040404] rounded-[10px] maxXl:rounded-md bg-white cursor-pointer">
        <p className="text-center h-10 font-extrabold rounded-[10px] -mb-5 maxXl:text-xs maxXl:-mb-[26px]">{word}</p>
            <img draggable="false" alt="Vector" src={Vector36} className="ml-[5px] maxXl:ml-[4.5px] maxXl:w-4" />
        </div>
        <Draggable  position={drag ? { x: 0, y: 0 } : { x: 0, y: 0 }} grid={featureDragable.snap ? [50, 50] : ''} onStop={(e)=>{addNewItem(e, word, typeWord);setDrag(false);}} onDrag={(e)=>{handleDrag(e, word, typeWord)}} className="z-50">
        <div className={drag ? "fixed justify-center justify-items-center bg-white place-content-center cursor-pointer pb-5 rounded-[20px] border-[3px] shadow-[inset_0px_-18px_0px_rgba(156,_156,_156,_0.14)] fix min-w-[156px] min-h-[156px] z-50 hidden" : "fixed shadow-[inset_0px_-8px_0px_rgba(156,_156,_156,_0.14);] maxXl:shadow-[inset_0px_-4px_0px_rgba(156,_156,_156,_0.14);] w-[38px] maxXl:w-7 h-[34px] maxXl:h-6 border-[2px] maxXl:border-[1.5px] border-[#040404] rounded-[10px] maxXl:rounded-md bg-white cursor-pointer"}>
            <p className={drag? "text-center font-gilroyMedium font-bold h-10 text-[90px]" : "text-center h-10 font-extrabold rounded-[10px] -mb-5 maxXl:-mb-[26px] maxXl:text-xs"}>{word}</p>
            <img draggable="false" alt="Vector" src={Vector36} width={drag ? 120 : false} className={drag ? "bottom-0 left-[18px] absolute" : "ml-[5px] maxXl:ml-[4.5px] maxXl:w-4"} />
        </div>
        </Draggable>
    </div>
  );
}
