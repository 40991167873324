const initialState = {
  inputButton: "",
  loading: false,
  finalBlendShow: false,
  gluedSound: false,
  dragItems: [],
  featureDragable: {snap: false},
  activeTile: {},
  statusPage: 'Basic Tiles',
  AllPlan: [],
  AllNotification:[],
  gameEvent:[],
  undoEvent:[],
  typeUndo: '',
  redoEvent:[],
  typeRedo: ''
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INPUT_BUTTON":
      return { ...state, inputButton: action.payload };
    case "LOADING":
      return { ...state, loading: action.payload };
    case "FINAL_BLEND_SHOW":
      return { ...state, finalBlendShow: action.payload };
    case "DRAG_ITEMS":
      return { ...state, dragItems: action.payload };
    case "FEATURE_DRAGABLE":
      return { ...state, featureDragable: action.payload };
    case "ACTIVE_TILE":
      return { ...state, activeTile: action.payload };
    case "GLUED_SOUND":
      return { ...state, gluedSound: action.payload };
    case "STATUS_PAGE":
      return { ...state, statusPage: action.payload };
    case "ALL_PLAN":
      return { ...state, AllPlan: action.payload };
    case "ALL_NOTIFICATION":
      return { ...state, AllNotification: action.payload };
    case "GAME_EVENT":
      return { ...state, gameEvent: action.payload };
    case "UNDO_EVENT":
      return { ...state, undoEvent: action.payload };
    case "TYPE_UNDO":
      return { ...state, typeUndo: action.payload };
    case "REDO_EVENT":
      return { ...state, redoEvent: action.payload };
    case "TYPE_REDO":
      return { ...state, typeRedo: action.payload };
    default:
      return state;
  }
};

export default globalReducer;
