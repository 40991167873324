import { useState } from "react";
import { syllaboard } from "../../../constant";
import { useDispatch, useSelector } from "react-redux";

import DraggableFitWidth from "../../common/Draggable/draggableFit";
export default function SyllaboardsPanel() {
  const dispatch = useDispatch();
  const gameEvent = useSelector((state) => state.globalReducer.gameEvent);
  const user = useSelector((state) => state.userReducer.user);
  const featureDragable = useSelector((state) => state.globalReducer.featureDragable);
  const [advanceVowelLeft, setAdvanceVowelLeft] = useState(syllaboard)
  const handleAddDragItem = (e, word, type, tempId=false) => {
    if (user.planActivation) {
      if (!user.planActivation.isActive) {
        return
      }
    }
    if (!tempId) {
      dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
      const isIdTemp = (element) => element.id === "tempId";
      const isIdTes = (element) => element.id === "tesId";
      if (gameEvent.findIndex(isIdTemp) !== -1) gameEvent.splice(gameEvent.findIndex(isIdTemp), 1);
      if (gameEvent.findIndex(isIdTes) !== -1) gameEvent.splice(gameEvent.findIndex(isIdTes), 1);
      const vokal = ["a", "i", "u", "e", "o"];
      let IdIncrement = 0;
      if (gameEvent.length !== 0)
        IdIncrement = gameEvent[gameEvent.length - 1].id;
      if (vokal.includes(word)) {
        let styleBorderNText = "border-red text-red";
        if (e.changedTouches) {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-200, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        } else {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-200, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        }
        dispatch({ type: "REDO_EVENT", payload: []})
      } else {
        let styleBorderNText = "border-black text-black";
        if (e.changedTouches) {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-200, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        } else {
          dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-200, typeEvent: 'tile', id: IdIncrement+=1, styleBorderNText: styleBorderNText, type: type, value:word}]})
        }
        dispatch({ type: "REDO_EVENT", payload: []})
      }
    } else {
      const checkTile = gameEvent.find(({ id }) => id === "tempId");
      const checkTileTes = gameEvent.find(({ id }) => id === "tesId");
      if (checkTile || checkTileTes) {
        const tempObj = checkTile
        const isIdTemp = (element) => element.id === "tempId";
        gameEvent.splice(gameEvent.findIndex(isIdTemp), 1);
        if (gameEvent.findIndex(isIdTemp) === -1) {
          if (e.changedTouches) {
            tempObj['x'] = e.changedTouches['0'].pageX-100
            tempObj['y'] = e.changedTouches['0'].pageY-200
            tempObj['id'] = 'tesId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          } else {
            tempObj['x'] = e.pageX-100
            tempObj['y'] = e.pageY-200
            tempObj['id'] = 'tesId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          }
        } else {
          const isIdTes = (element) => element.id === "tesId";
          gameEvent.splice(gameEvent.findIndex(isIdTes), 1);
          if (e.changedTouches) {
            tempObj['x'] = e.changedTouches['0'].pageX-100
            tempObj['y'] = e.changedTouches['0'].pageY-200
            tempObj['id'] = 'tempId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          } else {
            tempObj['x'] = e.pageX-100
            tempObj['y'] = e.pageY-200
            tempObj['id'] = 'tempId'
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {...tempObj}]})
          }
        }
      } else {
        dispatch({type: "FEATURE_DRAGABLE", payload: { ...featureDragable, snap: false }});
        const vokal = ["a", "i", "u", "e", "o"];
        let IdIncrement = 0;
        if (gameEvent.length !== 0) IdIncrement = gameEvent[gameEvent.length - 1].id;
        if (vokal.includes(word)) {
          let styleBorderNText = "border-red text-red";
          if (e.changedTouches) {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-200, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          } else {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-200, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          }
          dispatch({ type: "REDO_EVENT", payload: []})
        } else {
          let styleBorderNText = "border-black text-black";
          if (e.changedTouches) {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.changedTouches['0'].pageX-100, y:e.changedTouches['0'].pageY-200, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          } else {
            dispatch({ type: "GAME_EVENT", payload: [...gameEvent, {x:e.pageX-100, y:e.pageY-200, typeEvent: 'tile', id: tempId, styleBorderNText: styleBorderNText, type: type, value:word}]})
          }
          dispatch({ type: "REDO_EVENT", payload: []})
        }
      }
    }
  };

  const handleSetAlfabet = () => {
    setAdvanceVowelLeft(syllaboard)
  }

  return (
    <>
      <div className="px-6 flex justify-center gap-1">
        <div className="flex flex-col gap-2 p-[9px] w-fit rounded-2xl shadow-[inset_2px_2px_0px_rgba(0,_0,_0,_0.1);] bg-[#f4f4f4] ">
          <div className="flex flex-col w-3xl min-w-fit max-w-3xl gap-[0.5rem]">
            <div className="flex gap-[0.5rem] w-3xl min-w-fit">
              {syllaboard.map((item, index) => (
                  <DraggableFitWidth word={item} key={index} addNewItem={handleAddDragItem} handleSetAlfabet={handleSetAlfabet} typeWord={'labels'} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className='absolute left-3 bottom-0 mb-[-130px] -ml-3 w-[323px] h-[103px] bg-white rounded-md shadow-[inset_0px_-5px_0px_rgba(156,_156,_156,_0.14)] drop-shadow-[0px_4px_30px_rgba(0,_0,_0,_0.15)] pt-[14px] pb-[18px] px-4'>
        <p className='font-gilroyBold text-base text-green-1'>Instructions</p>
        <p className='text-xs font-gilroyMedium'>1. Write or build a word</p>
        <p className='text-xs font-gilroyMedium'>2. Code the word</p>
        <p className='text-xs font-gilroyMedium'>3. Select the syllable type for each part of the word</p>
      </div>
    </>
  );
}
