const initialState = {
    ListAdmin: [],
    ListMember: [],
    sideBarActive:false
};
    
const adminReducer = (state = initialState, action) => {
    switch (action.type) {
    case "LIST_ADMIN":
        return { ...state, ListAdmin: action.payload };
    case "LIST_MEMBER":
        return { ...state, ListMember: action.payload };
    case "SIDE_BAR_ACTIVE":
        return { ...state, sideBarActive: action.payload };
    default:
        return state;
    }
};
    
export default adminReducer;