import React, {useState, useEffect} from "react";
import Logo from "../assets/logo.png";
import {Link, useNavigate} from "react-router-dom"
import showPw from "../assets/show-pw.svg"
import hidePw from "../assets/hide-pw.svg"
import { useDispatch } from "react-redux";

import { login } from "../redux/actions/Auth";
import { detailUser, detailUserAdmin } from "../redux/actions/User";
import PopupWindow from "../components/PopupWindow";

function LoginPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')

    const [rememberMeActive, setRememberMeActive] = useState(false)

    useEffect(() => {
        document.body.style.backgroundColor = null;
        document.body.style.backgroundImage = null;
        const docBody = document.querySelector("body");
        docBody.classList.remove("green-bg");
        docBody.classList.add("yellow-bg");
        let rememberMe = sessionStorage.getItem('rememberActive')
        rememberMe = JSON.parse(rememberMe)
        let rememberUser = sessionStorage.getItem('rememberUser')
        rememberUser = JSON.parse(rememberUser)
        setRememberMeActive(rememberMe)
        if (rememberMe && rememberUser.email) {
          setEmail(rememberUser.email)
          setPassword(rememberUser.password)
        }
    }, [])

    const handleLogin = (e) => {
      e.preventDefault()
      if (password !== '' && email !== '') {
        const user = {
          email: email,
          password: password
        }
        submitFormLogin(user)
      }
    }
    
    const submitFormLogin = async (user) => {
      await dispatch(login(user, (result) => {
        if (result.message === 'login succsesfully') {
          localStorage.setItem('id_token', result.token)
          if (result.user.role) {
            dispatch(detailUserAdmin(result.token, true, (newResult)=>{
              localStorage.setItem('userDetail', JSON.stringify(newResult))
              if (newResult === 'invalid token') {
                localStorage.removeItem('userDetail')
                localStorage.removeItem('id_token')
                localStorage.removeItem('input_button')
                navigate('/')
              }
            }))
          } else {
            dispatch(detailUser(result.token, (newResult)=>{
              localStorage.setItem('userDetail', JSON.stringify(newResult))
              if (newResult === 'invalid token') {
                localStorage.removeItem('userDetail')
                localStorage.removeItem('id_token')
                localStorage.removeItem('input_button')
                navigate('/')
              }
            }))
          }
          if (rememberMeActive) {
            const rememberUser = {
              email: email,
              password: password
            }
            sessionStorage.setItem('rememberUser', JSON.stringify(rememberUser))
          }
          if (result.user.role) {
            navigate('/admin/dashboard')
          } else {
            navigate('/home')
          }
        } else {
          navigate('/login')
        }
      }));
    }

    const setupRememberMe = (remember) => {
      setRememberMeActive(remember)
      if (remember) {
        if (password && email) {
          sessionStorage.setItem('rememberActive', remember)
        } else {
          sessionStorage.removeItem('rememberActive')
          sessionStorage.removeItem('rememberUser')
        }
      } else {
        sessionStorage.removeItem('rememberActive')
        sessionStorage.removeItem('rememberUser')
      }
    }

  return (
    <>
      <div className="grid justify-items-center items-center h-screen">
      <PopupWindow/>
        <div className="bg-white rounded-[15px] shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14)] dark:bg-gray-800 dark:border-gray-700 py-[65px] px-[60px] max-w-[616px] min-w-[515px] min-h-[436px]">
          <div className="flex flex-col items-center">
            <div className='w-[496px] text-center px-6'>
                <h1 className="text-4xl text-green-1 font-gilroyBold tracking-wide mt-5 mb-14">Sign In</h1>
                <form className="w-full max-w-lg" onSubmit={e=>handleLogin(e)}>
                    <div className="relative my-6">
                        <div className="w-full">
                            <input value={email} onChange={e=>{setEmail(e.target.value);}} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" type="email" placeholder="Email Address" required/>
                        </div>
                    </div>
                    <div className='relative mb-5'>
                        <div className="w-full">
                            <input value={password} onChange={e=>{setPassword(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="Password" required/>
                            <div className="absolute inset-y-0 right-3 pr-3 flex items-center text-sm">
                                {
                                    showPassword?
                                    <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={showPw} alt="showPw" />
                                    :
                                    <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={hidePw} alt="hidePw" />
                                }
                            </div>
                        </div>
                    </div>
                    <div className='relative mb-5'>
                        <div className="w-full flex justify-between">
                          <div className="form-check flex">
                              <input checked={rememberMeActive} onChange={()=>setupRememberMe(!rememberMeActive)} className="form-check-input h-4 w-4 border accent-green-1 border-lightgrey-150 rounded-sm bg-grey-1100 checked:bg-green-1 checked:border-green-1 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" id="flexCheckDefault"/>
                              <label className="form-check-label inline-block text-grey-3" htmlFor="flexCheckDefault">
                                <p className='text-grey-3'>Remember Me</p>
                              </label>
                          </div> 
                          <Link to="/login/forgot-password" className="text-blue cursor-pointer font-gilroyBold">Forgot Password?</Link>
                        </div>
                    </div>
                    <button
                    className="flex shadow-[0px_4px_0px_#e9bf45,inset_0px_3px_0px_rgb(255_255_255_/_15%)] justify-center justify-items-center items-center rounded-[10px] w-full h-[62px] mt-[17.32px] bg-green-1 hover:bg-green-2 text-yellow-300 font-bold border-[3px] border-yellow hover:border-yellow" type="submit"
                    >
                        <span className="ml-2 tracking-[0.08rem] font-bold text-lg shadowText">Sign In</span>
                    </button>
                    <p className="text-grey-3 w-full text-center mt-5">Not Registered yet? <Link to="/login/select-plan" className="text-blue cursor-pointer font-gilroyBold">Create an Account</Link></p>
                </form>
                <Link to='/'>
                  <img alt="logo" src={Logo} className="w-[173.25px] mx-auto mt-5" />
                </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
