import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import bulletPoint from '../assets/bullet-point.svg'

import LogoLandingPage from "../assets/logo-landingpage.png"

import Navbar from "../components/Navbar";
import { detailUser } from "../redux/actions/User";

function LandingPage() {
    const dispatch = useDispatch()
    let token = localStorage.getItem('id_token');

    useEffect(() => {
        document.body.style.backgroundColor = null;
        document.body.style.backgroundImage = null;
        const docBody = document.querySelector("body");
        docBody.classList.remove("yellow-bg");
        docBody.classList.add("green-bg");
        if (token) {
            dispatch(detailUser(token, (result)=>{
                if (result === 'invalid token' || result === 'user not Found') {
                    localStorage.removeItem('userDetail')
                    localStorage.removeItem('id_token')
                    localStorage.removeItem('input_button')
                }
            }))
        }
    }, [])
    

    return (
        <div className='h-screen'>
            <Navbar token={token} signUp={true} signIn={true}/>
            <div className="grid grid-cols-2 h-[87%] md1:flex md1:justify-center">
                <div className="flex-col items-end w-full flex mt-auto mb-auto p-8 md1:flex md1:items-center">
                    <p className='font-gilroyBold lead text-yellow-300 tracking-wide xl:w-[540.55px] w-[90%] xl:text-3xl text-2xl xs1:text-xl'>Online Teaching Tools</p>
                    <div className="flex flex-col items-start gap-8 xl:w-[540.55px] w-[90%]">
                        <p className='font-gilroyBold xl:text-7xl text-5xl xs1:text-2xl text-white tracking-wide'>Blending Board</p>
                        <p className='font-gilroyMedium xl:text-xl text-base xs1:text-lg text-white tracking-wide'>A digital interface where students and teachers use letter tiles to explore, build, segment, sound out, and spell.</p>
                        <p className='font-gilroyMedium xl:text-xl text-base text-white tracking-wide'>Includes: <br/><div className="flex xs1:text-[10px]"><img src={bulletPoint} alt="point" /> &nbsp;Letter Tile manipulatives for phonics and spelling</div><div className="flex xs1:text-[10px]"><img src={bulletPoint} alt="point" /> &nbsp;Glued Sounds</div><div className="flex xs1:text-[10px]"><img src={bulletPoint} alt="point" /> &nbsp;Syllable Identification</div><div className="flex xs1:text-[10px]"><img src={bulletPoint} alt="point" /> &nbsp;Coding</div><div className="flex xs1:text-[10px]"><img src={bulletPoint} alt="point" /> &nbsp;Spelling</div></p>
                        {token ?
                        <Link to="/home">
                            <button className='flex flex-row justify-center items-center py-5 px-14 bg-yellow-300 rounded-md mt-4 xs1:px-6 xs1:py-3'>
                                <p className='text-green-1 text-lg font-gilroyBold xs1:text-sm'>Play!</p>
                            </button>
                        </Link>
                        :
                        <Link to="/register">
                            <button className='flex flex-row justify-center items-center py-5 px-14 bg-yellow-300 rounded-md mt-4 xs1:px-6 xs1:py-3'>
                                <p className='text-green-1 text-lg font-gilroyBold xs1:text-sm'>Try Free 14 Days!</p>
                            </button>
                        </Link>}
                    </div>
                </div>
                <div className="flex-col items-start gap-11 w-full flex mt-auto mb-auto p-8 md1:hidden">
                    <img src={LogoLandingPage} width='602.4px' alt="loadingPage" />
                </div>
            </div>
        </div>
    );
}

export default LandingPage;
