import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProfileNav from "../../components/profile/ProfileNav";
import { detailUser } from "../../redux/actions/User";
import PopupWindow from "../../components/PopupWindow";
import PhotoDefault from "../../assets/photo.png";
import { getAllMember, createNewMember, editNewMember, deleteNewMember } from "../../redux/actions/Auth";
import AddUser from "../../assets/AddUser.svg"
import ThreeDots from "../../assets/threeDots.svg"
import ActiveSvg from "../../assets/active.svg"
import InactiveSvg from "../../assets/inActive.svg"
import closeIcon from "../../assets/close-icon.svg"
import IconUsers from "../../assets/iconUsers.svg"
import Edit from "../../assets/Edit.svg"
import Delete from "../../assets/Delete.svg"
import IconRemove from "../../assets/icon-remove.svg"
import moment from 'moment'
import {Link} from "react-router-dom"

import showPw from "../../assets/show-pw.svg"
import hidePw from "../../assets/hide-pw.svg"

export default function Member() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loading = useSelector((state) => state.globalReducer.loading);
  const token = localStorage.getItem('id_token');
  let userDetail = localStorage.getItem('userDetail')
  userDetail = JSON.parse(userDetail)

  const [user, setUser] = useState({})
  const [selectedImage, setSelectedImage] = useState('');
  const [listMember, setListMember] = useState({})
  const [showAdd, setShowAdd] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isActive, setIsActive] = useState(true)
  const [showPassword, setShowPassword] = useState(false)
  const [idUser, setIdUser] = useState('')

  const showingEdit = (input) => {
    setFirstname(input.firstName)
    setLastname(input.lastName)
    setEmail(input.email)
    setIsActive(input.isActive)
    setIdUser(input._id)
    setShowEdit(true)
  }

  const showingDelete = (input) => {
    setIdUser(input._id)
    setEmail(input.email)
    setShowDelete(true)
  }

  const resetForm = () => {
    setFirstname('')
    setLastname('')
    setEmail('')
    setPassword('')
    setIdUser('')
    setIsActive(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (firstname !== '' && lastname !== '' && email !== '' && password !== '') {
        const paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;
        if (!password.match(paswd)) {
        dispatch({type:'ERROR_LOG', payload: 'validatePassword'})
        dispatch({type:'ERROR_MESSAGE', payload: 'Password Between 6 to 20 Characters Which Contains At Least One Lowercase Letter, One Uppercase Letter, One Numeric Digit, And One Special Character'})
        setTimeout(() => {
            dispatch({type:'ERROR_MESSAGE', payload: ''})
            dispatch({type:'ERROR_LOG', payload: ''})
        }, 2000);
        } else {   
            const user = {
                firstname:firstname,
                lastname:lastname,
                email:email,
                password:password,
                isActive:isActive,
            }
            dispatch(createNewMember(user, token, (result)=> {
                console.log(result);
                if (result.message === "email registered successfully") {
                    setShowAdd(false)
                    resetForm()
                    dispatch(getAllMember(token, (result)=> {
                        setListMember(result);
                    }))
                }
            }))
        }  
      } else {
        dispatch({type:'ERROR_LOG', payload: 'validateFormRegisterTrial'})
        dispatch({type:'ERROR_MESSAGE', payload: 'Please Input All Form'})
        setTimeout(() => {
            dispatch({type:'ERROR_MESSAGE', payload: ''})
            dispatch({type:'ERROR_LOG', payload: ''})
        }, 2000);
      }
  }

  const handleSubmitEdit = (e) => {
    e.preventDefault()
    if (firstname !== '' && lastname !== '' && email !== '') {
        // const paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;
        // if (!password.match(paswd)) {
        // dispatch({type:'ERROR_LOG', payload: 'validatePassword'})
        // dispatch({type:'ERROR_MESSAGE', payload: 'Password Between 6 to 20 Characters Which Contains At Least One Lowercase Letter, One Uppercase Letter, One Numeric Digit, And One Special Character'})
        // setTimeout(() => {
        //     dispatch({type:'ERROR_MESSAGE', payload: ''})
        //     dispatch({type:'ERROR_LOG', payload: ''})
        // }, 2000);
        // } else {   
            const user = {
                firstname:firstname,
                lastname:lastname,
                email:email,
                isActive:isActive,
            }
            dispatch(editNewMember(user, token, idUser, (result)=> {
                if (result.ok === 1) {
                    setShowEdit(false)
                    resetForm()
                    dispatch(getAllMember(token, (result)=> {
                        setListMember(result);
                    }))
                }
            }))
        // }  
      } else {
        dispatch({type:'ERROR_LOG', payload: 'validateFormRegisterTrial'})
        dispatch({type:'ERROR_MESSAGE', payload: 'Please Input All Form'})
        setTimeout(() => {
            dispatch({type:'ERROR_MESSAGE', payload: ''})
            dispatch({type:'ERROR_LOG', payload: ''})
        }, 2000);
      }
  }

  const handleDelete = () => {
    dispatch(deleteNewMember(token, idUser, (result)=> {
        if (result.ok === 1) {
            setShowDelete(false)
            resetForm()
            dispatch(getAllMember(token, (result)=> {
                setListMember(result);
            }))
        }
    }))
  }

  useEffect(()=> {
    const docBody = document.querySelector("body");
    docBody.classList.remove("green-bg");
    docBody.classList.add("yellow-bg");
        dispatch(getAllMember(token, (result)=> {
        setListMember(result);
        }))
      dispatch(detailUser(token, (result)=>{
        setUser(result)
        localStorage.setItem('userDetail', JSON.stringify(result))
        if (result === 'invalid token') {
          localStorage.removeItem('userDetail')
          localStorage.removeItem('id_token')
          localStorage.removeItem('input_button')
          navigate('/')
        }
      }))
  },[])

  return (
    <>
    <div className="min-h-screen flex justify-center items-center">
    <PopupWindow/>
      {loading? 
        <div className="fixed top-0 flex left-0 w-full h-full justify-center items-center bg-[#00000047] z-50">
            <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status"></div>
        </div>:''
      }
      <div className="py-10">
        <div className="grid justify-items-center items-center h-screen md:py-0 ">
          <div className="h-auto min-h-[839px] min-w-[906px] bg-white flex gap-0 flex-col items-center rounded-2xl overflow-hidden">
            <div className="flex gap-6 items-center w-full h-auto bg-green-1 px-10 py-6">
              {
                selectedImage ?
                <>
                    <img
                    src={selectedImage}
                    className="h-28 w-28 object-contain bg-grey-1000 border-4 border-white rounded-xl"
                    alt="Thumb"
                    />
                </>
                :
                <img alt="logo" src={PhotoDefault} className='h-28 w-28 bg-grey-1000 border-4 border-white rounded-xl'/>
              }
              <div className="h-36 w-1/6 flex flex-col gap-3 justify-center">
                <h1 className="text-2xl text-white max-w-[500px] w-max">{user.firstName ? user.firstName : ''} {user.lastName ? user.lastName : ''}</h1>
                {user.firstName?<h2 className="bg-yellow-300 text-green-1  text-center rounded-xl">Principal Trainer</h2>:''}
              </div>
            </div>
            <div className="flex gap-12 w-full h-auto min-h-[630px] bg-white p-12 divide-x" style={{color:'#F2F2F2'}}>
              <ProfileNav />

              <div className="pl-12 flex w-full flex-col gap-6 ">
                <div className="flex flex-col items-start p-5 gap-[15px] border border-lightgrey-100 rounded-[10px] overflow-auto max-h-[530px]">
                    {
                        user.userPlan ? 
                            user.userPlan.title !== "School License" ? 
                            <p className="font-gilroyBold font-bold text-xl item-center tracking-wide text-red w-100 flex justify-center">Sorry, your plan can't add members</p>
                            : 
                            <>
                                <div className="flex flex-row item-center w-full justify-between gap-[15px]">
                                    <p className="font-gilroyBold font-bold text-xl flex item-center tracking-wide text-green-1">Member</p>
                                    <button onClick={()=>setShowAdd(true)} className="flex flex-row items-center py-1 px-3 gap-1 border border-blue rounded-full">
                                        <img src={AddUser} alt="AddUser"/>
                                        <p className="font-gilroyBold text-sm flex items-center tracking-wide text-blue">Add Member</p>
                                    </button>
                                </div>
                                <table className="w-full">
                                    <thead className="border-b">
                                    <tr>
                                        <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2">Name</th>
                                        <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2">Email Address</th>
                                        <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2">Joined</th>
                                        <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2">Status</th>
                                        <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        listMember.length !== 0 ? 
                                        listMember.map((item, j)=>{
                                        return (
                                            <tr key={j}>
                                                <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs flex gap-1 items-center w-36"><img src={IconUsers} alt="icon-user"/>{item.firstName} {item.lastName}</td>
                                                <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs">{item.email}</td>
                                                <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs">{moment(item.createdAt).format('MMM Do YYYY')}</td>
                                                <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs"><img className="m-auto" src={item.isActive ?ActiveSvg:InactiveSvg} alt="active"/> <p className="text-center">{item.isActive ? 'Active' : 'Inactive'}</p></td>
                                                <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs cursor-pointer">
                                                    <div className="dropdown relative">
                                                        <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" id={`dropdownMenuButton${j}`}>
                                                            <img src={ThreeDots} alt="ThreeDots"/>
                                                        </button>
                                                        <ul className={`dropdown-menu -ml-9 p-2 min-w-full w-[101px] absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-3 m-0 bg-clip-padding border-none hidden`} aria-labelledby={`dropdownMenuButton${j}`}>
                                                            <li>
                                                                <button onClick={()=>showingEdit(item)} className="text-left dropdown-item items-center text-sm py-1 px-4 font-normal w-full whitespace-nowrap bg-transparent text-gey-3 hover:bg-grey-1100 hover:text-gey-3 hover:rounded-md flex gap-2"><img src={Edit} alt="edit"/> Edit</button>
                                                            </li>
                                                            <li>
                                                                <button onClick={()=>showingDelete(item)} className="text-left dropdown-item items-center text-sm py-1 px-4 font-normal w-full whitespace-nowrap bg-transparent text-red hover:bg-grey-1100 hover:text-red hover:rounded-md flex gap-2"><img src={Delete} alt="Delete"/> Delete</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                        })
                                        :''
                                    }
                                    </tbody>
                                </table>
                            </>
                        : <p className="font-gilroyBold font-bold text-xl item-center tracking-wide text-red w-100 flex justify-center">Sorry, your plan can't add members</p>
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {
        showAdd ? 
        <div className="bg-[#0000003d] w-full h-full top-0 fixed flex justify-center items-center">
            <div className="absolute flex flex-col item-center p-16 gap-7 bg-white shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14);] rounded-2xl">
                <button onClick={()=>{setShowAdd(false);resetForm()}} className="bg-green-1 p-3 absolute top-5 rounded-full right-5">
                    <img src={closeIcon} alt="closeIcon" width={15}/>
                </button>
                <p className="font-gilroyBold font-bold text-4xl text-center tracking-wide text-green-1">Add New Member</p>
                <form onSubmit={e=>{handleSubmit(e)}} className="w-full max-w-lg">
                    <div className="flex flex-wrap relative mt-8">
                        <div className="w-full md:w-1/2 px-3 md:mb-0 mb-5">
                            <input onChange={e=>{setFirstname(e.target.value)}} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="First Name" />
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-5">
                            <input onChange={e=>{setLastname(e.target.value)}} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-last-name" type="text" placeholder="Last Name" />
                        </div>
                    </div>
                    <div className="relative mb-5">
                        <div className="w-full px-3">
                            <input onChange={e=>{setEmail(e.target.value)}} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" type="email" placeholder="Email Address" />
                        </div>
                    </div>
                    <div className='relative mb-5'>
                        <div className="w-full px-3">
                            <input onChange={e=>{setPassword(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="Password" />
                            <div className="absolute inset-y-0 right-3 pr-3 flex items-center text-sm">
                                {
                                    showPassword?
                                    <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={showPw} alt="showPw" />
                                    :
                                    <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={hidePw} alt="hidePw" />
                                }
                            </div>
                        </div>
                    </div>  
                    <div className='relative mb-5'>
                        <div className="w-full px-3">
                            <select onChange={e=>setIsActive(e.target.value)} name="statusActive" className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="statusActive">
                                <option value={true}>Active</option>
                                <option value={false}>Inactive</option>
                            </select>
                        </div>    
                    </div>     
                    <button
                        className="flex justify-center justify-items-center items-center rounded-[10px] w-[97%] h-[62px] mt-[17.32px] bg-green-1 hover:bg-green-2 text-yellow-300 font-medium hover:border-yellow mx-[8px]"
                    >
                        <span className="ml-2 tracking-[0.08rem] font-medium text-lg shadowText">Add Member</span>
                    </button>
                </form> 
            </div>
        </div> : ''
    }
    {
        showEdit ? 
        <div className="bg-[#0000003d] w-full h-full top-0 fixed flex justify-center items-center">
            <div className="absolute flex flex-col item-center p-16 gap-7 bg-white shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14);] rounded-2xl">
                <button onClick={()=>{setShowEdit(false);resetForm()}} className="bg-green-1 p-3 absolute top-5 rounded-full right-5">
                    <img src={closeIcon} alt="closeIcon" width={15}/>
                </button>
                <p className="font-gilroyBold font-bold text-4xl text-center tracking-wide text-green-1">Edit Member</p>
                <form onSubmit={e=>{handleSubmitEdit(e)}} className="w-full max-w-lg">
                    <div className="flex flex-wrap relative mt-8">
                        <div className="w-full md:w-1/2 px-3 md:mb-0 mb-5">
                            <input onChange={e=>{setFirstname(e.target.value)}} value={firstname} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="First Name" />
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-5">
                            <input onChange={e=>{setLastname(e.target.value)}} value={lastname} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-last-name" type="text" placeholder="Last Name" />
                        </div>
                    </div>
                    <div className="relative mb-5">
                        <div className="w-full px-3">
                            <input onChange={e=>{setEmail(e.target.value)}} value={email} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" type="email" placeholder="Email Address" />
                        </div>
                    </div>
                    {/* <div className='relative mb-5'>
                        <div className="w-full px-3">
                            <input onChange={e=>{setPassword(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="Password" />
                            <div className="absolute inset-y-0 right-3 pr-3 flex items-center text-sm">
                                {
                                    showPassword?
                                    <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={showPw} alt="showPw" />
                                    :
                                    <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={hidePw} alt="hidePw" />
                                }
                            </div>
                        </div>
                    </div>   */}
                    <div className='relative mb-5'>
                        <div className="w-full px-3">
                            <select name="statusActive" onChange={e=>setIsActive(e.target.value)} value={isActive} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="statusActive">
                                <option value={true}>Active</option>
                                <option value={false}>Inactive</option>
                            </select>
                        </div>    
                    </div>     
                    <button
                        className="flex justify-center justify-items-center items-center rounded-[10px] w-[97%] h-[62px] mt-[17.32px] bg-green-1 hover:bg-green-2 text-yellow-300 font-medium hover:border-yellow mx-[8px]"
                    >
                        <span className="ml-2 tracking-[0.08rem] font-medium text-lg shadowText">Update</span>
                    </button>
                </form> 
            </div>
        </div> : ''
    }
    {
        showDelete ? 
        <div className="bg-[#0000003d] w-full h-full top-0 fixed flex justify-center items-center">
            <div className="absolute flex flex-col items-center p-16 gap-7 bg-white shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14);] rounded-2xl">
                <button onClick={()=>{setShowDelete(false);resetForm()}} className="bg-green-1 p-3 absolute top-5 rounded-full right-5">
                    <img src={closeIcon} alt="closeIcon" width={15}/>
                </button>
                <img src={IconRemove} alt="IconRemove" width={100} />
                <p className="font-gilroyBold font-bold text-4xl text-center tracking-wide text-red">Delete Member</p>
                <p className="text-center font-medium text-base tracking-wide text-grey-2">Are you sure you want to delete member<br/><span className="text-blue">{email}</span> ?</p>
                <div className="flex flex-row items-start gap-[15px] w-full">
                    <button
                    onClick={()=>{setShowDelete(false);resetForm()}}
                        className="flex justify-center justify-items-center items-center rounded-[5px] px-8 h-[49px] font-semibold border-[2px] border-green-1 hover:bg-lightgrey-50 w-3/6" type="button"
                    >
                        <span className="ml-2 tracking-wide font-semibold text-base text-green-1">No</span>
                    </button>
                    <button
                        onClick={(e)=>handleDelete(e)}
                        className={`flex justify-center justify-items-center items-center rounded-[10px] h-[49px] bg-green-1 hover:bg-green-2 text-yellow-300 font-semibold w-3/6`} type="button"
                        >
                        <span className="ml-2 tracking-wide font-semibold text-base">Yes</span>
                    </button>
                </div>
            </div>
        </div>
        : ''
    }
    </>
  );
}
