import React, {useState, useEffect} from "react";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { detailUserAdmin } from "../../redux/actions/User";
import PopupWindow from "../../components/PopupWindow";
import { getAllUsers, CreateUser, UpdateUser, BlockUser } from "../../redux/actions/Admin";
import { getAllPlan } from "../../redux/actions/Plan";
import AddUser from "../../assets/AddUser.svg"
import ThreeDots from "../../assets/threeDots.svg"
import ActiveSvg from "../../assets/active.svg"
import InactiveSvg from "../../assets/inActive.svg"
import closeIcon from "../../assets/close-icon.svg"
import IconUsers from "../../assets/iconUsers.svg"
import Edit from "../../assets/Edit.svg"
// import Delete from "../../assets/Delete.svg"
import Plus from "../../assets/Plus.svg"
import IconRemove from "../../assets/icon-remove.svg"
import moment from 'moment'

import showPw from "../../assets/show-pw.svg"
import hidePw from "../../assets/hide-pw.svg"

export default function ListMember() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const loading = useSelector((state) => state.globalReducer.loading);
    const token = localStorage.getItem('id_token');
    let userDetail = localStorage.getItem('userDetail')
    userDetail = JSON.parse(userDetail)

    const [user, setUser] = useState({})
    // const [selectedImage, setSelectedImage] = useState('');
    const [listAdmin, setListAdmin] = useState([])
    const [showAdd, setShowAdd] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [tempFirstname, setTempFirstname] = useState('')
    const [tempLastname, setTempLastname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [planCode, setPlanCode] = useState('00-01')
    const [planUser, setPlanUser] = useState({})
    const [addPlan, setAddPlan] = useState(true)
    const [showPassword, setShowPassword] = useState(false)
    const [idUser, setIdUser] = useState('')
    const [plans, setPlans] = useState([]);
    const [blockUser, setBlockUser] = useState(false)
    const [page,setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)

    const showingEdit = (input) => {
        setFirstname(input.firstName)
        setTempFirstname(input.firstName)
        setLastname(input.lastName)
        setTempLastname(input.lastName)
        setEmail(input.email)
        setIdUser(input._id)
        setPlanUser(input.userPlan)
        setPlanCode('')
        setShowEdit(true)
    }

    const showingDelete = (input, blockUser) => {
        setBlockUser(blockUser)
        setIdUser(input._id)
        setFirstname(input.firstName)
        setTempFirstname(input.firstName)
        setLastname(input.lastName)
        setTempLastname(input.lastName)
        setEmail(input.email)
        setShowDelete(true)
    }

    const resetForm = () => {
        setAddPlan(true)
        setFirstname('')
        setLastname('')
        setEmail('')
        setPassword('')
        setPlanCode('00-01')
        setIdUser('')
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (firstname !== '' && lastname !== '' && email !== '' && password !== '' && planCode !== '') {
            const paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;
            if (!password.match(paswd)) {
            dispatch({type:'ERROR_LOG', payload: 'validatePassword'})
            dispatch({type:'ERROR_MESSAGE', payload: 'Password Between 6 to 20 Characters Which Contains At Least One Lowercase Letter, One Uppercase Letter, One Numeric Digit, And One Special Character'})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
            } else {   
                const user = {
                    firstname:firstname,
                    lastname:lastname,
                    email:email,
                    password:password,
                    planCode:planCode
                }
                dispatch(CreateUser(user, token, (result)=> {
                    if (result.status === 200) {
                        setShowAdd(false)
                        dispatch({type:'ERROR_MESSAGE', payload: `Success Create User ${firstname} ${lastname}`})
                        dispatch({type:'ERROR_LOG', payload: `success`})
                        setTimeout(() => {
                            dispatch({type:'ERROR_MESSAGE', payload: ''})
                            dispatch({type:'ERROR_LOG', payload: ''})
                        }, 2000);
                        resetForm()
                        dispatch(getAllUsers("", "", token, (result)=> {
                            if (result.status === 200) {
                                setListAdmin(result.data.results);
                            }
                        }))
                    }
                }))
            }  
        } else {
            dispatch({type:'ERROR_LOG', payload: 'validateFormRegisterTrial'})
            dispatch({type:'ERROR_MESSAGE', payload: 'Please Input All Form'})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
        }
    }

    const handleSubmitEdit = (e) => {
        e.preventDefault()
        if (firstname !== '' && lastname !== '' && email !== '' && password !== '') {
            const paswd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,20}$/;
            if (!password.match(paswd)) {
            dispatch({type:'ERROR_LOG', payload: 'validatePassword'})
            dispatch({type:'ERROR_MESSAGE', payload: 'Password Between 6 to 20 Characters Which Contains At Least One Lowercase Letter, One Uppercase Letter, One Numeric Digit, And One Special Character'})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
            } else {   
                let user = {}
                if (planCode === "") {
                    user = {
                        firstname:firstname,
                        lastname:lastname,
                        email:email,
                        password:password,
                    }
                } else {
                    user = {
                        firstname:firstname,
                        lastname:lastname,
                        email:email,
                        password:password,
                        planCode:planCode
                    }
                }
                dispatch(UpdateUser(user, idUser, token, (result)=> {
                    if (result.status === 201) {
                        setShowEdit(false)
                        dispatch({type:'ERROR_MESSAGE', payload: `Success Edit User ${firstname} ${lastname}`})
                        dispatch({type:'ERROR_LOG', payload: `success`})
                        setTimeout(() => {
                            dispatch({type:'ERROR_MESSAGE', payload: ''})
                            dispatch({type:'ERROR_LOG', payload: ''})
                        }, 2000);
                        resetForm()
                        dispatch(getAllUsers(10, page, token, (result)=> {
                            if (result.status === 200) {
                                setListAdmin(result.data.results);
                            }
                        }))
                    }
                }))
            } 
        } else if (firstname !== '' && lastname !== '' && email !== '') {
            let user = {}
                if (planCode === "") {
                    user = {
                        firstname:firstname,
                        lastname:lastname,
                        email:email,
                    }
                } else {
                    user = {
                        firstname:firstname,
                        lastname:lastname,
                        email:email,
                        planCode:planCode
                    }
                }
            dispatch(UpdateUser(user, idUser, token, (result)=> {
                if (result.status === 201) {
                    setShowEdit(false)
                    dispatch({type:'ERROR_MESSAGE', payload: `Success Edit User ${firstname} ${lastname}`})
                    dispatch({type:'ERROR_LOG', payload: `success`})
                    setTimeout(() => {
                        dispatch({type:'ERROR_MESSAGE', payload: ''})
                        dispatch({type:'ERROR_LOG', payload: ''})
                    }, 2000);
                    resetForm()
                    dispatch(getAllUsers(10, page, token, (result)=> {
                        if (result.status === 200) {
                            setListAdmin(result.data.results);
                        }
                    }))
                }
            }))
        } else {
            dispatch({type:'ERROR_LOG', payload: 'validateFormRegisterTrial'})
            dispatch({type:'ERROR_MESSAGE', payload: 'Please Input All Form'})
            setTimeout(() => {
                dispatch({type:'ERROR_MESSAGE', payload: ''})
                dispatch({type:'ERROR_LOG', payload: ''})
            }, 2000);
        }
    }

    const handleDelete = () => {
        dispatch(BlockUser(idUser, token, (result)=> {
            if (result.status === 200) {
                setShowDelete(false)
                dispatch({type:'ERROR_MESSAGE', payload: `Success Block User ${firstname} ${lastname}`})
                dispatch({type:'ERROR_LOG', payload: `success`})
                setTimeout(() => {
                    dispatch({type:'ERROR_MESSAGE', payload: ''})
                    dispatch({type:'ERROR_LOG', payload: ''})
                }, 2000);
                resetForm()
                dispatch(getAllUsers(10, page, token, (result)=> {
                    if (result.status === 200) {
                        setListAdmin(result.data.results);
                    }
                }))
            }
        }))
    }

    const fatchAllPlan = async () => {
        dispatch(getAllPlan(false, (result)=>{
        setPlans(result.reverse())
        let newObj = {}
        if (result.length !== 0) {
            result.forEach((el, i) => {
            newObj = {...newObj, [i]: el.plan[0]}
            });
        }
        }))
    }

    const handleChangePage = (idx) => {
        setPage(idx)
        dispatch(getAllUsers(10, idx, token, (result)=> {
            if (result.status === 200) {
                setListAdmin(result.data.results);
                setTotalPage(Math.ceil(result.data.Total/10))
            }
        }))
    }

    useEffect(()=> {
        dispatch(getAllUsers(10, page, token, (result)=> {
            if (result.status === 200) {
                setListAdmin(result.data.results);
                setTotalPage(Math.ceil(result.data.Total/10))
            }
        }))
        fatchAllPlan()
        dispatch(detailUserAdmin(token, false, (result)=>{
            setUser(result)
            localStorage.setItem('userDetail', JSON.stringify(result))
            if (result === 'invalid token') {
                localStorage.removeItem('userDetail')
                localStorage.removeItem('id_token')
                localStorage.removeItem('input_button')
                navigate('/')
            }
        }))
    },[])

    return (
        <>
            <PopupWindow/>
            <div className="w-full sm:w-full lg:w-10/12 px-4">
                <Navbar widthFit={true}/>
                <div className="flex flex-row mt-4">
                    <div className="flex flex-col sm:flex-row gap-5 w-full min-w-[338px] mx-auto sm:mx-auto justify-center">
                        {loading? 
                            <div className="fixed top-0 flex left-0 w-full h-full justify-center items-center bg-[#00000047] z-50">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status"></div>
                            </div>:''
                        }
                        <div className="flex flex-col items-start px-6 py-5 gap-1 w-full">
                            <div className="h-auto min-w-[906px] bg-white flex gap-0 flex-col rounded-2xl overflow-hidden w-full">
                                <div className="flex flex-col items-start p-5 gap-[15px] rounded-[10px] overflow-auto max-h-[50rem] w-full">
                                    <div className="flex flex-row item-center w-full justify-between gap-[15px]">
                                        <p className="font-gilroyBold font-bold text-xl flex item-center tracking-wide text-green-1">List Member</p>
                                        <button onClick={()=>setShowAdd(true)} className="flex flex-row items-center py-1 px-3 gap-1 border border-blue rounded-full">
                                            <img src={AddUser} alt="AddUser"/>
                                            <p className="font-gilroyBold text-sm flex items-center tracking-wide text-blue">Add Member</p>
                                        </button>
                                    </div>
                                    <table className="w-full">
                                        <thead className="border-b border-lightgrey-250">
                                        <tr>
                                        <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2">Name</th>
                                            <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2">Email Address</th>
                                            <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2">Joined</th>
                                            <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2">Plan</th>
                                            <th className="text-center text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2">Status</th>
                                            <th className="text-left text-lightgrey-700 font-gilroyMedium font-medium text-xs p-2"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            listAdmin.length !== 0 ? 
                                            listAdmin.map((item, j)=>{
                                            return (
                                                <tr key={j}>
                                                    <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs flex gap-1 items-center w-36"><img src={IconUsers} alt="icon-user"/>{item.firstName} {item.lastName}</td>
                                                    <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs">{item.email}</td>
                                                    <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs">{moment(item.createdAt).format('MMM Do YYYY')}</td>
                                                    <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs">{item.userPlan?item.userPlan.title:'-'}</td>
                                                    <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs"><img className="m-auto" src={item.isVerified ?ActiveSvg:InactiveSvg} alt="active"/> <p className="text-center">{item.isVerified ? 'Active' : 'Inactive'}</p></td>
                                                    <td className="p-2 text-left text-grey-3 font-gilroyMedium font-semibold tracking-wide text-xs cursor-pointer">
                                                        <div className="dropdown relative">
                                                            <button className="dropdown-toggle px-5" type="button" data-bs-toggle="dropdown" aria-expanded="false" id={`dropdownMenuButton${j}`}>
                                                                <img src={ThreeDots} alt="ThreeDots"/>
                                                            </button>
                                                            <ul className={`dropdown-menu -ml-9 p-2 min-w-full w-[121px] absolute bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-3 m-0 bg-clip-padding border-none hidden`} aria-labelledby={`dropdownMenuButton${j}`}>
                                                                <li>
                                                                    <button onClick={()=>showingEdit(item)} className="text-left dropdown-item items-center text-sm py-1 px-4 font-normal w-full whitespace-nowrap bg-transparent text-gey-3 hover:bg-grey-1100 hover:text-gey-3 hover:rounded-md flex gap-2"><img src={Edit} alt="edit"/> Edit</button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={()=>showingDelete(item, !item.isVerified)} className={`text-left dropdown-item items-center text-sm py-1 px-4 font-normal w-full whitespace-nowrap bg-transparent ${!item.isVerified?'text-green-1 hover:text-green-1' : 'text-red hover:text-red'} hover:bg-grey-1100 hover:rounded-md flex gap-2`}><img src={!item.isVerified?ActiveSvg:InactiveSvg} alt="InactiveSvg"/> {!item.isVerified?'Unblock':'Block'}</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                            })
                                            :''
                                        }
                                        </tbody>
                                    </table>
                                    {
                                        listAdmin.length === 0 ? 
                                        '' : 
                                        <div className="flex justify-center">
                                            <nav aria-label="Page navigation example">
                                                <ul className="flex list-style-none">
                                                    <li onClick={()=>{handleChangePage(page-1)}} className="page-item cursor-pointer hover:bg-lightgrey-300 rounded-md">
                                                        <div className="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 focus:shadow-none" aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                        </div>
                                                    </li>
                                                    {
                                                        [...Array(totalPage)].map((_, i)=>{
                                                            return(
                                                                <li onClick={()=>{handleChangePage(i+1)}} className={`page-item cursor-pointer rounded-md hover:bg-lightgrey-300 ${i+1 === page?'bg-lightgrey-200':''}`} key={i}>
                                                                    <div className="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none">{i+1}</div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    <li onClick={()=>{handleChangePage(page+1)}} className="page-item cursor-pointer hover:bg-lightgrey-300 rounded-md">
                                                        <div className="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none" aria-label="Next">
                                                            <span aria-hidden="true">&raquo;</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {
                showAdd ? 
                <div className="bg-[#0000003d] w-full h-full top-0 fixed flex justify-center items-center z-40">
                    <div className="absolute flex flex-col item-center p-16 gap-7 bg-white shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14);] rounded-2xl">
                        <button onClick={()=>{setShowAdd(false);resetForm()}} className="bg-green-1 p-3 absolute top-5 rounded-full right-5">
                            <img src={closeIcon} alt="closeIcon" width={15}/>
                        </button>
                        <p className="font-gilroyBold font-bold text-4xl text-center tracking-wide text-green-1">Add New Member</p>
                        <form onSubmit={e=>{handleSubmit(e)}} className="w-full min-w-[30rem] border-t-lightgrey-250 border-t-2">
                            <div className="flex flex-wrap relative mt-8">
                                <div className="w-full md:w-1/2 px-3 md:mb-0 mb-5">
                                    <input onChange={e=>{setFirstname(e.target.value)}} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="First Name" />
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5">
                                    <input onChange={e=>{setLastname(e.target.value)}} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-last-name" type="text" placeholder="Last Name" />
                                </div>
                            </div>
                            <div className="relative mb-5">
                                <div className="w-full px-3">
                                    <input onChange={e=>{setEmail(e.target.value)}} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" type="email" placeholder="Email Address" />
                                </div>
                            </div>
                            <div className='relative mb-5'>
                                <div className="w-full px-3">
                                    <input onChange={e=>{setPassword(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="Password" />
                                    <div className="absolute inset-y-0 right-3 pr-3 flex items-center text-sm">
                                        {
                                            showPassword?
                                            <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={showPw} alt="showPw" />
                                            :
                                            <img className='cursor-pointer' onClick={()=>setShowPassword(!showPassword)} src={hidePw} alt="hidePw" />
                                        }
                                    </div>
                                </div>
                            </div>  
                            <div className='relative mb-5'>
                                <div className="w-full px-3">
                                    <select onChange={(e)=>{setPlanCode(e.target.value);}}  name="statusActive" className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white class-plans`} id="statusActive">
                                        {
                                            plans.map((item, i)=>{
                                                return(
                                                    <>
                                                        <option className="h-4" key={item.plan[0].kode} value={item.plan[0].kode}>{item.title} - {item.plan[0].month}</option>
                                                        <option key={item.plan[1].kode} value={item.plan[1].kode}>{item.title} - {item.plan[1].month}</option>
                                                    </>
                                                )
                                            })
                                        }
                                    </select>
                                </div>    
                            </div>    
                            <div className="flex flex-row items-start gap-[15px] w-full">
                                <button
                                    className={`flex justify-center justify-items-center items-center rounded-[10px] h-[49px] bg-green-1 hover:bg-green-2 text-yellow-300 font-semibold w-3/6`} type="submit"
                                    >
                                    <span className="ml-2 tracking-wide font-semibold text-base">Save</span>
                                </button>
                                <button
                                    onClick={()=>{setShowAdd(false);resetForm()}}
                                    className="flex justify-center justify-items-center items-center rounded-[5px] px-8 h-[49px] font-semibold border-[2px] border-green-1 hover:bg-lightgrey-50 w-3/6" type="button"
                                >
                                    <span className="ml-2 tracking-wide font-semibold text-base text-green-1">Cancel</span>
                                </button>
                            </div>
                        </form> 
                    </div>
                </div> : ''
            }
            {
                showEdit ? 
                <div className="bg-[#0000003d] w-full h-full top-0 fixed flex justify-center items-center z-40">
                    <div className="absolute flex flex-col item-center p-16 gap-7 bg-white shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14);] rounded-2xl">
                        <button onClick={()=>{setShowEdit(false);resetForm()}} className="bg-green-1 p-3 absolute top-5 rounded-full right-5">
                            <img src={closeIcon} alt="closeIcon" width={15}/>
                        </button>
                        <p className="font-gilroyBold font-bold text-4xl text-center tracking-wide text-green-1">Edit Admin <span className="text-blue">{tempFirstname} {tempLastname}</span></p>
                        <form onSubmit={e=>{handleSubmitEdit(e)}} className="w-full min-w-[30rem] border-t-lightgrey-250 border-t-2">
                            <div className="flex flex-wrap relative mt-8">
                                <div className="w-full md:w-1/2 px-3 md:mb-0 mb-5 flex flex-col gap-1">
                                    <p className="text-grey-3">First Name</p>
                                    <input onChange={e=>{setFirstname(e.target.value)}} value={firstname} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="First Name" />
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-5 flex flex-col gap-1">
                                    <p className="text-grey-3">Last Name</p>
                                    <input onChange={e=>{setLastname(e.target.value)}} value={lastname} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" id="grid-last-name" type="text" placeholder="Last Name" />
                                </div>
                            </div>
                            <div className="relative mb-5 mt-4">
                                <div className="w-full px-3 flex flex-col gap-1">
                                    <p className="text-grey-3">Email</p>
                                    <input onChange={e=>{setEmail(e.target.value)}} value={email} className="appearance-none block w-full border-lightgrey-150 border bg-grey-1100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white" type="email" placeholder="Email Address" />
                                </div>
                            </div>
                            <div className='relative mb-5 mt-4'>
                                <div className="w-full px-3 flex flex-col gap-1">
                                    <p className="text-grey-3">Password</p>
                                    <input onChange={e=>{setPassword(e.target.value);}} className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white`} id="password" type={showPassword? 'text':'password'} placeholder="Password" />
                                    <div className="absolute inset-y-0 right-3 pr-3 flex items-center text-sm">
                                        {
                                            showPassword?
                                            <img className='cursor-pointer mt-6' onClick={()=>setShowPassword(!showPassword)} src={showPw} alt="showPw" />
                                            :
                                            <img className='cursor-pointer mt-6' onClick={()=>setShowPassword(!showPassword)} src={hidePw} alt="hidePw" />
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                planUser ?
                                <div className="flex flex-wrap relative mt-4">
                                    <div className="w-full md:w-1/2 px-3 md:mb-0 flex flex-col gap-1">
                                        <p className="text-grey-3 text-base">Current Plan</p>
                                        <p className="font-gilroyBold font-bold text-2xl tracking-wide text-green-1">{planUser.title}</p>
                                    </div>
                                    <div className="w-full md:w-1/2 px-3 flex flex-col gap-1">
                                        <p className="text-grey-3 text-base">Status</p>
                                        <p className="font-gilroyMedium text-green-1 font-semibold text-lg tracking-wide my-auto">Active</p>
                                    </div>
                                </div>
                                : ''
                            }
                            {
                                addPlan ?
                                <div className='relative mb-5 mt-4'>
                                    <div className="w-full px-3 flex flex-col gap-1">
                                        <button
                                            onClick={()=>{setAddPlan(false);setPlanCode('00-01')}}
                                            className="flex justify-center justify-items-center items-center rounded-full px-5 h-[45px] font-semibold border-[2px] border-blue hover:bg-lightgrey-50 w-fit" type="button"
                                        >
                                            <span className="tracking-wide font-semibold text-base text-blue flex flex-row gap-3"><img src={Plus} alt="Plus"/> Add New Plan</span>
                                        </button>
                                    </div>
                                </div>
                                : 
                                <div className='relative mb-5 mt-4'>
                                    <div className="w-full px-3">
                                        <select onChange={(e)=>{setPlanCode(e.target.value);}}  name="statusActive" className={`border-lightgrey-150 border bg-grey-1100 appearance-none block w-full rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white class-plans`} id="statusActive">
                                            {
                                                plans.map((item, i)=>{
                                                    return(
                                                        <>
                                                            <option className="h-4" key={item.plan[0].kode} value={item.plan[0].kode}>{item.title} - {item.plan[0].month}</option>
                                                            <option key={item.plan[1].kode} value={item.plan[1].kode}>{item.title} - {item.plan[1].month}</option>
                                                        </>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>    
                                </div>   
                            }
                            <div className="flex flex-row items-start gap-[15px] w-full mt-5">
                                <button
                                    className={`flex justify-center justify-items-center items-center rounded-[10px] h-[49px] bg-green-1 hover:bg-green-2 text-yellow-300 font-semibold w-3/6`} type="submit"
                                    >
                                    <span className="ml-2 tracking-wide font-semibold text-base">Update</span>
                                </button>
                                <button
                                    onClick={()=>{setShowEdit(false);resetForm()}}
                                    className="flex justify-center justify-items-center items-center rounded-[5px] px-8 h-[49px] font-semibold border-[2px] border-green-1 hover:bg-lightgrey-50 w-3/6" type="button"
                                >
                                    <span className="ml-2 tracking-wide font-semibold text-base text-green-1">Cancel</span>
                                </button>
                            </div>
                        </form> 
                    </div>
                </div> : ''
            }
            {
                showDelete ? 
                <div className="bg-[#0000003d] w-full h-full top-0 fixed flex justify-center items-center z-40">
                    <div className="absolute flex flex-col items-center p-16 gap-7 bg-white shadow-[0px_4px_60px_rgba(0,_0,_0,_0.15),_inset_0px_-8px_0px_rgba(156,_156,_156,_0.14);] rounded-2xl">
                        <button onClick={()=>{setShowDelete(false);resetForm()}} className="bg-green-1 p-3 absolute top-5 rounded-full right-5">
                            <img src={closeIcon} alt="closeIcon" width={15}/>
                        </button>
                        <img src={blockUser?ActiveSvg:IconRemove} alt="IconRemove" width={100} />
                        <p className={`font-gilroyBold font-bold text-4xl text-center tracking-wide ${blockUser ? 'text-green-1' : 'text-red'}`}>{blockUser ? 'Unblock':'Block'} Member</p>
                        <p className="text-center font-medium text-base tracking-wide text-grey-2">Are you sure you want to {blockUser ? 'unblock':'block'} member?<br/><span className="text-blue">{email}</span> ?</p>
                        <div className="flex flex-row items-start gap-[15px] w-full">
                            <button
                            onClick={()=>{setShowDelete(false);resetForm()}}
                                className="flex justify-center justify-items-center items-center rounded-[5px] px-8 h-[49px] font-semibold border-[2px] border-green-1 hover:bg-lightgrey-50 w-3/6" type="button"
                            >
                                <span className="ml-2 tracking-wide font-semibold text-base text-green-1">No</span>
                            </button>
                            <button
                                onClick={(e)=>handleDelete(e)}
                                className={`flex justify-center justify-items-center items-center rounded-[10px] h-[49px] bg-green-1 hover:bg-green-2 text-yellow-300 font-semibold w-3/6`} type="button"
                                >
                                <span className="ml-2 tracking-wide font-semibold text-base">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
                : ''
            }
        </>
    );
}
