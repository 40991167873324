import React, {useState, useEffect} from "react";
import clsx from "clsx";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Logout from "../../../assets/Logout.svg"
import Arrow from "../../../assets/ArrowLeft.svg"

import {useSelector} from "react-redux"

const profileNav = [
  ["My Profile", "/game/profile"],
  ["Plan", "/game/profile/plan"],
  ["Member", "/game/profile/member"],
  ["Notification", "/game/profile/notification"],
  ["Password & Security", "/game/profile/security"],
];

const profileNavMember = [
  ["My Profile", "/game/profile"],
  ["Password & Security", "/game/profile/security"],
]

export default function ProfileNav() {
  const countNotif = localStorage.getItem('countNotif')
  let userDetail = localStorage.getItem('userDetail')
  userDetail = JSON.parse(userDetail)
  const [notifCount, setNotifCount] = useState(countNotif?countNotif:0)
  const AllNotification = useSelector((state) => state.globalReducer.AllNotification);
  
  const location = useLocation().pathname;
  const navigate = useNavigate()
  const goLogout = () => {
    localStorage.clear()
    navigate("/login")
  }

  useEffect(()=>{
    setNotifCount(countNotif)
  },[AllNotification])
  return (
    <div className="flex flex-col h-auto w-4/12 justify-between">
      <div className="flex flex-col">
        {
          userDetail.user?
          profileNavMember.map(([item, active], index) => (
            <Link key={index} to={active}>
              <p
                className={clsx(
                  "text-lg text-semibold flex justify-between",
                  location === active ? "text-green-1 p-2 bg-lightgrey-100 rounded" : "text-grey-600 p-2 rounded"
                )}
              >
                {item}   {
                  item === 'Notification' ? 
                  <div className="bg-yellow-1000 rounded-full w-7 h-7 flex flex-col items-center justify-center gap-2.5">
                    <p className="text-white text-xs font-bold font-gilroyMedium">{notifCount?notifCount:0}</p>
                  </div>
                  : ''
                }
              </p>
            </Link>
          ))
          :
          profileNav.map(([item, active], index) => (
            <Link key={index} to={active}>
              <p
                className={clsx(
                  "text-lg text-semibold flex justify-between",
                  location === active ? "text-green-1 p-2 bg-lightgrey-100 rounded" : "text-grey-600 p-2 rounded"
                )}
              >
                {item}   {
                  item === 'Notification' ? 
                  <div className="bg-yellow-1000 rounded-full w-7 h-7 flex flex-col items-center justify-center gap-2.5">
                    <p className="text-white text-xs font-bold font-gilroyMedium">{notifCount?notifCount:0}</p>
                  </div>
                  : ''
                }
              </p>
            </Link>
          ))
        }
      </div>
      <div className="gap-3">
        <Link to='/game' className="flex items-center gap-3 cursor-pointer w-fit mb-3">
          <img src={Arrow} alt='arrow'/>
          <div className="text-green-1 text-xl">Back to Game</div>
        </Link>
        <button onClick={()=>goLogout()} className="flex items-center gap-3 cursor-pointer w-fit">
          <img src={Logout} alt='logout'/>
          <div className="text-red text-xl">log out</div>
        </button>
      </div>
    </div>
  );
}
