import React from "react";
import { useDispatch, useSelector } from "react-redux";
import UserCircle from "../../assets/iconMenuAdmin/userCircle.svg"

export default function Navbar () {
    let username = localStorage.getItem('userDetail')
    username = JSON.parse(username)
    const dispatch = useDispatch()
    const sideBarActive = useSelector((state) => state.adminReducer.sideBarActive);
    return(
        <div className="border-b flex gap-4 p-2 border-lightgrey-250">
            <div className="md:hidden flex items-center mr-7">
                <button
                    onClick={() => dispatch({type:'SIDE_BAR_ACTIVE', payload: !sideBarActive})}
                    className="outline-none mobile-menu-button"
                >
                    <svg
                    className=" w-6 h-6 text-grey-500 hover:text-green "
                    x-show="!showMenu"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    >
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
            </div>
            <img src={UserCircle} alt="userCircle" width={40} className="ml-auto"/> 
            <span className="block font-gilroyMedium text-base text-[#232D42] my-auto">{!username ? 'admin' : username.username}</span>
        </div>
    )
}